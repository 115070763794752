import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isFinite from 'lodash/isFinite';
import getKey from 'lodash/get';
import setKey from 'lodash/set';

import COUNTRIES from '../assets/countries.json';
import TRANSLATIONS from './types.translations';

const CM_INCH_RATIO = 2.54;

const Types = {};

Types.createValues = (
  name,
  values = [],
  {
    keyId = 'id',
    getId = value => value[keyId],
    keyLabel = 'label',
    getLabel = value => value[keyLabel],
  } = {},
) => values.reduce(
  (agr, value) => {
    const id = getId(value);
    const label = getLabel(value);
    agr[name] = agr[name] || [];
    agr[name].push(id);
    const nameList = `${name}_LIST`;
    agr[nameList] = agr[nameList] || [];
    agr[nameList].push(value);
    const nameMap = `${name}_MAP`;
    agr[nameMap] = agr[nameMap] || {};
    agr[nameMap][id] = value;
    const nameLabels = `${name}_LABELS`;
    agr[nameLabels] = agr[nameLabels] || [];
    agr[nameLabels].push(label);
    const nameLabelsMap = `${name}_LABELS_MAP`;
    agr[nameLabelsMap] = agr[nameLabelsMap] || {};
    agr[nameLabelsMap][id] = label;
    const nameConstants = `${name}_CONST`;
    agr[nameConstants] = agr[nameConstants] || {};
    agr[nameConstants][id] = id;
    return agr;
  },
  {},
);

Types.getRoundedAmount = (amount, rounding = 2) => (
  Math.round(amount * (10 ** rounding)) / (10 ** rounding)
);
Types.getCmFromInch = value => value * CM_INCH_RATIO;
Types.getInchFromCm = value => value / CM_INCH_RATIO;
Types.rotatePoint = (x, y, angle, cx = 0, cy = 0, rounding = -1) => {
  angle = (((-angle % 360) + 360) % 360) * (Math.PI / 180);
  const point = {
    x: (Math.cos(angle) * (x - cx)) - (Math.sin(angle) * (y - cy)) + cx,
    y: (Math.sin(angle) * (x - cx)) + (Math.cos(angle) * (y - cy)) + cy,
  };
  if (rounding >= 0) {
    point.x = Types.getRoundedAmount(point.x, rounding);
    point.y = Types.getRoundedAmount(point.y, rounding);
  }
  return point;
};

Types.getCombinations = (input = []) => {
  let length = 1;
  for (let i = 0; i < input.length; i++) {
    length *= input[i][1].length;
  }
  const results = [];
  for (let p = 0; p < length; p++) {
    let nxt = null;
    const result = {};
    for (let i = input.length - 1; i >= 0; i--) {
      const [key, values] = input[i];
      const cur = nxt === null ? p : nxt;
      nxt = Math.floor(cur / values.length);
      result[key] = values[cur % values.length];
    }
    results.push(result);
  }
  return results;
};

Types.COUNTRIES = COUNTRIES;

Types.COUNTRIES_MAP = COUNTRIES.reduce(
  (agr, country) => { agr[country.iso3a2] = country; return agr; },
  {},
);

Types.COUNTRIES_DIAL_CODES = Types.COUNTRIES.reduce(
  (agr, country) => {
    country.dialCodes.forEach((dialCode, i) => {
      agr.push({
        value: dialCode,
        label: `+${dialCode} (${country.name})${i > 0 ? ` ${i}` : ''}`,
      });
    });
    return agr;
  },
  [],
);

Object.assign(
  Types,
  Types.createValues('CURRENCY_POSITION', [{
    id: 'BEFORE',
    label: 'Before price',
  }, {
    id: 'AFTER',
    label: 'After price',
  }]),
  Types.createValues('PRICE_TAG_COLOR', [{
    id: 'YELLOW',
    label: 'Yellow',
    color: '#ffff00',
  }, {
    id: 'WHITE',
    label: 'White',
    color: '#f3f3f3',
  }, {
    id: 'RED',
    label: 'Red',
    color: '#ff4545',
  }]),
);

Types.TRANSLATIONS = TRANSLATIONS;

Types.TRANSLATIONS_MAP = Types.TRANSLATIONS.reduce(
  (agr, [key, text]) => {
    agr[key] = text;
    return agr;
  },
  {},
);

// Types.COUNTRY = 'ae';

// Types.COUNTRY_NAME = Types.COUNTRIES_MAP[Types.COUNTRY].name;

// Types.TIMEZONE = 'Asia/Dubai';

Types.ROLE = [
  'ADMIN',
  'CONTENT_MANAGER',
  'CUSTOMER',
];

Types.ROLE_LABELS = [
  'Admin',
  'Content Manager',
  'Customer',
];

Types.ROLE_LABELS_MAP = Types.ROLE.reduce(
  (agr, role, index) => {
    agr[role] = Types.ROLE_LABELS[index];
    return agr;
  },
  {},
);

Types.ACCESS_TOKEN_TYPE = [
  'User',
];

Types.LENGTH_UNIT = [
  'CM',
  'INCH',
];

Types.LENGTH_UNIT_LABELS = [
  'cm',
  'inch',
];

Types.LENGTH_UNIT_LABELS_MAP = Types.LENGTH_UNIT.reduce(
  (agr, unit, index) => {
    agr[unit] = Types.LENGTH_UNIT_LABELS[index];
    return agr;
  },
  {},
);

Types.VARIATION_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

Types.STATS_SIGNIFICANCE_VARS_KEEP = false;
Types.STATS_SIGNIFICANCE_VARS = [
  ['p1', 0],
  ['p2', 0],
  ['s1', 0],
  ['s2', 0],
];
Types.STATS_SIGNIFICANCE_VARS.clone = () => (
  Types.STATS_SIGNIFICANCE_VARS.map(variable => [...variable])
);

Types.PROJECT_STATUS = [
  'DRAFT',
  'READY',
  'QUOTING',
  'APPROVAL',
  'APPROVED',
  'LIVE',
  'PROCESSING',
  'REPORT',
];

Types.PROJECT_STATUS_LABELS = [
  'Draft',
  'Ready',
  'Pricing Request',
  'Approval',
  'Approved',
  'Live',
  'Processing',
  'Report',
];

Types.PROJECT_STATUS_LABELS_MAP = Types.PROJECT_STATUS.reduce(
  (agr, status, index) => {
    agr[status] = Types.PROJECT_STATUS_LABELS[index];
    return agr;
  },
  {},
);

Types.PROJECT_MESSAGE = [];
Types.PROJECT_MESSAGE_MAP = {};
Types.PROJECT_MESSAGE_TYPE = [];
Types.PROJECT_MESSAGE_TYPE_LABELS = [];
Types.PROJECT_MESSAGE_TYPE_LABELS_MAP = [];

[{
  type: 'QUOTING_REQUEST',
  title: 'Custom pricing request',
  alert: 'info',
  isVisible: user => user && user.role === 'ADMIN',
}, {
  type: 'QUOTING_DENIAL',
  title: 'Custom pricing request denied',
  alert: 'error',
  isVisible: user => (
    user && (
      user.role === 'ADMIN'
      || (
        user.role === 'CUSTOMER'
        && (
          user.permissions.manageOrganization
          || user.permissions.manageProjects
        )
      )
    )
  ),
}, {
  type: 'APPROVAL_DENIAL',
  title: 'Project denied',
  alert: 'error',
  isVisible: user => (
    user && (
      user.role === 'ADMIN'
      || (
        user.role === 'CUSTOMER'
        && (
          user.permissions.manageOrganization
          || user.permissions.manageProjects
        )
      )
    )
  ),
}].forEach((value) => {
  Types.PROJECT_MESSAGE.push(value);
  Types.PROJECT_MESSAGE_MAP[value.type] = value;
  Types.PROJECT_MESSAGE_TYPE.push(value.type);
  Types.PROJECT_MESSAGE_TYPE_LABELS.push(value.title);
  Types.PROJECT_MESSAGE_TYPE_LABELS_MAP[value.type] = value.title;
});

Types.PROJECT_PAYMENT_PLAN_DEFAULT = null;
Types.PROJECT_PAYMENT_PLAN = [];
Types.PROJECT_PAYMENT_PLAN_LABELS = [];
Types.PROJECT_PAYMENT_PLAN_LABELS_MAP = {};
Types.PROJECT_PAYMENT_PLAN_DEFINITION_MAP = {};
Types.PROJECT_PAYMENT_PLAN_DEFINITION = [{
  name: 'TIER_1',
  label: 'Quick Test',
  description: 'Fast results with limited number of participants',
  defaultPricePerVariation: 990,
  defaultSamplePerVariation: 400,
  getPrice: (variationsCount = 1, pricePerVariation = 10) => (
    variationsCount * pricePerVariation
  ),
  getSampleSize: (variationsCount = 1, samplePerVariation = 10) => (
    variationsCount * samplePerVariation
  ),
}, {
  name: 'TIER_2',
  default: true,
  label: 'Recommended',
  description: 'Distribute optimal survey for your content',
  defaultPricePerVariation: 2490,
  defaultSamplePerVariation: 800,
  getPrice: (variationsCount = 1, pricePerVariation = 10) => (
    variationsCount * pricePerVariation
  ),
  getSampleSize: (variationsCount = 1, samplePerVariation = 10) => (
    variationsCount * samplePerVariation
  ),
}, {
  name: 'QUOTE',
  label: 'Custom',
  description: 'Custom offer based on your project needs',
  getPrice: () => -1,
  getSampleSize: () => -1,
  custom: true,
}];

Types.PROJECT_PAYMENT_PLAN_DEFINITION.forEach((plan) => {
  Types.PROJECT_PAYMENT_PLAN.push(plan.name);
  Types.PROJECT_PAYMENT_PLAN_LABELS.push(plan.label);
  Types.PROJECT_PAYMENT_PLAN_LABELS_MAP[plan.name] = plan.label;
  Types.PROJECT_PAYMENT_PLAN_DEFINITION_MAP[plan.name] = plan;
  if (plan.default) {
    Types.PROJECT_PAYMENT_PLAN_DEFAULT = plan.name;
  }
});

Types.POS_MATERIAL_TYPE = [];
Types.POS_MATERIAL_TYPE_MAP = {};
Types.POS_MATERIAL_TYPE_LABELS = [];
Types.POS_MATERIAL_TYPE_LABELS_MAP = {};

[{
  id: 'HANGING',
  label: 'Hanging Sign',
  back: true,
  ppc: 20,
  width: 60,
  height: 60,
  meta: {
    bottom: 210,
    offset: 100,
  },
}, {
  id: 'TOP',
  label: 'Top Board',
  back: false,
  ppc: 20,
  width: 120,
  height: 60,
  meta: {

  },
}, {
  id: 'SIDE',
  label: 'Aisle Fin',
  back: true,
  ppc: 20,
  width: 30,
  height: 190,
  meta: {
    bottom: 10,
    // bottom: 180 - 120,
  },
}, {
  id: 'COVER',
  label: 'Shelf Cover',
  back: false,
  ppc: 20,
  width: 120,
  height: 190,
  meta: {
    bottom: 200 - 190,
  },
}, {
  id: 'FLOOR_STICKER',
  label: 'Floor Sticker',
  back: false,
  ppc: 20,
  width: 120,
  height: 190,
  meta: {
    offset: 10,
  },
}, {
  id: 'SHELF_LINER',
  label: 'Shelf Liner',
  back: false,
  ppc: 20,
  width: 120,
  height: 4.8,
  meta: {

  },
}].forEach((item) => {
  item.widthPx = item.width * item.ppc;
  item.heightPx = item.height * item.ppc;
  Types.POS_MATERIAL_TYPE.push(item.id);
  Types.POS_MATERIAL_TYPE_MAP[item.id] = item;
  Types.POS_MATERIAL_TYPE_LABELS.push(item.label);
  Types.POS_MATERIAL_TYPE_LABELS_MAP[item.id] = item.label;
});

Types.POS_MATERIAL_POSITION = [];
Types.POS_MATERIAL_POSITION_MAP = {};
Types.POS_MATERIAL_POSITION_LABELS = [];
Types.POS_MATERIAL_POSITION_LABELS_MAP = {};
Types.POS_MATERIAL_POSITION_TYPE_MAP = {};

[{
  id: 'HANGING',
  label: 'Hanging Sign',
  type: 'HANGING',
  target: 'SHELF',
}, {
  id: 'TOP',
  label: 'Top Board',
  type: 'TOP',
  target: 'SHELF',
}, {
  id: 'LEFT',
  label: 'Left Aisle Fin',
  type: 'SIDE',
  target: 'SHELF',
}, {
  id: 'RIGHT',
  label: 'Right Aisle Fin',
  type: 'SIDE',
  target: 'SHELF',
}, {
  id: 'COVER_FRONT',
  label: 'Shelf Cover Front',
  type: 'COVER',
  icon: 'F',
  target: 'SHELF',
}, {
  id: 'COVER_BACK',
  label: 'Shelf Cover Back',
  type: 'COVER',
  icon: 'B',
  target: 'SHELF',
}, {
  id: 'FLOOR_STICKER',
  label: 'Floor Stocker',
  type: 'FLOOR_STICKER',
  icon: 'S',
  target: 'SHELF',
}, {
  id: 'SHELF_LINER_TOP',
  label: 'Shelf Liner Top',
  icon: 'T',
  type: 'SHELF_LINER',
  target: 'SEGMENT',
}, {
  id: 'SHELF_LINER_BOTTOM',
  label: 'Shelf Liner Bottom',
  icon: 'B',
  type: 'SHELF_LINER',
  target: 'SEGMENT',
}].forEach((item) => {
  Types.POS_MATERIAL_POSITION.push(item.id);
  Types.POS_MATERIAL_POSITION_MAP[item.id] = item;
  Types.POS_MATERIAL_POSITION_LABELS.push(item.label);
  Types.POS_MATERIAL_POSITION_LABELS_MAP[item.id] = item.label;
  Types.POS_MATERIAL_POSITION_TYPE_MAP[item.id] = item.type;
});

Types.PRODUCT_DISPLAY_PPC = 26;
Types.PRODUCT_DISPLAY_RESOURCE = [];
Types.PRODUCT_DISPLAY_RESOURCE_MAP = {};
Types.PRODUCT_DISPLAY_RESOURCE_LABELS = [];
Types.PRODUCT_DISPLAY_RESOURCE_LABELS_MAP = [];
[{
  id: 'BACKGROUND',
  label: 'Background',
  size: {
    width: Types.getRoundedAmount(10 / Types.PRODUCT_DISPLAY_PPC),
    height: Types.getRoundedAmount(10 / Types.PRODUCT_DISPLAY_PPC),
    widthPx: 10,
    heightPx: 10,
  },
  help: [
    'Display background image, tiled across the',
    'non-brandable area.',
    // eslint-disable-next-line max-len
    'For solid color style use dimensions {{size.widthPx}}x{{size.heightPx}} px.',
  ],
  required: true,
}, {
  id: 'HEADER',
  label: 'Header',
  size: {
    width: 45,
    height: 30,
  },
  help: [
    'Dimensions {{size.widthPx}}x{{size.heightPx}} px.',
  ],
}, {
  id: 'FOOTER',
  label: 'Footer',
  size: {
    width: 45,
    height: 20,
  },
  help: [
    'Dimensions {{size.widthPx}}x{{size.heightPx}} px.',
  ],
}, {
  id: 'LEFT',
  label: 'Left',
  size: {
    width: 38,
    height: 148,
  },
  help: [
    'Display left side image.',
    'Dimensions {{size.widthPx}}x{{size.heightPx}} px.',
  ],
}, {
  id: 'RIGHT',
  label: 'Right',
  size: {
    width: 38,
    height: 148,
  },
  help: [
    'Display right side image.',
    'Dimensions {{size.widthPx}}x{{size.heightPx}} px.',
  ],
}].forEach((item) => {
  Object.keys(item.size).forEach((key) => {
    const keyPx = `${key}Px`;
    if (!/Px$/.test(key) && !isFinite(item.size[keyPx])) {
      item.size[keyPx] = item.size[key] * Types.PRODUCT_DISPLAY_PPC;
    }
  });
  item.help = item.help.map(
    line => line.replace(/\{\{([^}]+)\}\}/g, (_, key) => {
      if (key && key.length) {
        const value = getKey(item, key);
        if (!isUndefined(value)) {
          return value;
        }
      }
      return '';
    }),
  );
  Types.PRODUCT_DISPLAY_RESOURCE.push(item.id);
  Types.PRODUCT_DISPLAY_RESOURCE_MAP[item.id] = item;
  Types.PRODUCT_DISPLAY_RESOURCE_LABELS.push(item.label);
  Types.PRODUCT_DISPLAY_RESOURCE_LABELS_MAP[item.id] = item.label;
});

Types.SHELF_ALIGNMENT = [];
Types.SHELF_ALIGNMENT_MAP = {};
Types.SHELF_ALIGNMENT_LABELS = [];
Types.SHELF_ALIGNMENT_LABELS_MAP = {};
[{
  id: 'EVEN',
  label: 'Space out evenly',
}, {
  id: 'CENTER',
  label: 'Center alignment',
}, {
  id: 'JUSTIFY',
  label: 'Spread to edges',
}].forEach((item) => {
  Types.SHELF_ALIGNMENT.push(item.id);
  Types.SHELF_ALIGNMENT_MAP[item.id] = item;
  Types.SHELF_ALIGNMENT_LABELS.push(item.label);
  Types.SHELF_ALIGNMENT_LABELS_MAP[item.id] = item.label;
});

Types.PLANOGRAM_PURPOSE = [
  'PROJECT_AISLE_FRONT',
  'PROJECT_AISLE_BACK',
];

Types.PLANOGRAM_PURPOSE_LABELS = [
  'Project Aisle Front',
  'Project Aisle Back',
];

Types.PLANOGRAM_PURPOSE_LABELS_MAP = Types.PLANOGRAM_PURPOSE.reduce(
  (agr, purpose, index) => {
    agr[purpose] = Types.PLANOGRAM_PURPOSE_LABELS[index];
    return agr;
  },
  {},
);

Types.PLANOGRAM_PURPOSE_SECTION_MAP = {
  PROJECT_AISLE_FRONT: 'FRONT',
  PROJECT_AISLE_BACK: 'BACK',
};

Types.SHOPPER_PRODUCT_IMAGE_PPC = 13.5;

Types.SHOPPER_AGE = [];
Types.SHOPPER_AGE_MAP = {};
Types.SHOPPER_AGE_LABELS = [];
Types.SHOPPER_AGE_LABELS_MAP = {};
// [{
//   id: '18-29',
//   label: '18-29',
// }, {
//   id: '30-44',
//   label: '30-44',
// }, {
//   id: '45-59',
//   label: '45-59',
// }, {
//   id: '60-N',
//   label: '60+',
// }]
[{
  id: '18-34',
  label: '18-34',
}, {
  id: '35-54',
  label: '35-54',
}, {
  id: '55-N',
  label: '55+',
}].forEach((item) => {
  Types.SHOPPER_AGE.push(item.id);
  Types.SHOPPER_AGE_MAP[item.id] = item;
  Types.SHOPPER_AGE_LABELS.push(item.label);
  Types.SHOPPER_AGE_LABELS_MAP[item.id] = item.label;
});

Types.SHOPPER_GENDER = [];
Types.SHOPPER_GENDER_MAP = {};
Types.SHOPPER_GENDER_LABELS = [];
Types.SHOPPER_GENDER_LABELS_MAP = {};
[{
  id: 'MALE',
  label: 'Male',
}, {
  id: 'FEMALE',
  label: 'Female',
}, {
  id: 'OTHER',
  label: 'Other',
}].forEach((item) => {
  Types.SHOPPER_GENDER.push(item.id);
  Types.SHOPPER_GENDER_MAP[item.id] = item;
  Types.SHOPPER_GENDER_LABELS.push(item.label);
  Types.SHOPPER_GENDER_LABELS_MAP[item.id] = item.label;
});

Types.SHOPPER_PLATFORM = [];
Types.SHOPPER_PLATFORM_MAP = {};
Types.SHOPPER_PLATFORM_LABELS = [];
Types.SHOPPER_PLATFORM_LABELS_MAP = {};
[{
  id: 'DESKTOP',
  label: 'Desktop',
}, {
  id: 'MOBILE',
  label: 'Mobile',
}].forEach((item) => {
  Types.SHOPPER_PLATFORM.push(item.id);
  Types.SHOPPER_PLATFORM_MAP[item.id] = item;
  Types.SHOPPER_PLATFORM_LABELS.push(item.label);
  Types.SHOPPER_PLATFORM_LABELS_MAP[item.id] = item.label;
});

Types.PLANOGRAM = {
  scale: 3.2,
  list: [],
  map: {},
  target: {},
  section: {},
  floor: {
    size: { width: 400, height: 0 },
    bounds: {
      min: { x: 0, y: 0 },
      max: { x: 0, y: 0 },
    },
  },
  bounds: {
    min: { x: 0, y: 0 },
    max: { x: 0, y: 0 },
  },
  type: {
    display: {
      id: 'display',
      name: 'Display',
      sections: [{
        id: 'DISPLAY',
        name: 'Secondary Display',
        count: 1,
        group: 'display',
        target: ['project'],
      }],
      size: {
        width: 45,
        height: 178,
        depth: 38,
        top: 30,
        bottom: 20,
        gutterProduct: 0.5,
        gutterGroup: 3,
        priceHeight: 4,
        priceWidth: 6,
      },
      segment: {
        min: 4,
        max: 4,
        default: 4,
        posMaterialPositions: [],
      },
      attention: {
        padding: 80,
        grid: 20,
      },
      posMaterialPositions: [],
    },
    bay: {
      id: 'bay',
      name: 'Bay',
      sections: [{
        id: 'LIBRARY',
        discriminator: 'N',
        name: 'Library',
        count: 8,
        target: ['library'],
        group: 'library',
        initial: true,
      }, {
        id: 'LEFT',
        discriminator: 'L',
        prefix: 'L',
        name: 'Aisle Left',
        count: 8,
        target: ['project'],
        group: 'aisle-side',
        initial: true,
      }, {
        id: 'RIGHT',
        discriminator: 'R',
        prefix: 'R',
        name: 'Aisle Right',
        count: 8,
        group: 'aisle-side',
        target: ['project'],
        reverse: true,
      }, {
        id: 'FRONT',
        discriminator: 'F',
        prefix: 'F',
        name: 'Aisle Front',
        count: 8,
        group: 'aisle-end',
        target: ['project'],
        hidden: true,
      }, {
        id: 'BACK',
        discriminator: 'B',
        prefix: 'B',
        name: 'Aisle Back',
        count: 8,
        group: 'aisle-end',
        target: ['project'],
        hidden: true,
      }],
      fridge: true,
      size: {
        width: 120,
        height: 200,
        depth: 40,
        top: 0,
        bottom: 10,
        gutterProduct: 0.5,
        gutterGroup: 1,
        priceHeight: 4,
        priceWidth: 6,
      },
      segment: {
        min: 4,
        max: 10,
        default: 5,
        posMaterialPositions: Types.POS_MATERIAL_POSITION.reduce(
          (agr, position) => {
            const { target } = Types.POS_MATERIAL_POSITION_MAP[position];
            if (target === 'SEGMENT') {
              agr.push(position);
            }
            return agr;
          },
          [],
        ),
      },
      attention: {
        padding: 80,
        grid: 20,
      },
      posMaterialPositions: Types.POS_MATERIAL_POSITION.reduce(
        (agr, position) => {
          const { target } = Types.POS_MATERIAL_POSITION_MAP[position];
          if (target === 'SHELF') {
            agr.push(position);
          }
          return agr;
        },
        [],
      ),
    },
  },
};

Object.keys(Types.PLANOGRAM.type).forEach((typeId) => {
  const {
    scale,
    target: targets,
    section: sections,
    type: types,
    list,
    map,
  } = Types.PLANOGRAM;
  const type = types[typeId];
  Object.keys(type.size).forEach((sizeKey) => {
    type.size[`${sizeKey}Px`] = type.size[sizeKey] * scale;
  });
  type.sections.forEach((section) => {
    if (!sections[section.id]) {
      sections[section.id] = section;
      sections[section.id].type = type;
    }
    section.target.forEach((targetId) => {
      if (!targets[targetId]) {
        targets[targetId] = {
          list: [],
          supportedType: [],
          supportedSection: [],
        };
      }
      const target = targets[targetId];
      if (!target.supportedType.includes(type.id)) {
        target.supportedType.push(type.id);
      }
      if (!target.supportedSection.includes(section.id)) {
        target.supportedSection.push(section.id);
      }
    });
    section.interactive = section.hidden !== true;
    section.list = [];
    for (let i = 0; i < section.count; i++) {
      const labelIndex = section.reverse
      ? section.count - i - 1
      : i;
      const id = `${type.id}${
        section.discriminator ? section.discriminator : ''
      }${
        section.count > 1 ? `${labelIndex + 1}` : ''
      }`;
      const suffix = `${
        section.prefix ? `${section.prefix}` : ''
      }${
        section.count > 1 ? `${labelIndex + 1}` : ''
      }`;
      map[id] = {
        id,
        index: i,
        name: `${type.name}${suffix.length ? ` ${suffix}` : ''}`,
        type,
        section,
      };
      list.push(map[id]);
      section.list.push(id);
      for (let t = 0; t < section.target.length; t++) {
        targets[section.target[t]].list.push(id);
      }
    }
  });
});

((config, cache = {}) => {
  [
    [
      'LEFT',
      90,
      () => 0,
      ({ size: { depth } }) => (config.aisleWidth / -2) - depth,
      () => config.shelfDistance,
      'wy',
      [
        ['bounds.max.x', 'bounds.min.x'],
        ['bounds.max.x', 'floor.bounds.min.x'],
        ['size.width', 'floor.size.height'],
      ],
    ], [
      'RIGHT',
      270,
      () => 0,
      ({ size: { depth } }) => (config.aisleWidth / -2) - depth,
      () => config.shelfDistance,
      'wy',
      [
        ['bounds.min.x', 'bounds.max.x'],
        ['bounds.min.x', 'floor.bounds.max.x'],
        ['size.width', 'floor.size.height'],
      ],
    ], [
      'FRONT',
      180,
      () => 0,
      ({ size: { depth } }) => (cache.wy / -2) - depth - config.aisleCapOffset,
      () => config.aisleCapOffset + config.aisleCapDistance,
      'wx',
      [
        ['bounds.min.y', 'bounds.max.y'],
        ['bounds.min.y', 'floor.bounds.max.y'],
      ],
    ], [
      'BACK',
      0,
      () => 0,
      ({ size: { depth } }) => (cache.wy / -2) - depth - config.aisleCapOffset,
      () => config.aisleCapOffset + config.aisleCapDistance,
      'wx',
      [
        ['bounds.max.y', 'bounds.min.y'],
        ['bounds.max.y', 'floor.bounds.min.y'],
      ],
    ],
    [
      'DISPLAY',
      180,
      () => config.aisleWidth * 0.25,
      ({ size: { depth } }) => (cache.wy / -2) + depth,
      () => config.shelfDistance,
      'wd',
    ],
  ].forEach(
    ([
      sectionId,
      orientation,
      getX,
      getY,
      getDistance,
      widthKey,
      transfers = [],
    ]) => {
      const section = Types.PLANOGRAM.section[sectionId];
      const size = section.size = {};
      size.orientation = orientation;
      size.width = section.type.size.width * section.count;
      size.height = section.type.size.height;
      size.depth = section.type.size.depth;
      section.bounds = { min: { x: 0, y: 0 }, max: { x: 0, y: 0 } };
      cache[widthKey] = Math.max(cache[widthKey] || 0, size.width);
      const x = getX(section);
      const y = getY(section);
      const distance = getDistance(section);
      const [bx1, by1, bx2, by2] = [
        x - distance - (size.width / 2),
        y - distance,
        x + distance + (size.width / 2),
        y + distance + size.depth,
      ];
      const { x: rx, y: ry } = Types.rotatePoint(x, y, orientation, 0, 0, 1);
      const [{ x: rbx1, y: rby1 }, { x: rbx2, y: rby2 }] = [
        Types.rotatePoint(bx1, by1, orientation, 0, 0, 1),
        Types.rotatePoint(bx2, by2, orientation, 0, 0, 1),
      ];
      size.x = Types.getRoundedAmount(rx, 1);
      size.y = Types.getRoundedAmount(ry, 1);
      section.bounds.min.x = Types.getRoundedAmount(Math.min(rbx1, rbx2), 1);
      section.bounds.min.y = Types.getRoundedAmount(Math.min(rby1, rby2), 1);
      section.bounds.max.x = Types.getRoundedAmount(Math.max(rbx1, rbx2), 1);
      section.bounds.max.y = Types.getRoundedAmount(Math.max(rby1, rby2), 1);
      for (let t = 0; t < transfers.length; t++) {
        const [keySrc, keyDst] = transfers[t];
        if (keySrc && keyDst) {
          setKey(Types.PLANOGRAM, keyDst, getKey(section, keySrc));
        }
      }
    },
  );
})({
  aisleWidth: Types.PLANOGRAM.floor.size.width,
  aisleCapOffset: 100,
  aisleCapDistance: 90,
  shelfDistance: 50,
});

Types.PLANOGRAM.maps = {
  floor: {
    id: 'floor',
    grid: 20,
    size: {
      width: Math.floor((
        Types.PLANOGRAM.bounds.max.x
        - Types.PLANOGRAM.bounds.min.x
      ) / 20),
      height: Math.floor((
        Types.PLANOGRAM.bounds.max.y
        - Types.PLANOGRAM.bounds.min.y
      ) / 20),
    },
    overrides: [
      // force 0 around start position
      { index: 576, value: 0 },
      { index: 577, value: 0 },
      { index: 578, value: 0 },
    ],
  },
  ...Types.PLANOGRAM.target.project.supportedSection.reduce(
    (agr, sectionId) => {
      const section = Types.PLANOGRAM.section[sectionId];
      if (section.interactive) {
        const { attention: { grid } } = section.type;
        const id = section.id.toLowerCase();
        agr[id] = {
          id,
          grid,
          size: {
            width: Math.floor(section.size.width / grid),
            height: Math.floor(section.size.height / grid),
          },
          overrides: [],
        };
      }
      return agr;
    },
    {},
  ),
};

Types.PLANOGRAM.validateSegmentSpace = (
  groups,
  type,
  segmentsCount,
  // segmentIndex,
) => {
  let used = 0;
  const {
    type: {
      [type]: {
        size: {
          top: shelfTop,
          bottom: shelfBottom,
          width: shelfWidth,
          height: shelfHeight,
          gutterProduct,
          gutterGroup,
          priceHeight,
        },
      },
    },
  } = Types.PLANOGRAM;
  for (let i = 0; i < groups.length; i++) {
    const {
      product: {
        width: productWidth,
        height: productHeight,
      },
      span,
      spanY,
    } = groups[i];
    if (span < 1 || spanY < 1) {
      return false;
    }
    if (
      ((productHeight * spanY) + 0.5)
      >= ((shelfHeight - shelfTop - shelfBottom) / segmentsCount) - priceHeight
    ) {
      return false;
    }
    used += (
      gutterGroup
      + (span * productWidth)
      + ((span - 1) * gutterProduct)
    );
    if (used > shelfWidth) {
      return false;
    }
  }
  return true;
};

Types.createConstant = valuesArray => valuesArray.reduce(
  (agr, value) => {
    agr[value] = value;
    return agr;
  },
  {},
);

Types.decimalize = (number, decimalSeparator = '.') => {
  const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
  return number.toFixed(2)
  .split('')
  .reverse()
  .join('')
  .split('.')
  .map((value, i) => (
    i === 1
    ? value
      .replace(/(.{3})/g, `$1${thousandSeparator}`)
      .replace(new RegExp(`\\${thousandSeparator}$`, 'g'), '')
    : value
  ))
  .join(decimalSeparator)
  .split('')
  .reverse()
  .join('');
};

Types.validatePassword = (password) => {
  if (!isString(password)) {
    throw new Error('Invalid password');
  }
  password = password.trim();
  if (password.length < 8) {
    throw new Error('Password must be at least 8 characters long');
  }
  if (
    !/[a-z]/.test(password)
    || !/[A-Z]/.test(password)
    || !/[0-9]/.test(password)
    || !/[\W_]/.test(password)
  ) {
    // eslint-disable-next-line max-len
    throw new Error('Password must contain a lowercase letter, an uppercase letter, a number and a symbol');
  }
  return true;
};

Types.CONSTANTS = {};

// [
//   'RIGHT',
//   'LEFT',
//   'FRONT',
//   'BACK',
//   'DISPLAY',
// ].forEach((sectionId) => {
//   console.log(`${sectionId}:`);
//   console.log(
//     'x', Types.PLANOGRAM.section[sectionId].size.x,
//     'y', Types.PLANOGRAM.section[sectionId].size.y,
//   );
//   console.log(
//     'w', Types.PLANOGRAM.section[sectionId].size.width,
//     'd', Types.PLANOGRAM.section[sectionId].size.depth,
//   );
//   console.log(Types.PLANOGRAM.section[sectionId].bounds);
// });

export default Types;
