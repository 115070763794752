import mongoose from 'mongoose/browser';

import React, { useContext, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Context as DNDContext } from '../DND';
import { useProduct } from '../Context';
import { GroupWrapperHTML } from '../Shelfs/Shelf/Group';
import GroupSettings from '../Shelfs/Shelf/GroupSettings';

import { useDelayedValue } from '../../utils';

const { Types: { ObjectId } } = mongoose;

const GroupWrapper = styled(GroupWrapperHTML)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  z-index: 1;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  ${({ dragging }) => dragging && css`
    pointer-events: none;
    z-index: 999999 !important;
  `}
`;

const ProductWrapperHTML = ({ innerRef, ...props }) => (
  <div ref={innerRef} {...props} />
);

const ProductWrapper = styled(ProductWrapperHTML)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const ProductImageHTML = ({
  src,
  hidden,
  dragging,
  scale,
  ...props
}) => (
  <div {...props} />
);

const ProductImage = styled(ProductImageHTML)`
  width: ${({ scale }) => `${100 * scale}%`};
  padding-top: ${({ scale }) => `${100 * scale}%`};
  opacity: ${({ hidden, dragging }) => (
    !hidden ? 1 : dragging ? 0.3 : 0
  )};
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  ${({ src }) => (
    src
    ? css`
        background-image: url("${src}");
      `
    : css`
        background-color: #ededed;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      `
  )}
`;

ProductImage.defaultProps = {
  scale: 0.8,
};

const ProductNameHTML = ({ hidden, dragging, ...props }) => (
  <div {...props} />
);

const ProductName = styled(ProductNameHTML)`
  padding-top: 10px;
  text-align: center;
  opacity: ${({ hidden, dragging }) => (
    !hidden ? 1 : dragging ? 0.3 : 0
  )};
`;

export default function Product({
  product: productInput,
  currency,
  organization,
  getBrand,
  getCategory,
}) {
  const product = useProduct(productInput);
  const productImageSrc = (
    product && product.image
    ? product.image.src
    : null
  );
  const {
    data,
    dstList,
    dragging,
    dragStart,
    dragMoveEvent,
    dragEndEvent,
  } = useContext(DNDContext);
  // const draggableRef = useRef();
  const wrapperRef = useRef();
  const cacheRef = useRef({
    dstList,
    item: {
      _id: `${ObjectId()}`,
      product,
      span: 1,
      spanY: 1,
    },
  });
  const [draggableStyle, setDraggableStyle] = useState({});
  const [isDraggable, setIsDraggable] = useState(false);
  if (
    cacheRef.current.dstList
    && !dragging
  ) {
    cacheRef.current.item = {
      _id: `${ObjectId()}`,
      product,
      span: 1,
      spanY: 1,
    };
  }
  cacheRef.current.dstList = dstList;
  const isDragging = (
    dragging
    && data
    && data._id === cacheRef.current.item._id
  );
  const isDraggingDelayed = useDelayedValue(
    isDragging,
    (oldValue, newValue) => (!newValue ? 300 : 0),
  );
  const isDraggableDelayed = useDelayedValue(
    isDraggable || isDraggingDelayed,
    (oldValue, newValue) => (!newValue ? 300 : 0),
  );
  useEffect(
    () => {
      if (isDraggingDelayed && wrapperRef.current) {
        const {
          x,
          y,
          width,
          height,
        } = wrapperRef.current.getBoundingClientRect();
        setDraggableStyle({
          position: 'fixed',
          left: `${x}px`,
          top: `${y}px`,
          width: `${width}px`,
          height: `${height}px`,
        });
      } else {
        setDraggableStyle({});
      }
    },
    [isDraggingDelayed],
  );
  return (
    <ProductWrapper innerRef={wrapperRef}>
      <ProductImage
        src={productImageSrc}
        dragging={isDragging}
        hidden
      />
      <ProductName
        dragging={isDragging}
        hidden
      >
        {product.name}
      </ProductName>
      {
        (!data || data._id !== cacheRef.current.item._id || !dstList)
        ? (
            <GroupWrapper
              draggable={isDraggableDelayed}
              key={cacheRef.current.item._id}
              dragging={isDraggingDelayed}
              layoutId={cacheRef.current.item._id}
              layoutStyle={draggableStyle}
              onDragStart={() => dragStart(cacheRef.current.item)}
              onDrag={dragMoveEvent}
              onDragEnd={dragEndEvent}
              onMouseEnter={() => setIsDraggable(true)}
              onMouseLeave={() => setIsDraggable(false)}
            >
              <GroupSettings
                dragging={isDragging}
                isActive={isDraggable}
                product={product}
                currency={currency}
                organization={organization}
                getBrand={getBrand}
                getCategory={getCategory}
                placement="right"
                showInfoName={false}
              >
                <ProductImage
                  src={productImageSrc}
                />
              </GroupSettings>
              <ProductName>
                {product.name}
              </ProductName>
            </GroupWrapper>
          )
        : null
      }
    </ProductWrapper>
  );
}
