import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import Settings from './Settings';
import POSMaterialPosition from './POSMaterialPosition';

import Types from '../../../../../modules/types';

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolbarDivider = styled.div`
  height: 20px;
  width: 1px;
  background-color: #8e8e8e;
  margin-left: 9px;
  margin-right: 8px;
`;

export default function Toolbar({
  id,
  client,
  target,
  value: shelfs,
  onChange: onChangeShelfs,
  valueShelf,
  onChangeShelf,
  getProduct,
  region,
  organization,
  disabled,
}) {
  const [openItemId, setOpenItemId] = useState(null);
  const { map: { [id]: { type: { posMaterialPositions } } } } = Types.PLANOGRAM;
  const setClosedItemId = (itemId) => {
    if (itemId === openItemId) {
      setOpenItemId(null);
    }
  };
  const onApplyInDirection = useCallback(
    (direction, position, posMaterialId) => {
      switch (direction) {
        case 'HORIZONTAL': {
          const { section } = Types.PLANOGRAM.map[id];
          const newShelfs = { ...shelfs };
          section.list.forEach((shelfId) => {
            if (
              newShelfs[shelfId]
              && (
                !newShelfs[shelfId].posMaterials
                || newShelfs[shelfId].posMaterials[position] !== posMaterialId
              )
            ) {
              newShelfs[shelfId] = {
                ...newShelfs[shelfId],
                posMaterials: {
                  ...(newShelfs[shelfId].posMaterials || {}),
                  [position]: posMaterialId,
                },
              };
            }
          });
          onChangeShelfs(newShelfs);
        }
        break;
        case 'VERTICAL':
        break;
        default:
      }
    },
    [
      id,
      shelfs,
      onChangeShelfs,
    ],
  );
  return (
    <ToolbarWrapper>
      {
        target === 'project' && posMaterialPositions.length
        ? (
            <>
              {
                posMaterialPositions.map(position => (
                  <POSMaterialPosition
                    key={position}
                    id={id}
                    icon={Types.POS_MATERIAL_POSITION_MAP[position].icon}
                    client={client}
                    position={position}
                    value={valueShelf}
                    onChange={onChangeShelf}
                    onApplyInDirection={onApplyInDirection}
                    supportsApplyVertically={false}
                    toolbarItemId={`pos-position-${position}`}
                    toolbarItemIdOpen={openItemId}
                    onOpen={setOpenItemId}
                    onClose={setClosedItemId}
                    region={region}
                    organization={organization}
                    disabled={disabled}
                  />
                ))
              }
              <ToolbarDivider />
            </>
          )
        : null
      }
      <Settings
        id={id}
        value={valueShelf}
        shelfs={shelfs}
        target={target}
        onChangeShelfs={onChangeShelfs}
        onChangeShelf={onChangeShelf}
        getProduct={getProduct}
        toolbarItemId="settings"
        toolbarItemIdOpen={openItemId}
        onOpen={setOpenItemId}
        onClose={setClosedItemId}
        disabled={disabled}
      />
    </ToolbarWrapper>
  );
}
