import { useRef, useState, useEffect } from 'react';

export default function useDelayedValue(
  currentValue,
  getDelay = () => 0,
  getValueDidChange = (newValue, oldValue) => newValue !== oldValue
) {
  const [value, setValue] = useState(currentValue);
  const ref = useRef({ timeout: null, getDelay, getValueDidChange });
  useEffect(
    () => {
      if (ref.current.getValueDidChange(value, currentValue)) {
        const { timeout } = ref.current;
        const delay = ref.current.getDelay(value, currentValue);
        if (delay > 0) {
          ref.current.timeout = setTimeout(setValue, delay, currentValue);
        } else {
          setValue(currentValue);
        }
        return () => clearTimeout(timeout);
      }
      return undefined;
    },
    [value, currentValue],
  );
  return value;
}
