import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
} from './fields';

const {
  viewerIsNotAdmin,
  viewerIsNotContentManager,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Regions',
    icon: 'flag',
    path: '/regions',
    redirect: [['redirect.unauthorized']],
    skip: [
      ['condition.and', [
        viewerIsNotAdmin,
        viewerIsNotContentManager,
      ]],
    ],
    archiveConfig: {
      icon: 'flag',
      title: 'Regions',
      label: 'Regions',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/regions',
      backButtonPaths: ['/regions'],
      headerTitle: 'Create Region',
      loadUrl: '/regions/:id',
    },
    singleEditConfig: {
      backButtonPaths: ['/organizations'],
      actions: [
        ['ActionSaveAndReload'],
        ['ActionDelete'],
      ],
    },
    fields: FIELDS_ALL,
  }])
).pages;
