/* eslint-disable react/no-unused-state */
import React, {
  Component,
  createContext,
} from 'react';

import { createGlobalStyle, css } from 'styled-components';

const Context = createContext();

const GlobalStyle = createGlobalStyle`
  ${({ dragging, droppable }) => (
    !dragging
    ? null
    : droppable
    ? css`
        html, body {
          * {
            cursor: grabbing !important;
            cursor: -moz-grabbing !important;
            cursor: -webkit-grabbing !important;
          }
        }
      `
    : css`
        html, body {
          * {
            cursor: no-drop !important;
          }
        }
      `
  )}
`;

export class Provider extends Component {
  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.count = -1;
    this.state = {
      addList: this.addList,
      removeList: this.removeList,
      dragStart: this.dragStart,
      dragMove: this.dragMove,
      dragMoveEvent: event => this.dragMove(event.pageX, event.pageY),
      dragEnd: this.dragEnd,
      dragEndEvent: event => this.dragEnd(event.pageX, event.pageY),
      dragging: false,
      data: null,
      srcList: null,
      srcIndex: null,
      dstList: null,
      dstListAttempt: null,
      dstIndex: null,
    };
    this.lists = {};
    this.mutable = {};
  }

  addList = (id, ref, data = {}) => {
    this.lists[id] = { ...data, id, ref };
  }

  removeList = (id) => {
    delete this.lists[id];
  }

  dragStart = (data, srcList, srcIndex) => {
    if (!this.mutable || !this.mutable.dragging) {
      this.mutable = {
        dragging: true,
        data,
        srcList,
        srcIndex,
        dstList: srcList,
        dstListAttempt: srcList,
        dstIndex: srcIndex,
        dstLists: [],
        dstListsDisabled: [],
        dstListsEnabled: [],
      };
      if (srcList) {
        this.mutable.dstListsEnabled.push(srcList);
      }
      Object.keys(this.lists).forEach((id) => {
        const { ref, ...list } = this.lists[id];
        const { x, y, width, height } = ref.current.getBoundingClientRect();
        this.mutable.dstLists.push({
          ...list,
          rect: {
            sx: x,
            ex: x + width,
            sy: y,
            ey: y + height,
          },
        });
      });
      this.setState({
        dragging: true,
        data,
        srcList,
        srcIndex,
        dstList: srcList,
        dstListAttempt: srcList,
        dstIndex: srcIndex,
      });
    }
  }

  dragMove = (dragX, dragY) => {
    const { mutable } = this;
    if (mutable.x === dragX && mutable.y === dragY) {
      return;
    }
    mutable.x = dragX;
    mutable.y = dragY;
    const updates = { updated: false, state: {} };
    const list = mutable.dstLists.find(({ rect }) => (
         dragX >= rect.sx
      && dragX <= rect.ex
      && dragY > rect.sy
      && dragY <= rect.ey
    ));
    let dstListNew = list ? list.id : null;
    if (mutable.dstListAttempt !== dstListNew) {
      mutable.dstListAttempt = dstListNew;
      updates.updated = true;
      updates.state.dstListAttempt = dstListNew;
    }
    if (dstListNew && mutable.dstListsDisabled.includes(dstListNew)) {
      dstListNew = null;
    }
    if (dstListNew !== mutable.dstList) {
      let dstListDisabled = false;
      if (dstListNew) {
        let dstListEnabled = mutable.dstListsEnabled.includes(dstListNew);
        if (!dstListEnabled) {
          const dstListData = this.lists[dstListNew];
          const { getCanAcceptData } = dstListData;
          if (
            !getCanAcceptData
            || getCanAcceptData(mutable.data, dstListData)
          ) {
            mutable.dstListsEnabled.push(dstListNew);
            dstListEnabled = true;
          } else {
            mutable.dstListsDisabled.push(dstListNew);
            dstListEnabled = false;
          }
        }
        if (dstListEnabled) {
          mutable.dstList = dstListNew;
          updates.updated = true;
          updates.state.dstList = dstListNew;
        } else {
          dstListDisabled = true;
        }
      } else {
        dstListDisabled = true;
      }
      if (dstListDisabled) {
        dstListNew = null;
        if (mutable.srcList) {
          if (mutable.srcList !== mutable.dstList) {
            mutable.dstList = mutable.srcList;
            mutable.dstIndex = mutable.srcIndex;
            updates.updated = true;
            updates.state.dstList = mutable.srcList;
            updates.state.dstIndex = mutable.srcIndex;
          }
        } else {
          mutable.dstList = null;
          mutable.dstIndex = null;
          updates.updated = true;
          updates.state.dstList = null;
          updates.state.dstIndex = null;
        }
      }
    }
    if (mutable.dstList) {
      const { ref: { current: dstListElement } } = this.lists[mutable.dstList];
      if (dstListElement) {
        const children = dstListElement.querySelectorAll(
          '[data-dragging="false"]',
        );
        if (!children.length) {
          if (mutable.dstIndex !== 0) {
            mutable.dstIndex = 0;
            updates.updated = true;
            updates.state.dstIndex = 0;
          }
        } else {
          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.dataset.dragging === 'false') {
              const {
                x: itemStartX,
                width: itemWidth,
              } = child.getBoundingClientRect();
              const itemX = itemStartX + (itemWidth / 2);
              const indexOffset = (
                  dragX <= itemX
                ? 0
                : (i === (children.length - 1) && dragX > itemX)
                ? 1
                : null
              );
              if (indexOffset !== null) {
                const index = parseInt(child.dataset.index, 10) + indexOffset;
                if (mutable.dstIndex !== index) {
                  mutable.dstIndex = index;
                  updates.updated = true;
                  updates.state.dstIndex = index;
                }
                break;
              }
            }
          }
        }
      }
    }
    if (mutable.srcList && !mutable.dstList) {
      mutable.dstList = mutable.srcList;
      mutable.dstIndex = mutable.srcIndex;
      updates.updated = true;
      updates.state.dstList = mutable.srcList;
      updates.state.dstIndex = mutable.srcIndex;
    }
    if (updates.updated) {
      this.setState(updates.state);
    }
  }

  dragEnd = (/* x, y */) => {
    const { srcList, srcIndex, data } = this.mutable;
    let { dstList, dstIndex } = this.mutable;
    if (!dstList) {
      dstList = srcList;
      dstIndex = srcIndex;
    }
    const dstListData = dstList ? this.lists[dstList] : null;
    this.mutable = {};
    this.setState({
      dragging: false,
      data: null,
      srcList: null,
      srcIndex: null,
      dstList: null,
      dstListAttempt: null,
      dstIndex: null,
    });
    if (dstListData && dstListData.onDragEnd) {
      dstListData.onDragEnd(
        srcList,
        srcIndex,
        dstList,
        dstIndex,
        data,
      );
    }
  }

  render() {
    const {
      dragging,
      dstListAttempt,
      dstList,
    } = this.state;
    this.count += 1;
    return (
      <Context.Provider value={this.state}>
        <GlobalStyle
          dragging={dragging}
          droppable={!(dstListAttempt && dstListAttempt !== dstList)}
        />
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Context;
