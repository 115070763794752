import Admin from 'hive-admin';
import OriginalActionSave from 'hive-admin/src/components/ActionSave';

class ActionSave extends OriginalActionSave {
  static config = {
    ...OriginalActionSave.config,
    ghost: true,
    icon: 'save',
  }
}

Admin.addToLibrary(
  'ActionSave',
  config => ActionSave.create(config),
);
