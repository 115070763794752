import React from 'react';
import styled from 'styled-components';

import { Link } from 'hive-admin/node_modules/react-router-dom';

import { CheckOutlined } from '@ant-design/icons';

import {
  columnCreatedAt,
} from '../common';

import Types from '../../modules/types';

const IconActiveHTML = ({ active, ...props }) => (
  <CheckOutlined {...props} />
);

const IconActive = styled(IconActiveHTML)`
  opacity: ${({ active }) => (active ? 1 : 0)};
  margin-right: 10px;
`;

function isColumnAvailableOnlyForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

function isColumnAvailableOnlyForAdminWhenRoleIsCustomer(props) {
  if (!isColumnAvailableOnlyForAdmin(props)) {
    return false;
  }
  return props.searchParams.role === 'customer';
}

function isColumnAvailableOnlyForAdminWhenRoleIsNotDefined(props) {
  if (!isColumnAvailableOnlyForAdmin(props)) {
    return false;
  }
  const { role } = props.searchParams;
  return !role || role === 'any';
}

export const columnName = {
  path: 'name',
  link: '/users/:_id',
  render: record => (
    <Link to={`/users/${record._id}`}>
      <IconActive active={record.active} />
      {record.name}
    </Link>
  ),
};

export const columnEmail = { path: 'email' };

export const columnRole = {
  path: 'role',
  render: record => Types.ROLE_LABELS_MAP[record.role],
  isAvailable: isColumnAvailableOnlyForAdminWhenRoleIsNotDefined,
};

export const columnOrganization = {
  path: 'organization.name',
  title: 'Organization',
  link: '/organizations/:organization._id',
  isAvailable: isColumnAvailableOnlyForAdminWhenRoleIsCustomer,
};

export const ALL = [
  columnName,
  columnEmail,
  columnRole,
  columnCreatedAt,
  columnOrganization,
];
