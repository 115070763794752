import React from 'react';

import Admin from 'hive-admin';
import styled from 'styled-components';

import Input from 'antd/lib/input';

import FieldTable, { InputWrapper } from './FieldTable';

const TranslationOriginal = styled.p`
  margin: 0px;
  padding-left: 3px;
  white-space: pre-wrap;
  font-size: 80%;
`;

const TranslationInput = styled(Input.TextArea)`
  font-size: 80%;
`;

const TranslationWrapper = styled(InputWrapper)`
  padding: 0px;
  margin-top: -3px;
  margin-bottom: -3px;
`;

Admin.addToLibrary(
  'FieldTranslations',
  (config = {}) => FieldTable.create({
    table: {
      showHeader: false,
      scroll: { x: '370px' },
    },
    controls: false,
    columns: [{
      key: 'reference',
      fields: [{
        ComponentInput({ value }) {
          return (
            <TranslationOriginal>
              {value}
            </TranslationOriginal>
          );
        },
        name: 'reference',
        props: {
          size: 'default',
          disabled: true,
        },
      }],
    }, {
      key: 'translation',
      fields: [{
        ComponentInput({ ...props }) {
          return (
            <TranslationInput
              {...props}
              autoSize={{ minRows: 1 }}
            />
          );
        },
        ComponentWrapper({ ...props }) {
          return (
            <TranslationWrapper
              {...props}
            />
          );
        },
        name: 'translation',
        props: {
          size: 'default',
        },
      }],
    }],
    ...config,
  }),
);
