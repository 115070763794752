import React from 'react';

import Query from 'hive-admin/src/components/Query';
// eslint-disable-next-line max-len
import DefaultInfiniteScrollQuery from 'hive-admin/src/components/InfiniteScrollQuery';

export default class InfiniteScrollQuery extends DefaultInfiniteScrollQuery {
  render() {
    return (
      <Query
        method={this.props.method}
        url={this.props.getUrl(this.props, this.state.page)}
        extractData={this.handleData}
        page={this.state.page}
        client={this.props.client}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }
}
