import isFinite from 'lodash/isFinite';
import isString from 'lodash/isString';

import React from 'react';

import tests from '../../helpers/tests';

import Types from '../../modules/types';

const {
  viewerIsNotAdmin,
  activityNotCreate,
  viewerUnitIsCm,
  viewerUnitIsInch,
  isActivityEditAndlibraryPlanogramIsPurposed,
} = tests;

export function cloneField(field, changes = {}) {
  return [field[0], { ...field[1], ...changes }];
}

export function getOrganizationBasedExtraQueryProps(props) {
  const organization = props.form.getFieldValue('organization');
  return [{
    organization: {
      IN: organization ? [organization, null] : [null],
    },
  }];
}

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  placeholder: 'Item name',
  rules: [['validation.isRequired']],
}];

export const fieldOrganization = ['FieldConnectionSelect', {
  name: 'organization',
  label: null,
  getInitialValue: (field, props) => (
      props.data
    ? props.data.organization
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? props.viewer.organization
    : null
  ),
  prefix: 'Organization:',
  initialValue: null,
  placeholder: 'Organization item belongs to',
  url: '/organizations',
  searchPaths: ['name'],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
  hidden: [viewerIsNotAdmin],
  allowClear: true,
}];

export const fieldRegion = ['FieldConnectionSelect', {
  name: 'region',
  label: null,
  prefix: 'Region:',
  placeholder: 'Target Region',
  url: '/regions',
  searchPaths: ['name', 'currency'],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
}];

export const fieldImage = ['FieldUploadRefs', {
  name: 'image',
  label: null,
  rules: [['validation.isRequired']],
  accept: 'image/png',
  transformations: [
    ['GM', { command: 'compress', maxWidth: 700 }],
  ],
}];

export const fieldImageProduct = [fieldImage[0], {
  ...fieldImage[1],
  description: (
    <>
      Please see our image upload guidelines&nbsp;
      <a
        rel="noopener noreferrer"
        href="https://shopnosis.slite.com/p/note/B8i9xf8HMa6Wgfys2yrbQS"
        target="_blank"
      >
        here
      </a>
      .
    </>
  ),
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
  transformations: [
    ['GM', { command: 'compress', maxWidth: 700 }],
  ],
}];

export const fieldsImagePOSMaterial = [
  ['image.front', 'POS Material front facing image.'],
  ['image.back', 'POS Material backside.', [
    (props) => {
      if (props && props.form) {
        const type = props.form.getFieldValue('type');
        const config = Types.POS_MATERIAL_TYPE_MAP[type];
        if (!config || !config.back) {
          return true;
        }
      }
      return false;
    },
  ]],
].map(([name, label = '', skip = []]) => [fieldImage[0], {
  ...fieldImage[1],
  name,
  description: (
    <>
      {label}
      <br />
      Please see our image upload guidelines&nbsp;
      <a
        rel="noopener noreferrer"
        href="https://shopnosis.slite.com/p/note/B8i9xf8HMa6Wgfys2yrbQS"
        target="_blank"
      >
        here
      </a>
      .
    </>
  ),
  getTransformations: (props) => {
    const posMaterialType = (
      props.form.getFieldValue('type')
      || (
        props.data
        ? props.data.type
        : null
      )
    );
    const posMaterialConfig = Types.POS_MATERIAL_TYPE_MAP[posMaterialType];
    if (posMaterialConfig) {
      return [
        ['GM', { command: 'compress', maxWidth: posMaterialConfig.widthPx }],
      ];
    }
    return props.transformations;
  },
  transformations: [
    ['GM', { command: 'compress', maxWidth: 1200 }],
  ],
  skip,
}]);

export const fieldCategory = ['FieldConnectionSelect', {
  name: 'category',
  label: null,
  prefix: 'Category:',
  placeholder: 'Select item category',
  url: '/library/categories',
  searchPaths: ['name'],
  rules: [['validation.isRequired']],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
}];

export const fieldCategories = [fieldCategory[0], {
  ...fieldCategory[1],
  name: 'categories',
  prefix: 'Categories:',
  placeholder: 'Select item categories',
  mode: 'multiple',
}];

export const fieldCompany = ['FieldConnectionSelect', {
  name: 'company',
  label: null,
  prefix: 'Company:',
  placeholder: 'Select item company',
  url: '/library/companies',
  searchPaths: ['name'],
  getExtraQueryConditions: getOrganizationBasedExtraQueryProps,
  rules: [['validation.isRequired']],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
}];

export const fieldBrand = ['FieldConnectionSelect', {
  name: 'brand',
  label: null,
  prefix: 'Brand:',
  placeholder: 'Select item brand',
  url: '/library/brands',
  getChoiceLabel: choice => `${
    choice.data.name
  } / by ${
    choice.data.company.name
  }`,
  getExtraQueryProps: (/* props */) => ({ populate: { company: true } }),
  searchPaths: ['name'],
  getExtraQueryConditions: getOrganizationBasedExtraQueryProps,
  rules: [['validation.isRequired']],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
}];

export const fieldsProductDimensions = [
  ['width', 'Width', [viewerUnitIsInch]],
  ['widthInch', 'Width', [viewerUnitIsCm]],
  ['height', 'Height', [viewerUnitIsInch]],
  ['heightInch', 'Height', [viewerUnitIsCm]],
].map(([name, label, skip]) => ['FieldLengthUnit', {
  name,
  label: null,
  prefix: `${label}:`,
  align: 'right',
  type: 'number',
  skip,
  placeholder: '0',
  rules: [['validation.isRequired'], ['validation.isNumber']],
  virtual: [activityNotCreate],
  disabled: [activityNotCreate],
}]);

export const fieldBarcode = ['FieldText', {
  name: 'barcode',
  label: null,
  prefix: 'Barcode:',
  placeholder: 'Valid EAN barcode',
  align: 'right',
  addonAfter: 'EAN',
}];

export const fieldDescription = ['FieldTextArea', {
  name: 'description',
  label: null,
  placeholder: 'Description',
  autoSize: { minRows: 2 },
}];

export const fieldPrice = ['FieldTextWithCurrencyAddonAfter', {
  name: 'price',
  label: null,
  prefix: 'Price:',
  align: 'right',
  type: 'number',
  placeholder: '0',
  // addonAfter: '$',
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber'],
  ],
}];

export const fieldPOSMaterialType = ['FieldSelect', {
  name: 'type',
  label: null,
  prefix: 'Type:',
  placeholder: 'Select POS Material Type',
  initialValue: Types.POS_MATERIAL_TYPE[1],
  choices: Types.POS_MATERIAL_TYPE.map(value => ({
    value,
    label: Types.POS_MATERIAL_TYPE_LABELS_MAP[value],
  })),
  rules: [['validation.isRequired']],
  virtual: [activityNotCreate],
  disabled: [activityNotCreate],
}];

export const CATEGORIES = [
  fieldName,
];

export const COMPANIES = [
  fieldName,
  fieldOrganization,
];

export const BRANDS = [
  fieldName,
  cloneField(fieldOrganization, {
    getFieldsToResetOnChange: () => ['company'],
  }),
  fieldCompany,
];

export const PRODUCTS_CREATE = [
  fieldImageProduct,
  fieldName,
  fieldRegion,
  fieldDescription,
  ...fieldsProductDimensions.slice(2).map(field => cloneField(
    field,
    { col: { xs: 24, md: 24, xl: 8 } },
  )),
  cloneField(fieldPrice, { col: { xs: 24, md: 24, xl: 8 } }),
  cloneField(fieldBarcode, { col: { xs: 24, md: 24, xl: 8 } }),
  fieldCategory,
  cloneField(fieldOrganization, {
    getFieldsToResetOnChange: () => ['brand'],
  }),
  fieldBrand,
];

export const PRODUCTS_EDIT = [
  fieldImageProduct,
  fieldName,
  fieldRegion,
  fieldDescription,
  ...fieldsProductDimensions.map(field => cloneField(
    [field[0], {
      ...field[1],
      prepareValueForInput: value => (
        isFinite(value)
        ? Types.getRoundedAmount(value, 3)
        : isString(value)
        ? value.replace(/(\.\d\d\d).+/, '$1')
        : value
      ),
    }],
    { col: { xs: 24, md: 24, xl: 12 } },
  )),
  cloneField(fieldPrice, { col: { xs: 24, md: 24, xl: 12 } }),
  cloneField(fieldBarcode, { col: { xs: 24, md: 24, xl: 12 } }),
  fieldCategory,
  cloneField(fieldOrganization, {
    getFieldsToResetOnChange: () => ['brand'],
  }),
  fieldBrand,
];

export const POS_MATERIALS = [
  ...fieldsImagePOSMaterial,
  fieldName,
  fieldRegion,
  cloneField(fieldPOSMaterialType, {
    getFieldsToResetOnChange: () => ['image.front', 'image.bsck'],
  }),
  fieldCategory,
  cloneField(fieldOrganization, {
    getFieldsToResetOnChange: () => ['brand'],
  }),
  fieldBrand,
];

export const PLANOGRAMS = [
  ...[
    fieldName,
    cloneField(fieldRegion, {
      getFieldsToResetOnChange: () => ['planogram'],
    }),
    cloneField(fieldOrganization, {
      getFieldsToResetOnChange: () => ['planogram'],
    }),
    [fieldCategories[0], {
      ...fieldCategories[1],
      disabled: [],
      virtual: [],
      skip: [
        ...(fieldCategories[1].skip || []),
        isActivityEditAndlibraryPlanogramIsPurposed,
      ],
    }],
  ].map(field => [field[0], {
    ...field[1],
    disabled: [
      ...(field[1].disabled || []),
      isActivityEditAndlibraryPlanogramIsPurposed,
    ],
  }]),
  ['FieldPlanogram', {
    name: 'planogram',
    label: null,
    target: 'library',
  }],
];
