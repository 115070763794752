import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { InboxOutlined } from '@ant-design/icons';

import EditorContext from '../Context';
import { TabLocal } from './Tab';

const ComponentIcon = styled(InboxOutlined)`
  font-size: 22px;
`;

export default function TabProject(props) {
  const { content: { product: productsMap = {} } = {} } = useContext(
    EditorContext,
  );
  const products = useMemo(
    () => Object.keys(productsMap).reduce(
      (agr, id) => {
        agr.push(productsMap[id]);
        return agr;
      },
      [],
    ),
    [productsMap],
  );
  return (
    <TabLocal
      products={products}
      emptyDescription={(
        <>
          No Test Products
          <br />
          Found
        </>
      )}
      {...props}
    />
  );
}

export const CONFIG = {
  id: 'project',
  label: 'Content',
  ComponentIcon,
  Component: TabProject,
};
