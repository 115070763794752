import React from 'react';
import styled from 'styled-components';

import Shelf from './Shelf';
import Arrow from './Arrow';

import Types from '../../../../modules/types';

import {
  SHELF_PADDING_X,
  SHELF_PADDING_Y,
  SHELF_HEADER_HEIGHT,
} from '../../config';

const WrapperHTML = ({ height, ...props }) => (
  <div {...props} />
);

const Wrapper = styled(WrapperHTML)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Spacer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  pointer-events: none;
`;

const DisplayHTML = ({ width, height, ...props }) => (
  <div {...props} />
);

const Display = styled(DisplayHTML)`
  display: flex;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const TrackHTML = ({ index, count, ...props }) => (
  <div {...props} />
);

const Track = styled(TrackHTML)`
  display: flex;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
  transform: ${({ index, count }) => `translateX(${index * -(100 / count)}%)`};
`;

export default function Shelfs({
  value,
  active,
  draggableAroundActive,
  onSetActive,
  onSetActiveIndex,
  onChange,
  onChangeShelf,
  onChangeActiveShelf,
  getProduct,
  getBrand,
  getCategory,
  priceTagColor,
  client,
  target,
  region,
  currency,
  organization,
  disabled,
}) {
  const {
    id: shelfIdActive,
    index: activeIndex,
    type,
    section,
  } = Types.PLANOGRAM.map[active];
  const { size: { widthPx, heightPx } } = type;
  const displayWidthPx = (
    widthPx + (2 * SHELF_PADDING_X)
  );
  const displayHeightPx = (
    heightPx + (2 * SHELF_PADDING_Y) + SHELF_HEADER_HEIGHT
  );
  return (
    <Wrapper height={displayHeightPx}>
      <Spacer />
      <Display width={displayWidthPx} height={displayHeightPx}>
        <Track index={activeIndex} count={section.count}>
          {
            section.list.map((shelfId, shelfSectionIndex) => {
              const activeIndexDiff = shelfSectionIndex - activeIndex;
              const inactive = (
                Math.abs(activeIndexDiff) > draggableAroundActive
              );
              return (
                <Shelf
                  key={shelfId}
                  id={shelfId}
                  active={shelfId === shelfIdActive}
                  inactive={inactive}
                  activeShelfId={shelfIdActive}
                  activeIndexDiff={activeIndexDiff}
                  draggableAroundActive={draggableAroundActive}
                  isFirst={shelfSectionIndex === 0}
                  isLast={shelfSectionIndex === section.list.length - 1}
                  value={value[shelfId]}
                  shelfs={value}
                  onSwitch={onSetActive}
                  onChange={onChange}
                  onChangeShelf={onChangeShelf}
                  onChangeActiveShelf={onChangeActiveShelf}
                  getProduct={getProduct}
                  getBrand={getBrand}
                  getCategory={getCategory}
                  priceTagColor={priceTagColor}
                  client={client}
                  target={target}
                  region={region}
                  currency={currency}
                  organization={organization}
                  disabled={disabled}
                />
              );
            })
          }
        </Track>
      </Display>
      <Spacer />
      <Arrow
        left
        onClick={() => onSetActiveIndex(-1, true)}
      />
      <Arrow
        right
        onClick={() => onSetActiveIndex(1, true)}
      />
    </Wrapper>
  );
}
