/* global document */

import React, { cloneElement } from 'react';
import styled from 'styled-components';

import Admin from 'hive-admin';
import Input from 'hive-admin/src/components/Input/Input';
import Field from 'hive-admin/src/components/Field';
import FieldText from 'hive-admin/src/components/FieldText';

import Alert from 'antd/lib/alert';

import {
  PaperClipOutlined,
  UserOutlined,
} from '@ant-design/icons';

import tests from '../../helpers/tests';

import copyToClipboard from '../../helpers/copyToClipboard';

import Types from '../../modules/types';

import Help from '../../components/Help';
import FieldSortableList from '../../components/FieldSortableList';

import { FIELDS_REPORT } from '../../components/PageSingleProject';

import {
  actionSubmit,
  actionPay,
  actionQuoteRequest,
  actionQuoteRequestApprove,
  actionQuoteRequestDeny,
  actionApprovalApprove,
  actionApprovalDeny,
  actionGoLive,
  actionProcessingAnalysisSave,
  actionProcessingSessionsAudit,
  actionProcessingCompileReport,
  actionProcessingPublishReport,
} from './actions';

const {
  viewerIsNotAdmin,
  activityNotCreate,
  viewerUnitIsCm,
  viewerUnitIsInch,
} = tests;

const narrowFormCols = {
  sm: { span: 24, offset: 0 },
  md: { span: 24, offset: 0 },
  lg: { span: 16, offset: 4 },
  xl: { span: 12, offset: 6 },
  xxl: { span: 8, offset: 8 },
};

const isAvailableForStatus = [
  'DRAFT',
  'READY',
  'QUOTING',
  'APPROVAL',
  'APPROVED',
  'LIVE',
  'PROCESSING',
  'REPORT',
].reduce(
  (agr, status) => {
    agr[status] = data => data && data.status === status;
    return agr;
  },
  {},
);

const paymentPlanIsQuoteAndNotQuoted = props => (
  props.form.getFieldValue('paymentPlan') === 'QUOTE'
  && !props.data.payment.quoted
);

const paymentPlanIsNotQuoteOrIsAlreadyQuoted = props => (
  props.form.getFieldValue('paymentPlan') !== 'QUOTE'
  || props.data.payment.quoted
);

const CopyToClipboardAddonAfterIcon = styled(PaperClipOutlined)`
  font-size: 16px;
  line-height: 100%;
  padding-top: 4px;
`;

class FieldTextDynamicPlaceholder extends FieldText {
  renderInput(props) {
    if (this.props.getPlaceholder) {
      props = {
        ...props,
        placeholder: this.props.getPlaceholder(this.props),
      };
    }
    return super.renderInput(props);
  }
}

Admin.addToLibrary(
  'FieldTextDynamicPlaceholder',
  config => FieldTextDynamicPlaceholder.create(config),
);

class FieldPerVariation extends FieldText {
  renderInput(props) {
    let variations = 0;
    if (this.props.data.variations) {
      variations = this.props.data.variations.length;
    }
    return super.renderInput({
      ...props,
      value: props.value < 0 ? 0 : props.value,
      addonAfter: (
        <span>
          {
            variations > 0
            ? (
                <>
                  <span style={{ fontSize: '15px' }}>
                    {`x ${variations}`}
                  </span>
                  &nbsp;
                </>
              )
            : null
          }
          {props.addonAfter}
        </span>
      ),
    });
  }
}

Admin.addToLibrary(
  'FieldPerVariation',
  config => FieldPerVariation.create(config),
);

export function cloneField(field, changes = {}) {
  return [field[0], { ...field[1], ...changes }];
}

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [['validation.isRequired']],
}];

export const fieldOrganization = ['FieldConnectionSelect', {
  name: 'organization',
  label: null,
  prefix: 'Organization:',
  placeholder: 'Select the projects organization',
  url: '/organizations',
  // getChoiceLabel: choice => choice.data.name,
  searchPaths: ['name'],
  rules: [['validation.isRequired']],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
  skip: [viewerIsNotAdmin],
}];

export const fieldRegion = ['FieldConnectionSelect', {
  name: 'region',
  label: null,
  prefix: 'Region:',
  placeholder: 'Target Region',
  url: '/regions',
  searchPaths: ['name', 'currency'],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
}];

export const fieldTeams = ['FieldConnectionSelect', {
  name: 'teams',
  label: null,
  prefix: 'Teams:',
  placeholder: 'Select teams that can read this project',
  url: '/teams',
  searchPaths: ['name'],
  mode: 'multiple',
  getExtraQueryConditions: (props) => {
    if (!props.viewer) {
      return [];
    }
    if (props.viewer.role === 'ADMIN') {
      const organization = props.form.getFieldValue('organization');
      return organization
      ? [{ organization }]
      : [{ name: '______________' }];
    }
    return [];
  },
}];

const ProductDimensionFieldsArray = [
  [
    'width',
    (
      // eslint-disable-next-line react/jsx-key
      <span>
        <Help
          content={(
            <>
              Product physical width, as it will be placed on the shelf.
              <br />
              If in doubt, search the product library for similar products
              <br />
              to copy dimensions.
            </>
          )}
        />
        &nbsp;
        Width:
      </span>
    ),
  ],
  [
    'height',
    (
      // eslint-disable-next-line react/jsx-key
      <span>
        <Help
          content={(
            <>
              Product physical height, as it will be placed on the shelf.
              <br />
              If in doubt, search the product library for similar products
              <br />
              to copy dimensions.
            </>
          )}
        />
        &nbsp;
        Height:
      </span>
    ),
  ],
].reduce(
  (agr, [base, prefix]) => {
    [
      [`${base}`, [viewerUnitIsInch]],
      [`${base}Inch`, [viewerUnitIsCm]],
    ].forEach(([name, skip]) => agr.push(['FieldLengthUnit', {
      name,
      label: null,
      prefix,
      align: 'right',
      type: 'number',
      skip,
      rules: [['validation.isRequired'], ['validation.isNumber']],
      col: { lg: 12, xs: 24 },
    }]));
    return agr;
  },
  [],
);

export const fieldContentDisplay = ['FieldRadio', {
  name: 'content.display',
  label: (
    <span>
      Test Secondary Display
      &nbsp;
      <Help
        content={(
          <>
            Place a free standing secondary display in the aisle or not.
            <br />
            Used if testing effectiveness of promotional communication.
          </>
        )}
      />
    </span>
  ),
  initialValue: false,
  choices: [{
    value: true,
    label: 'Yes',
  }, {
    value: false,
    label: 'No',
  }],
}];

export const fieldContentPriceTagColor = ['FieldRadio', {
  name: 'content.priceTagColor',
  label: 'Price Tags Color',
  initialValue: Types.PRICE_TAG_COLOR[0],
  choices: Types.PRICE_TAG_COLOR_LIST.map(({ id: value, label }) => ({
    label,
    value,
  })),
}];

export const fieldContentMission = ['FieldTextArea', {
  name: 'content.mission',
  placeholder: 'Give instructions to your testers',
  label: (
    <span>
      Shopper Instructions
      &nbsp;
      <Help
        content={(
          <>
            Instruct your testers what should they look for
            <br />
            in the store.
          </>
        )}
      />
    </span>
  ),
  autoSize: { minRows: 1, maxRows: 4 },
}];

export const fieldContentReview = ['FieldSelect', {
  name: 'content.review',
  placeholder: 'Select item for shopper to review',
  label: (
    <span>
      Shopper Review
      &nbsp;
      <Help
        content={(
          <>
            Tested item for which the shopper will be prompted
            <br />
            to leave a review.
          </>
        )}
      />
    </span>
  ),
  renderChoices: (field, props) => {
    let found = false;
    const choices = [];
    (props.form.getFieldValue('content.products') || [])
    .forEach((product) => {
      if (product && product._id) {
        choices.push({
          value: product._id,
          label: `Product: ${product.name || '/'}`,
        });
        if (!found && props.value === product._id) {
          found = true;
        }
      }
    });
    (props.form.getFieldValue('content.posMaterials') || [])
    .forEach((posMaterial) => {
      if (posMaterial && posMaterial._id) {
        choices.push({
          value: posMaterial._id,
          label: `POS Material: ${posMaterial.name || '/'}`,
        });
        if (!found && props.value === posMaterial._id) {
          found = true;
        }
      }
    });
    if (!found && (props.value || choices.length)) {
      setTimeout(
        () => field.props.onChange(choices[0] ? choices[0].value : undefined),
        200,
      );
    }
    return choices.map(choice => props.renderChoice(choice, field, props));
  },
}];

export const fieldContentProducts = ['FieldSortableList', {
  name: 'content.products',
  label: (
    <span>
      Test Products
      &nbsp;
      <Help
        content={(
          <>
            Add new products (SKUs) for testing, or products (SKUs)
            <br />
            for package redesign testing.
            <br />
            You can add more than one product.
          </>
        )}
      />
    </span>
  ),
  supportDuplicate: true,
  sortableListItemWrapperClassName: 'project-products',
  renderAddButton: (props, self) => cloneElement(
    FieldSortableList.config.renderAddButton(props, self),
    { children: 'Add New Product' },
  ),
  fields: [
    ['FieldText', {
      name: 'name',
      autoComplete: 'new-name',
      label: null,
      prefix: (
        <span>
          <Help
            content={(
              <>
                Name should be detailed, including weight or volume.
              </>
            )}
          />
          &nbsp;
          Name:
        </span>
      ),
      rules: [['validation.isRequired']],
    }],
    ['FieldConnectionSelect', {
      name: 'category',
      label: null,
      prefix: (
        <span>
          <Help
            content={(
              <>
                Select the most appropriate category.
                <br />
                If a suitable category is not on the list, please
                contact our support
                <br />
                to add the requested category assets.
              </>
            )}
          />
          &nbsp;
          Category:
        </span>
      ),
      url: '/library/categories',
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      col: { lg: 12, xs: 24 },
    }],
    ['FieldConnectionSelect', {
      name: 'brand',
      label: null,
      prefix: (
        <span>
          <Help
            content={(
              <>
                Select the product brand.
                <br />
                If tested product brand is not on the list,
                <br />
                you can add one in the Library/Brands section.
              </>
            )}
          />
          &nbsp;
          Brand:
        </span>
      ),
      url: '/library/brands',
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      col: { lg: 12, xs: 24 },
    }],
    ['FieldTextWithCurrencyAddonAfter', {
      name: 'price',
      label: null,
      prefix: 'Price:',
      align: 'right',
      type: 'number',
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber'],
      ],
      col: { lg: 12, xs: 24 },
    }],
    ['FieldText', {
      name: 'barcode',
      label: null,
      prefix: 'Barcode:',
      align: 'right',
      addonAfter: 'EAN',
      col: { lg: 12, xs: 24 },
    }],
    ...ProductDimensionFieldsArray,
  ],
}];

export const fieldContentPOSMaterials = ['FieldSortableList', {
  name: 'content.posMaterials',
  label: (
    <span>
      Test POS Materials
      &nbsp;
      <Help
        content={(
          <>
            Add test POS like Aisle Fin(s),
            <br />
            Top board(s) or Hangers. You can add more than one POS.
          </>
        )}
      />
    </span>
  ),
  supportDuplicate: true,
  sortableListItemWrapperClassName: 'project-pos-materials',
  renderAddButton: (props, self) => cloneElement(
    FieldSortableList.config.renderAddButton(props, self),
    { children: 'Add New POS Material' },
  ),
  fields: [
    ['FieldText', {
      name: 'name',
      autoComplete: 'new-name',
      label: null,
      prefix: 'Name:',
      rules: [['validation.isRequired']],
    }],
    ['FieldSelect', {
      name: 'type',
      label: null,
      prefix: 'Type:',
      rules: [['validation.isRequired']],
      choices: Types.POS_MATERIAL_TYPE.map(value => ({
        label: Types.POS_MATERIAL_TYPE_LABELS_MAP[value],
        value,
      })),
    }],
    ['FieldConnectionSelect', {
      name: 'category',
      label: null,
      prefix: (
        <span>
          <Help
            content={(
              <>
                Select the most appropriate category.
                <br />
                If a suitable category is not on the list, please
                contact our support
                <br />
                to add the requested category assets.
              </>
            )}
          />
          &nbsp;
          Category:
        </span>
      ),
      url: '/library/categories',
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      col: { lg: 12, xs: 24 },
    }],
    ['FieldConnectionSelect', {
      name: 'brand',
      label: null,
      prefix: (
        <span>
          <Help
            content={(
              <>
                Select the product brand.
                <br />
                If tested product brand is not on the list,
                <br />
                you can add one in the Library/Brands section.
              </>
            )}
          />
          &nbsp;
          Brand:
        </span>
      ),
      url: '/library/brands',
      searchPaths: ['name'],
      rules: [['validation.isRequired']],
      col: { lg: 12, xs: 24 },
    }],
  ],
}];

export const fieldContentTranslationsUsed = ['FieldSwitch', {
  name: 'content.translationsUsed',
  initialValue: false,
  label: null,
  inlineLabelBefore: (
    <span>
      Custom Translations
      &nbsp;
      <Help
        content={(
          <>
            If checked, use the table below to translate the shopper
            <br />
            user interface to the local language of your testers.
          </>
        )}
      />
    </span>
  ),
}];

export const fieldContentTranslations = ['FieldTranslations', {
  name: 'content.translationsArray',
  label: null,
  table: {
    showHeader: false,
    scroll: { x: '370px' },
    style: { marginTop: '-28px' },
  },
  hidden: [[
    'condition.testFieldValue',
    'content.translationsUsed',
    '!==',
    true,
  ]],
}];

export const fieldVariations = ['FieldSortableListVariations', {
  name: 'variations',
  label: null,
  sortableListItemWrapperClassName: 'project-variations',
  // eslint-disable-next-line arrow-body-style
  renderAddButton: (props, self) => {
    // if (!self.fields.length) {
    //   return null;
    // }
    return cloneElement(FieldSortableList.config.renderAddButton(props, self), {
      children: 'Add New Variation',
      style: (
          !props.value || !props.value.length
        ? { alignSelf: 'center' }
        : null
      ),
    });
  },
  getRuntimeFields: ({ data }) => {
    const fields = [];
    if (data && data.content) {
      if (data.content.products) {
        data.content.products.forEach((product) => {
          fields.push(['FieldUploadRefs', {
            name: `PRODUCT-${product._id}`,
            label: (
              <div>
                <Help
                  content={(
                    <>
                      Product image in png.
                      <br />
                      Transparent background; Front facing side;
                      Fully frontal perspective.
                    </>
                  )}
                />
                &nbsp;&nbsp;
                {product.name}
              </div>
            ),
            maxCount: 1,
            accept: 'image/png',
            formItemConfig: {
              className: `variation-resource-PRODUCT-${product._id}`,
            },
            transformations: [
              ['GM', { command: 'compress', maxWidth: 700 }],
            ],
          }]);
        });
      }
      if (data.content.posMaterials) {
        data.content.posMaterials.forEach((posMaterial) => {
          const { type: posMaterialType } = posMaterial;
          const posMaterialConfig = Types.POS_MATERIAL_TYPE_MAP[
            posMaterialType
          ];
          const fieldsConfig = [
            ['_FRONT', ''],
          ];
          if (posMaterialConfig && posMaterialConfig.back) {
            fieldsConfig.push(['_BACK', 'Backside of ']);
          }
          fields.push(...fieldsConfig.map(([nameSuffix, labelPrefix]) => [
            'FieldUploadRefs',
            {
              name: `POS_MATERIAL${nameSuffix}-${posMaterial._id}`,
              label: (
                <div>
                  <Help
                    content={(
                      <>
                        {`${labelPrefix}`}
                        POS image in png, transparent background.
                        <br />
                        Please use the POS template uploader.
                      </>
                    )}
                  />
                  &nbsp;&nbsp;
                  {`${labelPrefix}${posMaterial.name}`}
                </div>
              ),
              maxCount: 1,
              accept: 'image/png',
              formItemConfig: {
                className: `variation-resource-POS_MATERIAL${
                  nameSuffix
                }-${
                  posMaterial._id
                }`,
              },
              transformations: [
                ['GM', {
                  command: 'compress',
                  maxWidth: posMaterialConfig.widthPx,
                }],
              ],
            },
          ]));
        });
      }
      if (data.content.display) {
        Types.PRODUCT_DISPLAY_RESOURCE.forEach((targetInfo) => {
          const displayResource = Types.PRODUCT_DISPLAY_RESOURCE_MAP[
            targetInfo
          ];
          fields.push(['FieldUploadRefs', {
            name: `DISPLAY-${targetInfo}`,
            label: (
              <div>
                <Help
                  content={(
                    <>
                      {
                        displayResource.help.reduce(
                          (agr, line, index) => {
                            if (index > 0) {
                              // eslint-disable-next-line react/no-array-index-key
                              agr.push(<br key={`${index}-space`} />);
                            }
                            agr.push(line);
                            return agr;
                          },
                          [],
                        )
                      }
                    </>
                  )}
                />
                &nbsp;&nbsp;
                {`Secondary Display ${displayResource.label}`}
              </div>
            ),
            maxCount: 1,
            accept: 'image/png',
            formItemConfig: {
              className: `variation-resource-DISPLAY-${targetInfo}`,
            },
            transformations: [
              ['GM', {
                command: 'compress',
                maxWidth: (
                    displayResource.id === 'BACKGROUND'
                  ? 1200
                  : displayResource.size.widthPx
                ),
              }],
            ],
          }]);
        });
      }
    }
    return fields;
  },
}];

export const fieldPlanogram = ['FieldPlanogram', {
  name: 'planogram',
  label: null,
  target: 'project',
  centerButton: true,
  disabled: [props => !props.data || props.data.status !== 'DRAFT'],
}];

export const fieldPaymentPlan = ['FieldPaymentPlan', {
  name: 'paymentPlan',
  label: null,
  initialValue: Types.PROJECT_PAYMENT_PLAN_DEFAULT,
}];

export const fieldReadyTitle = (
  ({ Title: ComponentTitle }) => ['FieldReact', {
    name: 'fieldReadyTitle',
    label: null,
    renderContent: (props) => (
      <ComponentTitle>
        {
          paymentPlanIsQuoteAndNotQuoted(props)
          ? 'Custom Pricing Request'
          : 'Checkout'
        }
      </ComponentTitle>
    ),
  }]
)({
  Title: styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
  `,
});

export const fieldPaymentName = ['FieldTextDynamicPlaceholder', {
  name: 'paymentName',
  label: null,
  prefix: (
    <span>
      <UserOutlined
        style={{
          opacity: 0.5,
          paddingLeft: '3px',
          paddingRight: '9px',
        }}
      />
      Billing Name
    </span>
  ),
  align: 'right',
  getPlaceholder: (props) => {
    if (props.viewer) {
      return `eg. ${props.viewer.name}`;
    }
    return 'eg. your name';
  },
  rules: [
    ['validation.isRequired'],
    ['validation.isName'],
  ],
}];

export const fieldPaymentCard = ['FieldStripe', {
  name: 'paymentCard',
  label: null,
}];

export const fieldQuoteRequestMessage = ['FieldTextArea', {
  name: 'quoteRequestMessage',
  label: null,
  placeholder: 'Describe your request',
  autoSize: { minRows: 2 },
  rules: [['validation.isRequired']],
}];

export const fieldQuoteRequestMessagePreview = ['FieldReact', {
  name: 'quotedRequestMessagePreview',
  renderContent: ({ data }) => (
    <Alert
      message="Message:"
      description={(
        data.message && data.message.value
        ? data.message.value
        : 'No message provided'
      )}
      type="info"
      closable={false}
    />
  ),
}];

export const fieldQuoteRequestResponseType = ['FieldSelect', {
  name: 'quoteRequestResponseType',
  label: null,
  placeholder: 'Select response type',
  choices: [{
    label: 'Set Custom Price',
    value: 'price',
  }, {
    label: 'Requires No Payment (Invoiced Externally)',
    value: 'free',
  }],
  rules: [
    ['validation.isRequired'],
  ],
}];

export const fieldQuoteRequestResponseSamplePerVariation = [
  'FieldPerVariation', {
    name: 'quoteRequestResponseSamplePerVariation',
    label: null,
    prefix: 'Shoppers Per Variation:',
    placeholder: '0',
    align: 'right',
    type: 'number',
    addonAfter: <UserOutlined />,
    rules: [
      ['validation.isRequired'],
      ['validation.isNumber', {
        min: 1,
        message: 'Cannot be less thant 1',
      }],
    ],
  },
];

export const fieldQuoteRequestResponsePrice = ['FieldText', {
  name: 'quoteRequestResponsePrice',
  label: null,
  prefix: 'Total Price:',
  placeholder: '0',
  align: 'right',
  type: 'number',
  addonAfter: '$',
  disabled: [
    ({ form }) => (
      form && form.getFieldValue('quoteRequestResponseType') !== 'price'
    ),
  ],
  virtual: [
    ({ form }) => (
      form && form.getFieldValue('quoteRequestResponseType') !== 'price'
    ),
  ],
  skip: [
    ({ form }) => (
      form && form.getFieldValue('quoteRequestResponseType') !== 'price'
    ),
  ],
  rules: [
    ['validation.isRequired'],
    ['validation.isNumber', {
      min: 1,
      message: 'Cannot be less than $1',
    }],
  ],
}];

export const fieldTestingSamplePerVariation = ['FieldPerVariation', {
  type: 'number',
  name: 'testing.sample.variations',
  label: null,
  prefix: 'Shoppers Per Variation:',
  placeholder: '0',
  align: 'right',
  addonAfter: <UserOutlined />,
  getInitialValue: (fieldConfig, formProps) => {
    const value = Field.config.getInitialValue(fieldConfig, formProps);
    if (value === '0' || value < 0) {
      return undefined;
    }
    return value;
  },
}];

export const fieldLink = ['FieldText', {
  name: 'link',
  label: null,
  prefix: 'Link:',
  addonAfter: (
    <CopyToClipboardAddonAfterIcon
      onClick={() => copyToClipboard(
        document.querySelector('input[name="link"]').value,
      )}
    />
  ),
  virtual: true,
  Component: styled(Input)`
    input {
      pointer-events: none !important;
      opacity: 0.6;
    }
  `,
}];

export const fieldRedirect = ['FieldText', {
  name: 'testing.redirect',
  label: null,
  prefix: 'Redirect:',
  placeholder: 'Redirect shopper to this address',
  addonAfter: (
    <CopyToClipboardAddonAfterIcon
      onClick={() => copyToClipboard(
        document.querySelector('input[name="testing.redirect"').value,
      )}
    />
  ),
  virtual: true,
  Component: styled(Input)`
    input {
      pointer-events: none !important;
      opacity: 0.6;
    }
  `,
}];

export const fieldTestinngRedirect = ['FieldText', {
  name: 'testing.redirect',
  label: null,
  prefix: 'Redirect:',
  placeholder: 'Redirect shopper to this address',
  addonAfter: (
    <CopyToClipboardAddonAfterIcon
      onClick={() => copyToClipboard(
        document.querySelector('input[name="testing.redirect"').value,
      )}
    />
  ),
}];

export const fieldProjectAnalysis = ['FieldTextArea', {
  name: 'analysis',
  label: null,
  placeholder: 'Shopnosis Team Analysys',
  autoSize: { minRows: 3 },
}];

export const [
  fieldActionSubmit,
  fieldActionPay,
  fieldQuoteRequest,
  fieldQuoteRequestResponseActions,
  fieldApprovalActions,
  fieldApprovedActions,
  fieldProcessingAnalysisSaveAction,
] = (
  (fields, { WrapperComponent }) => fields.map(([name, actions, center]) => (
    ['FieldReact', {
      name,
      label: null,
      create: () => ({
        fieldActions: actions.map(action => Admin.compileFromLibrary(
          action,
          false,
        )),
        getSkip: props => Admin.test(
          props.skip || [],
          props,
          true,
          true,
        ),
      }),
      renderContent: (props) => {
        if (!props.getSkip || !props.getSkip(props)) {
          return (
            <WrapperComponent data-center={!!center}>
              {props.fieldActions.map(action => action.render(props))}
            </WrapperComponent>
          );
        }
        return null;
      },
    }]
  ))
)(
  [[
    'submitAction',
    [actionSubmit],
    true,
  ], [
    'actionPay',
    [actionPay],
    true,
  ], [
    'qupteRequestActionn',
    [actionQuoteRequest],
    true,
  ], [
    'quoteRequestResponseActions',
    [actionQuoteRequestApprove, actionQuoteRequestDeny],
    false,
  ], [
    'approvalActions',
    [actionApprovalApprove, actionApprovalDeny],
    true,
  ], [
    'approvedActions',
    [actionGoLive],
    true,
  ], [
    'processingAnalysisSaveAction',
    [actionProcessingAnalysisSave],
    true,
  ]],
  {
    WrapperComponent: styled.div`
      display: flex;
      &[data-center="true"] {
        justify-content: center;
      }
      .ant-btn:not(:first-child) {
        margin-left: 10px;
      }
    `,
  },
);

export const CREATE = [
  fieldName,
  fieldOrganization,
  fieldRegion,
];

export const CONTENT = [
  fieldContentMission,
  fieldContentReview,
  fieldContentProducts,
  fieldContentPOSMaterials,
  fieldContentDisplay,
  fieldContentPriceTagColor,
  fieldContentTranslationsUsed,
  fieldContentTranslations,
];

export const VARIATIONS = [
  fieldVariations,
];

export const PLANOGRAM = [
  fieldPlanogram,
];

export const FINALIZE = [
  // DRAFT
  ...[
    [fieldActionSubmit],
  ].map(([field, extras = {}]) => cloneField(
    field,
    { isAvailable: isAvailableForStatus.DRAFT, ...extras },
  )),
  // READY
  ...[
    [fieldPaymentPlan],
    // Is not quote or is already quoted
    [fieldReadyTitle, {
      col: narrowFormCols,
    }],
    [fieldPaymentName, {
      skip: [paymentPlanIsQuoteAndNotQuoted],
      col: narrowFormCols,
    }],
    [fieldPaymentCard, {
      skip: [paymentPlanIsQuoteAndNotQuoted],
      col: narrowFormCols,
    }],
    [fieldActionPay, {
      skip: [paymentPlanIsQuoteAndNotQuoted],
      col: narrowFormCols,
    }],
    // Is quote and is not quoted yet
    [fieldQuoteRequestMessage, {
      skip: [paymentPlanIsNotQuoteOrIsAlreadyQuoted],
      col: narrowFormCols,
    }],
    [fieldQuoteRequest, {
      skip: [paymentPlanIsNotQuoteOrIsAlreadyQuoted],
      col: narrowFormCols,
    }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    { isAvailable: isAvailableForStatus.READY, ...extras },
  )),
  // QUOTING
  ...[
    [fieldQuoteRequestMessagePreview, {
      col: narrowFormCols,
    }],
    [fieldQuoteRequestResponseType, {
      col: narrowFormCols,
    }],
    [fieldQuoteRequestResponseSamplePerVariation, {
      col: narrowFormCols,
    }],
    [fieldQuoteRequestResponsePrice, {
      col: narrowFormCols,
    }],
    [fieldQuoteRequestResponseActions, {
      col: narrowFormCols,
    }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    { isAvailable: isAvailableForStatus.QUOTING, ...extras },
  )),
  // APPROVAL
  ...[
    [fieldApprovalActions, {
      col: narrowFormCols,
    }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    { isAvailable: isAvailableForStatus.APPROVAL, ...extras },
  )),
  // APPROVED
  ...[
    [fieldTestingSamplePerVariation, {
      col: narrowFormCols,
    }],
    [fieldLink, {
      col: narrowFormCols,
    }],
    [fieldTestinngRedirect, {
      col: narrowFormCols,
    }],
    [fieldApprovedActions, {
      col: narrowFormCols,
    }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    { isAvailable: isAvailableForStatus.APPROVED, ...extras },
  )),
  // LIVE
  ...[
    [fieldLink, {
      col: narrowFormCols,
    }],
    [fieldRedirect, {
      col: narrowFormCols,
    }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    {
      isAvailable: data => data && data.status === 'LIVE',
      ...extras,
    },
  )),
  // PROCESSINNG
  ...[
    [fieldProjectAnalysis, { }],
    [actionProcessingAnalysisSave, { style: { width: '100%' } }],
    [actionProcessingSessionsAudit, { style: { width: '100%' } }],
    [actionProcessingCompileReport, { style: { width: '100%' } }],
    [actionProcessingPublishReport, { style: { width: '100%' } }],
  ].map(([field, extras = {}]) => cloneField(
    field,
    {
      isProcessingField: true,
      isAvailable: (data, viewer) => (
           data
        && viewer
        && data.status === 'PROCESSING'
        && viewer.role === 'ADMIN'
      ),
      ...extras,
    },
  )),
  // REPORT
  ...FIELDS_REPORT.map(([field, extras = {}]) => cloneField(
    field,
    {
      isAvailable: (data, viewer) => data && viewer && (
        data.status === 'REPORT'
        || (data.status === 'PROCESSING' && viewer && viewer.role === 'ADMIN')
      ),
      ...extras,
    },
  )),
];
