import React, { cloneElement } from 'react';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Admin from 'hive-admin';
// import ActionWithRequest from 'hive-admin/src/components/ActionWithRequest';
// eslint-disable-next-line max-len
import ActionWithFormBasedRequest from 'hive-admin/src/components/ActionWithFormBasedRequest';

export class PaymentError extends Error {

}

export function ActionStripeWrapper({ children, ...props }) {
  const stripe = useStripe();
  const stripeElements = useElements();

  return cloneElement(children, {
    ...props,
    stripe,
    stripeElements,
    CardElement,
  });
}

export default class ActionStripePay extends ActionWithFormBasedRequest {
  static create(config) {
    const result = super.create(config);
    return {
      ...result,
      render: props => (
        <ActionStripeWrapper key={props.id || props.name}>
          {result.render(props)}
        </ActionStripeWrapper>
      ),
    };
  }
}

Admin.addToLibrary(
  'ActionStripePay',
  config => ActionStripePay.create({
    disabled: [],
    getRequestConfig: (props) => ({
      method: 'POST',
      url: `/projects/${props.data._id}/actions/pay`,
      data: { plan: props.form.getFieldValue('paymentPlan') },
    }),
    processAction: async (props) => {
      const { errors, values: valuesRaw } = await props.validateForm(
        props,
        props.getValidationOptions(props)
      );
      if (errors) {
        throw new Error('Check the fields and try again!');
      }
      const { billingName } = await props.serializeValues(valuesRaw, props);
      const { client, stripe, stripeElements } = props;
      const { data: { paymentIdClient } } = await props.issueRequest(
        props,
        props.getRequestConfig(props),
      );
      try {
        const { error } = await stripe.confirmCardPayment(paymentIdClient, {
          payment_method: {
            card: stripeElements.getElement(CardElement),
            billing_details: {
              name: billingName,
            },
          },
        });
        if (error) {
          throw new PaymentError(error.message || 'Invalid card details');
        }
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { data } = await client.get(`/projects/${props.data._id}`);
          if (data.status === 'APPROVAL') {
            break;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ActionStripePay error:', error);
        if (error instanceof PaymentError) {
          throw error;
        }
        throw new Error('Could not process payment');
      }
    },
    handleSuccess: (data, props) => {
      props.reload();
    },
    ...config,
  }),
);
