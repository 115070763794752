/* global window */

import React, { createRef } from 'react';
import styled from 'styled-components';

import AntdButton from 'antd/lib/button';
import AntdModal from 'antd/lib/modal';

import Admin from 'hive-admin';
import Field from 'hive-admin/src/components/Field';

import Editor, { LoaderLibrary, LoaderProject } from './Editor';

import useRequest from '../../helpers/useRequest';

import './index.less';

function EditorWithCurrency(props) {
  const [response] = useRequest(
    props.client,
    `/regions/${props.region || null}`,
  );
  const currency = (
    response.data && response.data.currency
    ? response.data.currency
    : null
  );
  return <Editor {...props} currency={currency} />;
}

const Modal = styled(AntdModal)`
  top: 0px;
  left: 0px;
  width: calc(100vw - 40px);
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 20px;
    height: calc(100vh - 40px);
    .ant-modal-body {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 0px;
      overflow-x: hidden;
      overflow-y: hidden;
      flex-shrink: 1;
    }
    .ant-modal-footer {
      display: flex;
      flex-grow: 0;
    }
  }
`;

const Button = styled(AntdButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonModifiedSeparator = styled.span`
  margin: 0px 8px;
`;

const ButtonModifiedLabel = styled.span`
  color: ${({ theme }) => theme.less.primaryColor};
`;

export default class FieldPlanogram extends Field {
  static config = {
    ...Field.config,
    centerButton: false,
  }

  static inputPropsMap = {
    name: true,
    value: true,
    disabled: true,
    onChange: true,
    defaultValue: true,
  }

  static defaultProps ={
    target: 'library',
    draggableAroundActive: 1,
  }

  constructor(props) {
    super(props);
    this.state = this.state || {};
    Object.assign(this.state, { open: false });
    this.editor = createRef();
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = (callback) => {
    this.setState({ open: false }, () => {
      if (window.document.activeElement) {
        window.document.activeElement.blur();
      }
      callback && callback();
    });
  }

  renderModalContent(props) {
    if (!this.state.open) {
      return null;
    }
    switch (this.props.target) {
      case 'library':
        return (
          <LoaderLibrary
            client={this.props.client}
            target={this.props.target}
            value={this.props.value}
            onClose={(value) => {
              if (value && value.shelfs) {
                this.handleClose(() => props.onChange(value.shelfs));
              } else {
                this.handleClose();
              }
            }}
          >
            <EditorWithCurrency
              key="editor"
              ref={this.editor}
              client={this.props.client}
              target={this.props.target}
              viewer={this.props.viewer}
              region={
                this.props.form.getFieldValue('region') || null
              }
              organization={
                this.props.form.getFieldValue('organization') || null
              }
              purpose={
                  this.props.data && this.props.data.purpose
                ? this.props.data.purpose
                : null
              }
              draggableAroundActive={this.props.draggableAroundActive}
              disabled={this.props.disabled}
            />
          </LoaderLibrary>
        );
      case 'project': {
        const {
          content = {},
          variations = [],
        } = this.props.data || {};
        return (
          <LoaderProject
            client={this.props.client}
            target={this.props.target}
            value={this.props.value}
            content={content}
            variations={variations}
            onClose={(value) => {
              if (value && value.shelfs) {
                this.handleClose(() => props.onChange(value.shelfs));
              } else {
                this.handleClose();
              }
            }}
          >
            <EditorWithCurrency
              key="editor"
              ref={this.editor}
              client={this.props.client}
              target={this.props.target}
              viewer={this.props.viewer}
              region={
                this.props.data.region || null
              }
              organization={
                this.props.data.organization || null
              }
              draggableAroundActive={this.props.draggableAroundActive}
              disabled={this.props.disabled}
            />
          </LoaderProject>
        );
      }
      default:
        return null;
    }
  }

  renderModal(props) {
    return (
      <Modal
        header={null}
        footer={null}
        visible={this.state.open}
        onCancel={() => {
          if (!this.editor.current || !this.editor.current.didChange()) {
            this.handleClose();
          }
        }}
        width={null}
        wrapClassName="planogram-editor-modal-wrap"
      >
        {this.renderModalContent(props)}
      </Modal>
    );
  }

  renderInput(props) {
    const isTouched = this.props.form.isFieldTouched(this.props.name);
    return (
      <>
        <Button
          icon="pic-center"
          onClick={this.handleOpen}
          className="planogram-open-button"
          style={(
            this.props.centerButton
            ? { marginLeft: 'auto', marginRight: 'auto' }
            : null
          )}
        >
          Open Planogram Editor
          {
            isTouched
            ? (
                <>
                  <ButtonModifiedSeparator>/</ButtonModifiedSeparator>
                  <ButtonModifiedLabel>Modified</ButtonModifiedLabel>
                </>
              )
            : null
          }
        </Button>
        {this.renderModal(props)}
      </>
    );
  }
}

Admin.addToLibrary(
  'FieldPlanogram',
  config => FieldPlanogram.create(config),
);
