import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'hive-admin/node_modules/react-router-dom';

import AntdPopover from 'antd/lib/popover';
import AntdRadio from 'antd/lib/radio';

import {
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  // ProjectOutlined,
  // HomeOutlined,
} from '@ant-design/icons';

import { useDelayedValue } from '../../../utils';

import Types from '../../../../../modules/types';

const Popover = styled(AntdPopover)`

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  padding: 10px;
`;

const Actions = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-top: -5px;
`;

const ActionsDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.less.borderColor};
  margin: 10px 0px 15px 0px;
`;

const ActionHTML = ({ activeColor, ...props }) => (
  <div {...props} />
);

const Action = styled(ActionHTML)`
  display: flex;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: ${({ theme, activeColor }) => (
      theme.less[activeColor] || activeColor
    )};
  }
  ${({ disabled }) => disabled && css`
    cursor: default;
  `}
`;

const ActionDivider = styled.div`
  width: 1px;
  height: 18px;
  margin: 0px 8px;
  background-color: ${({ theme }) => theme.less.borderColor};
`;

Action.defaultProps = {
  activeColor: 'primaryColor',
};

const RadioGroup = styled(AntdRadio.Group)`
  width: 100%;
  display: flex;
  .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px;
`;

const InfoItem = styled.div`
  display: flex;
  line-height: 130%;
  &:not(:first-child) {
    margin-top: 7px;
  }
`;

const InfoItemLabel = styled.div`
  display: flex;
  opacity: 0.4;
  padding-right: 5px;
  user-select: none;
  width: 80px;
  flex-shrink: 0;
`;

const InfoItemValue = styled.div`
  display: inline;
  > span {
    margin-right: 5px;
    /* color: ${({ theme }) => theme.less.primaryColor}; */
    opacity: 0.4;
  }
`;

const ACTIONS = [{
  id: 'SPAN_DEC',
  Icon: MinusOutlined,
}, {
  id: 'SPAN_INC',
  Icon: PlusOutlined,
}, {
  divider: true,
}, {
  id: 'SPAN_VERTICAL_DEC',
  Icon: DownOutlined,
}, {
  id: 'SPAN_VERTICAL_INC',
  Icon: UpOutlined,
}, {
  divider: true,
}, {
  id: 'REMOVE',
  Icon: DeleteOutlined,
  activeColor: 'errorColor',
}];

const INFO = [{
  id: 'name',
  label: 'Name:',
  get: ({ showInfoName, product }) => {
    if (!showInfoName) {
      return null;
    }
    return product.name;
  },
}, {
  id: 'library',
  label: 'Library:',
  get: ({ showInfoLibrary, product }) => {
    if (!showInfoLibrary) {
      return null;
    }
    return (
        product.project
      ? 'Project'
      : product.organization
      ? 'Organization'
      : null
    );
  },
}, {
  id: 'category',
  label: 'Category:',
  get: ({ showInfoCategory, product, getCategory }) => {
    if (!showInfoCategory) {
      return null;
    }
    const category = getCategory(product.categoryEmbed || product.category);
    if (category) {
      return category.name;
    }
    return null;
  },
}, {
  id: 'brand',
  label: 'Brand:',
  get: ({ showInfoBrand, product, getBrand }) => {
    if (!showInfoBrand) {
      return null;
    }
    const brand = getBrand(product.brandEmbed || product.brand);
    if (brand) {
      return brand.name;
    }
    return null;
  },
}, {
  id: 'price',
  label: 'Price:',
  get: ({ showInfoPrice, product, currency }) => {
    if (!showInfoPrice) {
      return null;
    }
    return `${currency || ''}${(product.price || 0).toFixed(2)}`;
  },
}, {
  id: 'edit',
  label: 'Edit',
  get: ({ showInfoEdit, product }) => {
    if (!showInfoEdit) {
      return null;
    }
    return (
      <Link to={`/library/products/${product._id}`} target="_blank">
        Open Product
      </Link>
    );
  },
}];

function ContentActions({ id, product, segmentId, onAction, disabled }) {
  if (!onAction || disabled) {
    return null;
  }
  return (
    <>
      <Actions>
        {
          ACTIONS.map(({ id: action, divider, Icon, ...config }, i) => (
            divider
            ? (
                <ActionDivider
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                />
              )
            : (
                <Action
                  key={action}
                  onClick={() => onAction(action, id, segmentId, product)}
                  {...config}
                >
                  <Icon />
                </Action>
              )
          ))
        }
      </Actions>
      <ActionsDivider />
    </>
  );
}

function ContentPriceTagColor({
  id,
  segmentId,
  product,
  priceTagColor,
  onAction,
}) {
  return (
    <>
      <Actions style={{ width: '100%' }}>
        <RadioGroup
          value={priceTagColor}
          onChange={event => onAction(
            'PRICE_TAG_COLOR_CHANGE',
            id,
            segmentId,
            product,
            event.target.value,
          )}
          size="small"
        >
          <AntdRadio.Button
            key="default"
            value={null}
          >
            Default
          </AntdRadio.Button>
          {
            Types.PRICE_TAG_COLOR_LIST.map(({ id: value, label }) => (
              <AntdRadio.Button
                key={value}
                value={value}
              >
                {label}
              </AntdRadio.Button>
            ))
          }
        </RadioGroup>
      </Actions>
      <ActionsDivider />
    </>
  );
}

function ContentInfo(props) {
  return (
    <Info>
      {
        INFO.map(({ id, label, get }) => {
          const value = get(props);
          if (value !== null) {
            return (
              <InfoItem key={id}>
                <InfoItemLabel>{label}</InfoItemLabel>
                <InfoItemValue>{value}</InfoItemValue>
              </InfoItem>
            );
          }
          return null;
        })
      }
    </Info>
  );
}

ContentInfo.defaultProps = {
  showInfoName: true,
  showInfoLibrary: true,
  showInfoCategory: true,
  showInfoBrand: true,
  showInfoPrice: true,
  showInfoEdit: true,
};

function Content({ showPriceTagColor, ...props }) {
  return (
    <Wrapper>
      <ContentActions {...props} />
      {
          showPriceTagColor
        ? <ContentPriceTagColor {...props} />
        : null
      }
      <ContentInfo {...props} />
    </Wrapper>
  );
}

Content.defaultProps = {
  showPriceTagColor: false,
};

export default function GroupSettings({
  children,
  id,
  product,
  currency,
  segmentId,
  priceTagColor,
  onAction,
  dragging,
  disabled,
  organization,
  getBrand,
  getCategory,
  placement,
  offset,
  showPriceTagColor,
  showInfoName,
  showInfoCategory,
  showInfoBrand,
  showInfoPrice,
  showInfoEdit,
  isActive,
}) {
  const [isVisible, setIsVisible] = useState(isActive || false);
  const isDragging = useDelayedValue(
    dragging,
    (oldValue, newValue) => (!newValue ? 200 : 0),
  );
  useEffect(
    () => {
      if (isDragging && isVisible) {
        setIsVisible(false);
      }
    },
    [isDragging, isVisible, setIsVisible],
  );
  return (
    <Popover
      title={null}
      overlayClassName="planogram-editor-group-settings"
      content={(
        <Content
          id={id}
          product={product}
          currency={currency}
          segmentId={segmentId}
          priceTagColor={priceTagColor}
          onAction={onAction}
          disabled={disabled}
          organization={organization}
          getBrand={getBrand}
          getCategory={getCategory}
          showPriceTagColor={showPriceTagColor}
          showInfoName={showInfoName}
          showInfoCategory={showInfoCategory}
          showInfoBrand={showInfoBrand}
          showInfoPrice={showInfoPrice}
          showInfoEdit={showInfoEdit}
        />
      )}
      trigger="hover"
      destroyTooltipOnHide
      mouseEnterDelay={0.4}
      mouseLeaveDelay={0.1}
      placement={placement}
      align={{ offset }}
      visible={isVisible}
      onVisibleChange={newIsVisible => (
        !isDragging && setIsVisible(newIsVisible)
      )}
    >
      {children}
    </Popover>
  );
}

GroupSettings.defaultProps = {
  placement: 'bottom',
};
