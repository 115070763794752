import React from 'react';
import styled, { css } from 'styled-components';

const IconHTML = ({ disabled, left, right, ...props }) => (
  <div {...props} />
);

const Icon = styled(IconHTML)`
  display: flex;
  position: absolute;
  padding: 15px;
  top: 50%;
  ${({ left, right }) => (
      left
    ? css`left: 15px;`
    : right
    ? css`right: 15px;`
    : null
  )}
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  background-color: #fff;
  transform: translate(0px, -50%);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  > div {
    transition: opacity 300ms;
    opacity: 0.4;
    > div {
      transition: background-color 300ms;
      background-color: ${({ theme }) => theme.less.textColor};
    }
  }
  &:hover {
    > div {
      opacity: 1;
      > div {
        background-color: ${({ theme }) => theme.less.primaryColor};
      }
    }
  }
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;

const IconWrapperHTML = ({ left, right, ...props }) => (
  <div {...props} />
);

const IconWrapper = styled(IconWrapperHTML)`
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  transform-origin: 50% 50%;
  transform: ${({ left, right }) => (
    `translateX(${
        left
      ? '25%'
      : right
      ? '-7%'
      : '0%'
    }) rotate(${
        left
      ? '-45deg'
      : right
      ? '135deg'
      : '0deg'
    })`
  )};
`;

const IconBarTop = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 3px;
  border-radius: 1.5px;
`;

const IconBarLeft = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 3px;
  border-radius: 1.5px;
`;

export default function Arrow({
  onClick,
  disabled,
  left,
  right,
}) {
  return (
    <Icon
      onClick={onClick}
      disabled={!!disabled}
      left={left}
      right={right}
    >
      <IconWrapper left={left} right={right}>
        <IconBarTop />
        <IconBarLeft />
      </IconWrapper>
    </Icon>
  );
}
