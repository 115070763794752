import omit from 'lodash/omit';

import React, { Component, createRef } from 'react';
import styled from 'styled-components';

import memoizeOne from 'memoize-one';
import { stringify as stringifyQuery } from 'querystring';

import Query from 'hive-admin/src/components/Query';

import { withPixelValues } from './common';

const {
  REACT_APP_SHOPPER_CONTENT_STORAGE_PATH: shopperContentStoragePath,
} = process.env;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > .ant-form {
    width: 100%;
  }
`;

export default class Report extends Component {
  constructor(props) {
    super(props);
    this.statsQuery = createRef();
  }

  // getFilterIdMaps = memoizeOne(
  //   (project) => ({
  //     age: project.config.age.reduce(
  //       (agr, { _id }, i) => { agr[_id] = i; return agr; },
  //       {},
  //     ),
  //     gender: project.config.gender.reduce(
  //       (agr, { _id }, i) => { agr[_id] = i; return agr; },
  //       {},
  //     ),
  //   }),
  // )

  // getFilterId = (
  //   age = null,
  //   gender = null,
  //   variation = null,
  //   filterIdMaps,
  // ) => {
  //   if (!filterIdMaps) {
  //     filterIdMaps = this.getFilterIdMaps(this.props.data);
  //   }
  //   return `a${
  //     age === null ? '_' : filterIdMaps.age[age]
  //   }g${
  //     gender === null ? '_' : filterIdMaps.gender[gender]
  //   }v${
  //     variation === null ? '_' : variation
  //   }`;
  // }

  getFiltersChoices = memoizeOne((content) => {
    const result = {
      age: [
        { key: 'any', value: null, label: 'Any' },
        ...content.project.config.age.map(({ _id, label }) => ({
          value: _id,
          label,
        })),
      ],
      gender: [
        { key: 'any', value: null, label: 'Any' },
        ...content.project.config.gender.map(({ _id, label }) => ({
          value: _id,
          label,
        })),
      ],
      productGroups: [],
    };
    const groups = {};
    result.product = Object.values(content.data.resources.product).reduce(
      (agr, product, i, array) => {
        const groupId = `${product.category}_${product.brand}${
          product.project
          ? '_project'
          : ''
        }`;
        let group = groups[groupId];
        if (!group) {
          const category = content.data.resources.category[product.category];
          const brand = content.data.resources.brand[product.brand];
          group = {
            id: groupId,
            label: `${category.name} / ${brand.name}`,
            major: product.project === true,
            category,
            brand,
          };
        }
        product.brandData = group.brand;
        product.categoryData = group.category;
        agr.push({
          value: product._id,
          label: product.name,
          data: product,
          dimmed: !product.sample || product.sample.cart < 1,
          group,
          searchable: `${
            product._id
          } | ${
            product.name
          } | ${
            product.brandData.name
          } | ${
            product.categoryData.name
          }`.toLowerCase(),
        });
        // last call
        if (i === array.length - 1) {
          agr.sort((a, b) => (
              a.data.project === b.data.project
            ? (
                a.data.category === b.data.category
                ? (
                    a.data.brand === b.data.brand
                    ? (
                          a.data.name > b.data.name ? 1
                        : a.data.name < b.data.name ? -1
                        : 0
                      )
                    : (
                          a.group.brand.name > b.group.brand.name ? 1
                        : a.group.brand.name < b.group.brand.name ? -1
                        : 0
                      )
                  )
                : (
                      a.group.category.name > b.group.category.name ? 1
                    : a.group.category.name < b.group.category.name ? -1
                    : 0
                  )
              )
            : (
                  a.data.project ? -1
                : b.data.project ? 1
                : 0
              )
          ));
        }
        return agr;
      },
      [],
    );
    return result;
  })

  getFilters = memoizeOne((params, content) => {
    let { product, age, gender } = params;
    const choices = this.getFiltersChoices(content);
    if (!age || !content.ageValues.includes(age)) {
      age = null;
    }
    if (!gender || !content.genderValues.includes(gender)) {
      gender = null;
    }
    if (!product || !content.data.resources.product[product]) {
      // eslint-disable-next-line prefer-destructuring
      product = choices.product[0].value;
    }
    return {
      values: {
        age,
        gender,
        product,
      },
      choices,
    };
  })

  setFilterValue = (filter, value) => {
    value = (value === null ? undefined : `${value}`);
    if (this.props.searchParams[filter] !== value) {
      this.props.history.push(`${this.props.pathname}?${stringifyQuery(
          value === undefined
        ? omit(this.props.searchParams, filter)
        : { ...this.props.searchParams, [filter]: value }
      )}`);
    }
  }

  extractProductsQueryData = (response, oldData, { content }) => {
    if (!this.props.data || !response || !response.data || !content) {
      return null;
    }
    const { data: { resources: { product: products } } } = content;
    response.data.forEach((product) => {
      if (products[product.content]) {
        products[product.content].sample = product.sample;
      }
    });
    return content;
  }

  renderProductsQuery({ data: content, error }) {
    if (!content) {
      return null;
    }
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    }
    const { client, data: project } = this.props;
    return (
      <Query
        client={client}
        content={content}
        extractData={this.extractProductsQueryData}
        url={`/projects/${project._id}/stats/compact?${stringifyQuery({
          query: JSON.stringify({
            compact: [[{
              type: 'product',
            }, {
              age: [null],
              gender: [null],
              variation: [null],
            }, {
              select: { sample: 1 },
            }]],
          }),
        })}`}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }

  extractShopperContentQueryData = (response) => {
    if (!this.props.data || !response || !response.data) {
      return null;
    }
    const { data } = response;
    const { data: project } = this.props;
    const content = { data, project };
    content.ageValues = project.config.age.map(({ _id }) => _id);
    content.genderValues = project.config.gender.map(({ _id }) => _id);
    content.variationValues = [project.testing.variations.control];
    for (let i = 0; i < project.testing.variations.count; i++) {
      if (!content.variationValues.includes(i)) {
        content.variationValues.push(i);
      }
    }
    withPixelValues(content.data.config);
    withPixelValues(content.data.planogram);
    Object.values(content.data.resources.product).forEach((product) => {
      if (!product.locations || !product.locations.length) {
        delete content.data.resources.product[product._id];
      } else {
        withPixelValues(product);
      }
    });
    content.review = null;
    content.reviewType = null;
    if (project.content && project.content.review) {
      const {
        resources: { product: products, posMaterial: posMaterials },
      } = data;
      content.review = products[project.content.review];
      content.reviewType = 'product';
      if (!content.review) {
        content.review = posMaterials[project.content.review];
        content.reviewType = 'posMaterial';
        if (!content.review) {
          content.review = null;
          content.reviewType = null;
        }
      }
    }
    return content;
  }

  renderShopperContentQuery() {
    const { client, data: project } = this.props;
    return (
      <Query
        client={client}
        extractData={this.extractShopperContentQueryData}
        url={`/storage${shopperContentStoragePath}/project/${project._id}?ts=${
          new Date(project.updatedAt).getTime()
        }`}
      >
        {queryProps => this.renderProductsQuery(queryProps)}
      </Query>
    );
  }

  renderContent = ({ data: content, error }) => {
    if (!content) {
      return null;
    }
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    }
    const filters = this.getFilters(
      this.props.searchParams,
      content,
    );
    return (
      <Wrapper>
        {this.props.renderForm({
          content,
          filters,
          setFilterValue: this.setFilterValue,
        })}
      </Wrapper>
    );
  }

  render() {
    return this.renderShopperContentQuery();
  }
}
