import isArray from 'lodash/isArray';

// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'query-string';

import React, { useState } from 'react';
import styled from 'styled-components';

import { FolderOpenOutlined } from '@ant-design/icons';

import Select from 'antd/lib/select';
import Empty from 'antd/lib/empty';

import Query from 'hive-admin/src/components/Query';
import InfiniteScrollQuery from '../../../InfiniteScrollQuery';

import Tab from './Tab';

// import Types from '../../../../modules/types';

const ComponentIcon = styled(FolderOpenOutlined)`
  font-size: 20px;
`;

const LibrarySelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
`;

function LibraryInput({
  value,
  onChange,
  className,
}) {
  return (
    <LibrarySelect
      value={value}
      onChange={onChange}
      filterOption={false}
      allowClear
      placeholder="Library"
      className={className}
    >
      <LibrarySelect value="global">
        Global
      </LibrarySelect>
      <LibrarySelect value="organization">
        Organization
      </LibrarySelect>
    </LibrarySelect>
  );
}

// const CountriesSelect = styled(Select)`
//   width: 100%;
//   margin-top: 10px;
// `;

// function CountriesInput({
//   value,
//   onChange,
//   className,
// }) {
//   return (
//     <CountriesSelect
//       value={value}
//       onChange={onChange}
//       allowClear
//       placeholder="Countries"
//       optionFilterProp="children"
//       className={className}
//       mode="multiple"
//     >
//       {Types.COUNTRIES.map(({ name: label, iso3a2 }) => (
//         <CountriesSelect.Option
//           key={iso3a2}
//           value={iso3a2}
//         >
//           {label}
//         </CountriesSelect.Option>
//       ))}
//     </CountriesSelect>
//   );
// }

const ConnectionSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
`;

function ConnectionSelectInput({
  value,
  onChange,
  client,
  extractData,
  className,
  url,
  placeholder,
}) {
  const [search, setSearch] = useState();
  return (
    <Query
      client={client}
      extractData={extractData}
      url={`${url}?${qs.stringify({ query: JSON.stringify({
        limit: 20,
        skip: 0,
        where: {
          name: search && search.length
          ? { REGEX: search, OPTIONS: 'i' }
          : undefined,
        },
        sort: { name: 1 },
      }) })}`}
    >
      {({ data }) => (
        <ConnectionSelect
          value={value}
          onChange={onChange}
          showSearch
          onSearch={setSearch}
          filterOption={false}
          placeholder={placeholder}
          allowClear
          className={className}
        >
          {data.map(item => (
            <ConnectionSelect.Option key={item._id} value={item._id}>
              {item.name}
            </ConnectionSelect.Option>
          ))}
        </ConnectionSelect>
      )}
    </Query>
  );
}

ConnectionSelectInput.defaultProps = {
  extractData: response => (
    (
      response
      && response.data
      && response.data.data
      && response.data.data.length
    )
    ? response.data.data
    : []
  ),
};

export default class TabLibrary extends Tab {
  renderHeaderContent() {
    return (
      <>
        {super.renderHeaderContent()}
        {
          this.props.organization
          ? (
              <LibraryInput
                value={this.props.config.library}
                onChange={library => this.props.onConfigChange({ library })}
                className="planogram-editor-sidebar-body-header-library"
              />
            )
          : null
        }
        <ConnectionSelectInput
          client={this.props.client}
          value={this.props.config.category}
          onChange={category => this.props.onConfigChange({ category })}
          url="library/categories"
          placeholder="Category"
        />
        <ConnectionSelectInput
          client={this.props.client}
          value={this.props.config.brand}
          onChange={brand => this.props.onConfigChange({ brand })}
          url="library/brands"
          placeholder="Brand"
        />
        {/* <CountriesInput
          value={this.props.config.countries}
          onChange={countries => this.props.onConfigChange({ countries })}
          placeholder="Countries"
        /> */}
      </>
    );
  }

  getQeuryResponseData = response => (
    response && isArray(response.data)
    ? response.data
    : []
  )

  getQueryShouldReload = (oldProps, newProps) => (
    oldProps.search !== newProps.search
    || oldProps.category !== newProps.category
    || oldProps.brand !== newProps.brand
  )

  getQueryUrl = (props, page) => (
    `${props.url}?query=${encodeURIComponent(JSON.stringify({
      skip: page * props.limit,
      where: props.getWhereConditions(props, page),
      limit: props.limit,
      sort: { name: 1 },
      populate: {
        category: true,
        company: true,
        brand: true,
      },
    }))}`
  )

  getQueryWhereConditions = ({
    search,
    library,
    category,
    brand,
    region,
    organization,
    // countries,
  }) => {
    const conditions = [{
      organization: {
        IN: (
            !organization
          ? [null]
          : library === 'global'
          ? [null]
          : library === 'organization'
          ? [organization]
          : [null, organization]
        ),
      },
      category: category || undefined,
      brand: brand || undefined,
    }];
    // if (countries && countries.length) {
    //   conditions.push({
    //     OR: [
    //       { countries: { IN: countries } },
    //       { countries: { EQ: [] } },
    //       { countries: { EQ: null } },
    //     ],
    //   });
    // }
    if (region) {
      conditions.push({ region });
    }
    if (search && search.length) {
      conditions.push({
        OR: ['name', 'categoryName', 'companyName', 'brandName'].map(
          searchPath => ({ [searchPath]: { REGEX: search, OPTIONS: 'i' } })
        ),
      });
    }
    return { AND: conditions };
  }

  renderQueryProduct = (product, index) => this.renderProduct(product, index)

  renderQueryEmpty = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={(
        <>
          No Library Products
          <br />
          Found
        </>
      )}
    />
  )

  renderProducts() {
    return (
      <InfiniteScrollQuery
        client={this.props.client}
        url="library/products"
        limit={7}
        getUrl={this.getQueryUrl}
        search={this.props.config.search}
        category={this.props.config.category || null}
        brand={this.props.config.brand || null}
        library={this.props.config.library || null}
        region={this.props.region || null}
        currency={this.props.currency || null}
        organization={this.props.organization}
        // countries={this.props.config.countries || null}
        shouldReload={this.getQueryShouldReload}
        getWhereConditions={this.getQueryWhereConditions}
        renderItem={this.renderQueryProduct}
        renderEmpty={this.renderQueryEmpty}
      />
    );
  }
}

export const CONFIG = {
  id: 'library',
  label: 'Library',
  ComponentIcon,
  Component: TabLibrary,
};
