import React, { cloneElement } from 'react';
import styled from 'styled-components';

import { DownOutlined } from '@ant-design/icons';

import InputRadio from 'hive-admin/src/components/Input/Radio';

import Popover, {
  Content as PopoverContent,
} from '../../../Popover';

import Item from './Item';

const LABELS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const VariationLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(2px);
  line-height: 18px;
`;

const Icon = styled(DownOutlined)`
  font-size: 14px;
  margin-left: 7px;
`;

function Content({ value, onChange, variations }) {
  return (
    <PopoverContent title="Preview Variation" minWidth={200}>
      <InputRadio.Group
        value={value}
        onChange={ev => onChange(ev.target.value)}
        justify
      >
        {variations.map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <InputRadio.Button key={`${index}`} value={index}>
            {LABELS[index]}
          </InputRadio.Button>
        ))}
      </InputRadio.Group>
    </PopoverContent>
  );
}

Content.defaultProps = {
  value: 0,
};

export default class ItemVariations extends Item {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.visible = false;
  }

  handleChange = (value) => {
    this.setState({ visible: false });
    this.props.onChange(value);
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  renderButton() {
    const { value = 0, variations } = this.props;
    const enabled = variations && variations.length > 1;
    const button = cloneElement(super.renderButton(), {}, (
      <VariationLabel>
        {`Variation ${LABELS[value]}`}
        {enabled ? <Icon /> : null}
      </VariationLabel>
    ));
    if (enabled) {
      return (
        <Popover
          title={null}
          content={(
            <Content
              value={value}
              onChange={this.handleChange}
              variations={variations}
            />
          )}
          trigger="click"
          destroyTooltipOnHide
          mouseLeaveDelay={0.1}
          placement="bottomLeft"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
        >
          {button}
        </Popover>
      );
    }
    return button;
  }
}

ItemVariations.Separator = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 1;
  transform: translateY(3px);
  margin-left: 2px;
  margin-right: 2px;
`;

ItemVariations.Separator.defaultProps = {
  children: '/',
};
