import Types from '../../modules/types';

import tests from '../../helpers/tests';

const {
  activityNotCreate,
  viewerIsNotAdmin,
  userIsNotCustomer,
  skipIfUserIsCustomerWhichCanManageOrganization,
  viewerCannotEditUserPermissions,
} = tests;

const skipPassword = () => false;

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [['validation.isRequired'], ['validation.isName']],
}];

export const fieldEmail = ['FieldText', {
  name: 'email',
  label: null,
  prefix: 'Email:',
  autoComplete: 'new-email',
  rules: [['validation.isRequired'], ['validation.isEmail']],
}];

export const fieldRole = ['FieldSelect', {
  name: 'role',
  label: null,
  getInitialValue: (field, props) => (
    props.data && props.data.role
    ? props.data.role
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? 'CUSTOMER'
    : 'ADMIN'
  ),
  prefix: 'Role:',
  showSearch: true,
  rules: [['validation.isRequired']],
  choices: Types.ROLE.map((value) => ({
    label: Types.ROLE_LABELS_MAP[value],
    value,
  })),
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
  hidden: [viewerIsNotAdmin],
}];

export const dividerPassword = ['Divider', {
  name: 'passwordDivider',
  skip: [skipPassword],
}];

export const fieldPassword = ['FieldText', {
  name: 'password',
  type: 'password',
  autoComplete: 'new-password',
  label: null,
  prefix: 'Password:',
  rules: [
    ['validation.isPassword'],
  ],
  skip: [skipPassword],
  col: { lg: 12, xs: 24 },
}];

export const fieldPasswordConfirm = ['FieldText', {
  name: 'confirmPassword',
  type: 'password',
  label: null,
  prefix: 'Confirm Password:',
  rules: [
    ['validation.isPasswordSame'],
  ],
  skip: [skipPassword],
  virtual: true,
  col: { lg: 12, xs: 24 },
}];

export const dividerUnits = ['Divider', {
  name: 'unitsDivider',
  skip: [],
}];

export const fieldUnits = ['FieldRadio', {
  name: 'units',
  label: null,
  initialValue: Types.LENGTH_UNIT[1],
  choices: Types.LENGTH_UNIT.map(value => ({
    label: `Use ${Types.LENGTH_UNIT_LABELS_MAP[value].toUpperCase()}`,
    value,
  })),
}];

// Customer

export const fieldOrganization = ['FieldConnectionSelect', {
  name: 'organization',
  label: null,
  getInitialValue: (field, props) => (
      props.data && props.data.organization
    ? props.data.organization
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? props.viewer.organization
    : undefined
  ),
  prefix: 'Organization:',
  placeholder: 'Select the customers organization',
  url: '/organizations',
  searchPaths: ['name'],
  getFieldsToResetOnChange: () => ['permissions.readProjectsForTeams'],
  // rules: [['validation.isRequired']],
  disabled: [activityNotCreate],
  virtual: [activityNotCreate],
  hidden: [
    viewerIsNotAdmin,
    userIsNotCustomer,
  ],
}];

export const dividerCustomerPermissions = ['Divider', {
  name: 'permissionsDivider',
  skip: [
    userIsNotCustomer,
  ],
}];

export const fieldsCustomerPermissions = [
  ...[
    ['manageOrganization', 'Manage Organization', []],
    ['manageLibrary', 'Manage Library', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
    ['permissionsProjectsDivider', null, [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ], true],
    ['manageProjects', 'Manage Projects', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
    ['createProjects', 'Create Projects', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
    ['readProjects', 'Access Project Reports', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
  ].map(([name, label, disabled, isDivider = false]) => (
    [isDivider ? 'Divider' : 'FieldSwitch', {
      name: `permissions.${name}`,
      label,
      skip: [
        userIsNotCustomer,
        viewerCannotEditUserPermissions,
      ],
      prepareValueForInput: (value, props) => {
        if (name === 'manageOrganization') {
          return value;
        }
        const manageOrganization = props.form.getFieldValue(
          'permissions.manageOrganization',
        );
        if (['createProjects', 'readProjects'].includes(name)) {
          const manageProjects = props.form.getFieldValue(
            'permissions.manageProjects',
          );
          return (manageOrganization || manageProjects) ? true : value;
        }
        return manageOrganization ? true : value;
      },
      disabled: [
        ...disabled,
        ...(
          ['createProjects', 'readProjects'].includes(name)
          ? [[
              'condition.testFieldValue',
              'permissions.manageProjects',
              '===',
              true,
            ]]
          : []
        ),
      ],
      col: isDivider ? undefined : { lg: 8, xs: 24 },
      ghost: isDivider ? true : undefined,
    }]
  )),
  ['FieldConnectionSelect', {
    name: 'permissions.readProjectsForTeams',
    label: 'Access Project Reports for Teams',
    placeholder: 'Select teams this user can read project reports for',
    url: '/teams',
    searchPaths: ['name'],
    mode: 'multiple',
    getExtraQueryConditions: (props) => {
      const organization = props.form.getFieldValue('organization');
      return [{
        organization: {
          IN: organization ? [organization, null] : [null],
        },
      }];
    },
    skip: [
      userIsNotCustomer,
      viewerCannotEditUserPermissions,
    ],
    prepareValueForInput: (value, props) => {
      const manageOrganization = props.form.getFieldValue(
        'permissions.manageOrganization'
      );
      const manageProjects = props.form.getFieldValue(
        'permissions.manageProjects'
      );
      const readProjects = props.form.getFieldValue(
        'permissions.readProjects'
      );
      return (
        manageOrganization
        || manageProjects
        || !readProjects
      )
      ? []
      : value;
    },
    disabled: [
      skipIfUserIsCustomerWhichCanManageOrganization,
      [
        'condition.testFieldValue',
        'permissions.readProjects',
        '!==',
        true,
      ],
      [
        'condition.testFieldValue',
        'permissions.manageProjects',
        '===',
        true,
      ],
    ],
  }],
];

export const ALL = [
  fieldName,
  fieldEmail,
  fieldRole,
  fieldOrganization,
  fieldPassword,
  fieldPasswordConfirm,
  fieldUnits,
  dividerCustomerPermissions,
  ...fieldsCustomerPermissions,
];
