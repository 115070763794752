import Admin from 'hive-admin';
// eslint-disable-next-line max-len
import ActionSaveAndReloadOriginal from 'hive-admin/src/components/ActionSaveAndReload';

export default class ActionSaveAndReload extends ActionSaveAndReloadOriginal {
  static config = {
    ...ActionSaveAndReloadOriginal.config,
    ghost: false,
    icon: 'save',
  }
}

Admin.addToLibrary(
  'ActionSaveAndReload',
  config => ActionSaveAndReload.create(config),
);
