import memoizeOne from 'memoize-one';

import React, { cloneElement } from 'react';
import styled from 'styled-components';

import { DownOutlined } from '@ant-design/icons';

import InputRadio from 'hive-admin/src/components/Input/Radio';

import Popover, {
  Content as PopoverContent,
} from '../../../Popover';

import Item from './Item';

import Types from '../../../../modules/types';

const SectionLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(2px);
  line-height: 18px;
`;

const Icon = styled(DownOutlined)`
  font-size: 14px;
  margin-left: 7px;
`;

const NotTestedLabel = styled.span`
  opacity: 0.5;
  font-size: 80%;
  margin-left: 10px;
`;

function Content({ value, onChange, supportsDisplay, options: { groups } }) {
  return (
    <PopoverContent title="Store Section" width={230}>
      {groups.map(({ id, options }) => (
        <InputRadio.Group
          key={id}
          value={value}
          onChange={ev => onChange(ev.target.value)}
          justify
        >
          {options.map(option => (
            <InputRadio.Button key={option.value} value={option.value}>
              {
                option.value === 'DISPLAY' && !supportsDisplay
                ? (
                    <>
                      {option.label}
                      <NotTestedLabel>Not Tested</NotTestedLabel>
                    </>
                  )
                : option.label
              }
            </InputRadio.Button>
          ))}
        </InputRadio.Group>
      ))}
    </PopoverContent>
  );
}

export default class ItemSection extends Item {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.open = false;
  }

  getOptions = memoizeOne(target => (
    Types.PLANOGRAM.target[target].supportedSection.reduce(
      (agr, section) => {
        const { section: { [section]: { group, hidden } } } = Types.PLANOGRAM;
        if (!hidden) {
          const { groups, groupsMap } = agr;
          if (!groupsMap[group]) {
            groupsMap[group] = { id: group, options: [] };
            groups.push(groupsMap[group]);
          }
          groupsMap[group].options.push({
            value: section,
            label: Types.PLANOGRAM.section[section].name,
          });
          agr.count++;
        }
        return agr;
      },
      { count: 0, groups: [], groupsMap: {} },
    )
  ))

  handleChange = (value) => {
    this.setState({ open: false });
    this.props.onChange(value);
  }

  handleOpenChange = (open) => {
    this.setState({ open: !!open });
  }

  renderButton() {
    const { value, target, purpose, supportsDisplay } = this.props;
    const options = this.getOptions(target);
    const purposedValue = Types.PLANOGRAM_PURPOSE_SECTION_MAP[purpose];
    const button = cloneElement(super.renderButton(), {}, (
      <SectionLabel>
        {Types.PLANOGRAM.section[purposedValue || value].name}
        {options.count > 1 ? <Icon /> : null}
      </SectionLabel>
    ));
    if (options.count > 1) {
      return (
        <Popover
          title={null}
          content={(
            <Content
              value={value}
              onChange={this.handleChange}
              options={options}
              supportsDisplay={supportsDisplay}
            />
          )}
          trigger="click"
          destroyTooltipOnHide
          mouseLeaveDelay={0.1}
          placement="bottomLeft"
          visible={this.state.open}
          onVisibleChange={this.handleOpenChange}
        >
          {button}
        </Popover>
      );
    }
    return button;
  }
}
