// eslint-disable-next-line import/no-extraneous-dependencies
import pluralize from 'pluralize';

import Admin from 'hive-admin';
import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';

import tests from '../../helpers/tests';

import {
  CATEGORIES as FILTERS_CATEGORIES,
  COMPANIES as FILTERS_COMPANIES,
  BRANDS as FILTERS_BRANDS,
  PRODUCTS as FILTERS_PRODUCTS,
  POS_MATERIALS as FILTERS_POS_MATERIALS,
  PLANOGRAMS as FILTERS_PLANOGRAMS,
} from './filters';
import {
  CATEGORIES as FIELDS_CATEGORIES,
  COMPANIES as FIELDS_COMPANIES,
  BRANDS as FIELDS_BRANDS,
  PRODUCTS_CREATE as FIELDS_PRODUCTS_CREATE,
  PRODUCTS_EDIT as FIELDS_PRODUCTS_EDIT,
  POS_MATERIALS as FIELDS_POS_MATERIALS,
  PLANOGRAMS as FIELDS_PLANOGRAMS,
} from './fields';
import {
  CATEGORIES as COLUMNS_CATEGORIES,
  COMPANIES as COLUMNS_COMPANIES,
  BRANDS as COLUMNS_BRANDS,
  PRODUCTS as COLUMNS_PRODUCTS,
  POS_MATERIALS as COLUMNS_POS_MATERIALS,
  PLANOGRAMS as COLUMNS_PLANOGRAMS,
} from './columns';

const { defaultProps: { getColumns: initialGetColumns } } = PageArchiveTable;

const {
  viewerIsNotAdmin,
  viewerIsNotContentManager,
  viewerCannotManageLibrary,
  isActivityEditAndlibraryPlanogramIsPurposed,
} = tests;

const itemIsGlobalWhileEditing = props => (
  props.activity === 'edit'
  && props.data
  && props.data.organization === null
);

const viewerIsCustomerAndItemIsGlobalWhileEditing = props => (
  props.viewer
  && props.viewer.role === 'CUSTOMER'
  && itemIsGlobalWhileEditing(props)
);

export default ['Group', {
  id: 'library',
  title: 'Library',
  icon: 'book',
  skip: [viewerCannotManageLibrary],
  pages: [{
    title: 'Categories',
    icon: 'unordered-list',
    path: '/categories',
    filters: FILTERS_CATEGORIES,
    columns: COLUMNS_CATEGORIES,
    fields: FIELDS_CATEGORIES,
    skip: [
      ['condition.and', [
        viewerIsNotAdmin,
        viewerIsNotContentManager,
      ]],
    ],
  }, {
    title: 'Companies',
    icon: 'solution',
    path: '/companies',
    filters: FILTERS_COMPANIES,
    columns: COLUMNS_COMPANIES,
    fields: FIELDS_COMPANIES,
    // duplicateFields: [
    //   'name',
    // ],
  }, {
    title: 'Brands',
    icon: 'apple',
    path: '/brands',
    filters: FILTERS_BRANDS,
    columns: COLUMNS_BRANDS,
    fields: FIELDS_BRANDS,
    // duplicateFields: [
    //   'name',
    //   'company',
    // ],
  }, {
    title: 'Products',
    icon: 'barcode',
    path: '/products',
    filters: FILTERS_PRODUCTS,
    columns: COLUMNS_PRODUCTS,
    fields: FIELDS_PRODUCTS_CREATE,
    fieldsEdit: FIELDS_PRODUCTS_EDIT,
    // duplicateFields: [
    //   'image',
    //   'name',
    //   'description',
    //   'price',
    //   'height',
    //   'barcode',
    //   'category',
    //   'brand',
    // ],
  }, {
    title: 'POS Materials',
    icon: 'interaction',
    path: '/posmaterials',
    filters: FILTERS_POS_MATERIALS,
    columns: COLUMNS_POS_MATERIALS,
    fields: FIELDS_POS_MATERIALS,
    // duplicateFields: [
    //   'image',
    //   'name',
    //   'type',
    // ],
  }, {
    title: 'Planograms',
    icon: 'pic-center',
    path: '/planograms',
    filters: FILTERS_PLANOGRAMS,
    columns: COLUMNS_PLANOGRAMS,
    fields: FIELDS_PLANOGRAMS,
    // duplicateFields: [
    //   'name',
    //   'categories',
    //   'planogram',
    // ],
    useActionSaveAndReload: true,
    extraSkipDelete: [isActivityEditAndlibraryPlanogramIsPurposed],
  }].reduce(
    (
      pages,
      {
        filters = [],
        columns = [],
        fields = [],
        fieldsEdit = fields,
        useActionSaveAndReload,
        extraSkipDelete = [],
        ...config
      },
    ) => {
      const path = `/library${config.path}`;
      const group = Admin.compileFromLibrary(
        ['GroupResource', {
          ...config,
          skip: config.skip || [viewerCannotManageLibrary],
          redirect: [['redirect.unauthorized']],
          path,
          archiveConfig: {
            title: config.title,
            label: config.title,
            filters,
            getArchiveFilters: (props, filtersOriginal) => (
              filtersOriginal.filter(filter => (
                  !filter.isAvailable
                ? true
                : filter.isAvailable(props, filter)
              ))
            ),
            getColumns: props => initialGetColumns(
              props,
              columns
              .filter(column => (
                  !column.isAvailable
                ? true
                : column.isAvailable(props, column)
              )),
            ),
          },
          fields: fields.map(field => ([field[0], {
            ...field[1],
            disabled: [
              ...(field[1].disabled || []),
              viewerIsCustomerAndItemIsGlobalWhileEditing,
            ],
          }])),
          singleConfig: {
            hidden: true,
            alias: path,
            backButtonPaths: [path],
            headerTitle: `Create ${pluralize.singular(config.title)}`,
            duplicateLoadUrl: `${path}/:duplicate`,
          },
          singleEditConfig: {
            fields: fieldsEdit,
            actions: [
              [useActionSaveAndReload ? 'ActionSaveAndReload' : 'ActionSave', {
                skip: [viewerIsCustomerAndItemIsGlobalWhileEditing],
              }],
              ['ActionDelete', {
                skip: [
                  viewerIsCustomerAndItemIsGlobalWhileEditing,
                  ...extraSkipDelete,
                ],
              }],
              ['Action', {
                name: 'duplicate',
                title: 'Duplicate',
                type: 'primary',
                ghost: true,
                skip: [
                  props => !itemIsGlobalWhileEditing(props),
                ],
                onClick: (props) => {
                  props.history.push(
                    `${path}/create?duplicate=${props.data._id}`
                  );
                },
              }],
            ],
          },
        }],
        false,
      );
      pages.push(...group.pages);
      return pages;
    },
    [],
  ),
}];
