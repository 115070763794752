import React from 'react';
import styled from 'styled-components';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin';
import OriginalActionDelete from 'hive-admin/src/components/ActionDelete';

const IconDelete = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.less.errorColor} !important;
  i { color: ${({ theme }) => theme.less.errorColor} !important; }
`;

class ActionDelete extends OriginalActionDelete {
  static config = {
    ...OriginalActionDelete.config,
    ghost: true,
    icon: 'delete',
    popconfirm: {
      title: 'Permanently remove this item?',
      okButtonProps: { type: 'danger', icon: 'delete' },
      okText: 'Delete',
      cancelButtonProps: { },
      icon: <IconDelete />,
    },
  }
}

Admin.addToLibrary(
  'ActionDelete',
  config => ActionDelete.create(config),
);
