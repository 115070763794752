import isObject from 'lodash/isObject';

import mongoose from 'mongoose/browser';
import memoize from 'memoize-one';

import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import AntdSelect from 'antd/lib/select';
import AntdButton from 'antd/lib/button';
// import AntdRadio from 'antd/lib/radio';

import { SettingOutlined } from '@ant-design/icons';

import InputRadio from 'hive-admin/src/components/Input/Radio';

import Popover, {
  Content as PopoverContent,
} from '../../../../Popover';

import Types from '../../../../../modules/types';

const { Types: { ObjectId } } = mongoose;

const InputSelect = styled(AntdSelect)`
  width: 100%;
`;

const Content = styled(PopoverContent)`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    filter: grayscale(100%);
  `}
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  [data-full-placeholder="true"] .ant-select-selection__placeholder {
    color: ${({ theme }) => theme.less.textColor};
  }
`;

const Button = styled.div`
  display: flex;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
`;

function cloneDeepWithNewIds(value) {
  if (Array.isArray(value)) {
    return value.map(cloneDeepWithNewIds);
  }
  if (isObject(value)) {
    return Object.keys(value).reduce(
      (agr, key) => {
        if (key === '_id' && value[key]) {
          agr[key] = `${ObjectId()}`;
        } else {
          agr[key] = cloneDeepWithNewIds(value[key]);
        }
        return agr;
      },
      {},
    );
  }
  return value;
}

export default class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAlignmentChange = (value) => {
    this.props.onChangeShelf(this.props.id, { alignment: value });
  }

  renderAlignmentOptions = memoize(() => (
    Types.SHELF_ALIGNMENT.map(alignment => (
      <InputSelect.Option key={alignment} value={alignment}>
        {Types.SHELF_ALIGNMENT_LABELS_MAP[alignment]}
      </InputSelect.Option>
    ))
  ))

  handleSegmentCountChange = ({ target: { value } }) => {
    const {
      id,
      value: { segments: segmentsOld = [] },
      getProduct,
    } = this.props;
    const { type: { id: typeId } } = Types.PLANOGRAM.map[id];
    const segments = [];
    let isValid = true;
    for (let i = 0; i < value; i++) {
      if (segmentsOld[i]) {
        isValid = Types.PLANOGRAM.validateSegmentSpace(
          segmentsOld[i].groups.map(group => ({
            ...group,
            product: getProduct(group.product),
          })),
          typeId,
          value,
          i,
        );
        if (!isValid) {
          break;
        }
        segments.push(segmentsOld[i]);
      } else {
        segments.push({
          _id: `${ObjectId()}`,
          groups: [],
          posMaterials: {},
        });
      }
    }
    if (isValid) {
      this.props.onChangeShelf(this.props.id, { segments });
    }
  }

  renderSegmentOptions = memoize((type) => {
    const { segment: { min, max } } = Types.PLANOGRAM.type[type];
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(
        <InputRadio.Button key={i} value={i}>
          {i}
        </InputRadio.Button>
      );
    }
    return options;
  })

  renderSwapAndCopyOptions = memoize((id, target) => {
    const { type: { id: typeId } } = Types.PLANOGRAM.map[id];
    const sections = Types.PLANOGRAM.target[target].list.reduce(
      (agr, shelfId) => {
        const {
          name,
          type: { id: shelfTypeId },
          section: { id: sectionId, name: sectionName, interactive },
        } = Types.PLANOGRAM.map[shelfId];
        if (interactive && shelfTypeId === typeId) {
          if (!agr.map[sectionId]) {
            agr.map[sectionId] = {
              id: sectionId,
              name: sectionName,
              options: [],
            };
            agr.list.push(agr.map[sectionId]);
          }
          agr.map[sectionId].options.push({
            value: shelfId,
            name,
          });
        }
        return agr;
      },
      { list: [], map: {} },
    );
    sections.list.forEach(({ options }) => options.sort(
      (a, b) => (
          a.name > b.name ? 1
        : a.name < b.name ? -1
        : 0
      ),
    ));
    return sections.list.map(section => (
      <InputSelect.OptGroup
        key={section.id}
        label={section.name}
      >
        {section.options.map(shelf => (
          <InputSelect.Option
            key={shelf.value}
            value={shelf.value}
            disabled={shelf.value === id}
          >
            {shelf.name}
          </InputSelect.Option>
        ))}
      </InputSelect.OptGroup>
    ));
  })

  handleFridgeChange = ({ target: { value } }) => {
    this.props.onChangeShelf(this.props.id, { fridge: !!value });
  }

  handleFridgeOpenAirChange = ({ target: { value } }) => {
    this.props.onChangeShelf(this.props.id, { fridgeOpenAir: !!value });
  }

  handleClear = () => {
    this.props.onChangeShelf(this.props.id, {
      segments: this.props.value.segments.map(() => ({
        _id: `${ObjectId()}`,
        groups: [],
        posMaterials: {},
      })),
    });
  }

  handleSwap = (swapShelfId) => {
    const { id: shelfId, shelfs } = this.props;
    const shelf = shelfs[shelfId];
    const swapShelf = shelfs[swapShelfId];
    this.props.onChangeShelfs({
      ...shelfs,
      [shelfId]: swapShelf,
      [swapShelfId]: shelf,
    });
  }

  handleCopy = (copyShelfId) => {
    const { id: shelfId, shelfs } = this.props;
    const shelfClone = cloneDeepWithNewIds(shelfs[shelfId]);
    this.props.onChangeShelfs({
      ...shelfs,
      [copyShelfId]: shelfClone,
    });
  }

  renderPopoverContent() {
    const { id, value, disabled, target } = this.props;
    const {
      type: { id: typeId, fridge: fridgeSupport },
    } = Types.PLANOGRAM.map[id];
    const segmentOptions = this.renderSegmentOptions(typeId);
    return (
      <Content
        title="Settings"
        width={typeId === 'display' ? 200 : 300}
        disabled={disabled}
      >
        <InputWrapper>
          <InputSelect
            value={value.alignment}
            onChange={this.handleAlignmentChange}
          >
            {this.renderAlignmentOptions()}
          </InputSelect>
        </InputWrapper>
        {
          segmentOptions && segmentOptions.length > 1
          ? (
              <InputWrapper>
                <InputRadio.Group
                  value={value.segments.length}
                  onChange={this.handleSegmentCountChange}
                  justify
                >
                  {this.renderSegmentOptions(typeId)}
                </InputRadio.Group>
              </InputWrapper>
            )
          : null
        }
        {
          fridgeSupport
          ? (
              <InputWrapper>
                <InputRadio.Group
                  value={!!value.fridge}
                  onChange={this.handleFridgeChange}
                  justify
                >
                  <InputRadio.Button value={false}>
                    Regular
                  </InputRadio.Button>
                  {/* eslint-disable-next-line react/jsx-boolean-value */}
                  <InputRadio.Button value={true}>
                    Fridge
                  </InputRadio.Button>
                </InputRadio.Group>
              </InputWrapper>
            )
          : null
        }
        {
          fridgeSupport && value.fridge
          ? (
              <InputWrapper>
                <InputRadio.Group
                  value={!!value.fridgeOpenAir}
                  onChange={this.handleFridgeOpenAirChange}
                  justify
                >
                  <InputRadio.Button value={false}>
                    Enclosed
                  </InputRadio.Button>
                  {/* eslint-disable-next-line react/jsx-boolean-value */}
                  <InputRadio.Button value={true}>
                    Open Air
                  </InputRadio.Button>
                </InputRadio.Group>
              </InputWrapper>
            )
          : null
        }
        {
          typeId !== 'fridge'
          ? (
              <InputWrapper>
                <InputSelect
                  value={undefined}
                  placeholder="Swap With"
                  onChange={this.handleSwap}
                  data-full-placeholder="true"
                >
                  {this.renderSwapAndCopyOptions(id, target)}
                </InputSelect>
                <InputSelect
                  value={undefined}
                  placeholder="Copy To"
                  onChange={this.handleCopy}
                  data-full-placeholder="true"
                >
                  {this.renderSwapAndCopyOptions(id, target)}
                </InputSelect>
              </InputWrapper>
            )
          : null
        }
        {
          <InputWrapper>
            <AntdButton block onClick={this.handleClear}>
              Remove All Products
            </AntdButton>
          </InputWrapper>
        }
      </Content>
    );
  }

  render() {
    const {
      toolbarItemId,
      toolbarItemIdOpen,
      onOpen,
      onClose,
    } = this.props;
    return (
      <Popover
        title={null}
        content={this.renderPopoverContent()}
        trigger="hover"
        destroyTooltipOnHide
        mouseLeaveDelay={0.5}
        placement="bottomRight"
        visible={toolbarItemId === toolbarItemIdOpen}
        onVisibleChange={visible => (
          visible
          ? onOpen(toolbarItemId)
          : onClose(toolbarItemId)
        )}
      >
        <Button className="planogram-editor-body-shelf-header-settings">
          <SettingOutlined />
        </Button>
      </Popover>
    );
  }
}
