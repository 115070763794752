import React from 'react';
import styled from 'styled-components';

import { Link } from 'hive-admin/node_modules/react-router-dom';

import {
  HomeOutlined,
  GlobalOutlined,
  LockOutlined,
} from '@ant-design/icons';

import {
  columnCreatedAt,
} from '../common';

import Types from '../../modules/types';

const IconNamePrefixHTML = ({ organization, purpose, ...props }) => (
    organization
  ? <HomeOutlined title="Organization" {...props} />
  : purpose
  ? <LockOutlined title="Special" {...props} />
  : <GlobalOutlined title="Global" {...props} />
);

const IconNamePrefix = styled(IconNamePrefixHTML)`
  opacity: ${({ organization, purpose }) => (
    organization || purpose
    ? 1
    : 0.25
  )};
  margin-right: 10px;
`;

export const columnName = {
  path: 'name',
  render: (record, column, props) => (
    <Link to={`${props.page.path}/${record._id}`}>
      {
        props.viewer
        ? (
              props.viewer.role === 'CUSTOMER'
            ? <IconNamePrefix organization={record.organization} />
            : record.purpose
            ? <IconNamePrefix purpose={record.purpose} />
            : null
          )
        : null
      }
      {record.name}
    </Link>
  ),
};

export const columnRegion = {
  path: 'region',
  title: 'Region',
  render: record => (
    record && record.region && record.region.name
    ? record.region.name
    : '-'
  ),
};

export const columnOrganization = {
  path: 'organization.name',
  title: 'Organization',
  render: record => (
      !record.organization
    ? 'Global'
    : (
        <Link to={`/organizations/${record.organization._id}`}>
          {record.organization.name}
        </Link>
      )
  ),
  isAvailable: props => props.viewer && props.viewer.role === 'ADMIN',
};

export const columnCategory = {
  path: 'category.name',
  title: 'Category',
};

export const columnCategories = {
  path: 'categories',
  title: 'Categories',
  render: record => (
    record.categories && record.categories.length
    ? record.categories.map(({ name }) => name).join(', ')
    : '/'
  ),
};

export const columnCompany = {
  path: 'company.name',
  title: 'Company',
  link: '/library/companies/:company._id',
};

export const columnBrand = {
  path: 'brand.name',
  title: 'Brand',
  link: '/library/brands/:brand._id',
};

export const columnPOSMaterialType = {
  path: 'type',
  title: 'Type',
  render: record => `${Types.POS_MATERIAL_TYPE_LABELS_MAP[record.type]}`,
};

export const CATEGORIES = [
  columnName,
  columnCreatedAt,
];

export const COMPANIES = [
  columnName,
  columnCreatedAt,
  columnOrganization,
];

export const BRANDS = [
  columnName,
  columnCompany,
  columnCreatedAt,
  columnOrganization,
];

export const PRODUCTS = [
  columnName,
  columnRegion,
  columnCategory,
  columnCompany,
  columnBrand,
  columnCreatedAt,
  columnOrganization,
];

export const POS_MATERIALS = [
  columnName,
  columnRegion,
  columnPOSMaterialType,
  columnCreatedAt,
  columnOrganization,
];

export const PLANOGRAMS = [
  columnName,
  columnRegion,
  columnCategories,
  columnCreatedAt,
  columnOrganization,
];
