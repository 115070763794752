/* eslint-disable import/prefer-default-export */

import isFinite from 'lodash/isFinite';
import moment from 'moment-shortformat';

export function getValueForFieldDefault(value) {
  return (
    value && value.length
    ? value
    : undefined
  );
}

export function cloneItem(
  item,
  col = null,
  isAvailable = null,
  extras = {},
) {
  return [
    item[0],
    Object.assign(
      { ...item[1] },
      col ? { col: isFinite(col) ? { xs: 24, lg: col } : col } : {},
      isAvailable ? { isAvailable } : {},
      { ...extras },
    ),
  ];
}

export const filterSort = ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    name: { name: 1 },
    date: { createdAt: -1 },
  },
  buildQueryDefault: 'name',
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP[filter.buildQueryDefault] })
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort',
    defaultValue: 'date',
    initialValue: 'date',
    prepareValueForInput: (value, props) => value || props.buildQueryDefault,
    choices: [{
      label: 'Sort by name',
      value: 'name',
    }, {
      label: 'Sort by date',
      value: 'date',
    }],
  }],
}];

export const filterSortLibraryForCustomer = [filterSort[0], {
  ...filterSort[1],
  VALUES_MAP: {
    name: { organization: -1, name: 1 },
    date: { organization: -1, createdAt: -1 },
  },
}];

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Created',
  render: record => moment(record.createdAt).format('lll'),
};

export const columnUpdatedAt = {
  path: 'updatedAt',
  title: 'Last Updated',
  render: record => moment(record.updatedAt).format('lll'),
};
