/* eslint-disable no-console */
import getKey from 'lodash/get';
import memoizeOne from 'memoize-one';

import React from 'react';

import Query from 'hive-admin/src/components/Query';

import { stringify as stringifyQuery } from 'querystring';

import Types from '../../../modules/types';

import TableReport from './TableReport';
import { Header, Table, TableHeader, TableCell } from './common';

export default class TableShoppingMetrics extends TableReport {
  getTableColumns = memoizeOne(() => (
    [
      {
        key: 'variation',
        title: (
          <TableHeader description="Scenarios Tested">
            Variation
          </TableHeader>
        ),
        width: '130px',
        align: 'left',
        render: (text, record, index) => (
          <TableHeader
            description={`${
              getKey(record, 'stat.project.sample.total', 0)
            } Shoppers`}
          >
            {
              index === 0
              ? 'Control'
              : `${Types.VARIATION_LETTERS[record.variation]}`
            }
          </TableHeader>
        ),
      },
      ...[
        ['Spending', 'avg. / per shopper', 'project'],
        ['Category Spending', 'avg. / per shopper', 'category'],
      ].map(([title, subtitle, key]) => ({
        key: `avgSpendingFor${key}`,
        title: (
          <TableHeader description={subtitle}>
            {title}
          </TableHeader>
        ),
        align: 'right',
        render: (text, record) => (
          <TableCell
            description={`over ${
              getKey(record, `stat.${key}.sample.cart`, 0)
            } shoppers`}
          >
            {`$${
              (getKey(
                record,
                `stat.${key}.spending.average`,
                0,
              ) || 0).toFixed(2)
            }`}
          </TableCell>
        ),
      })),
      {
        key: 'avgShoppingDuration',
        title: (
          <TableHeader description="avg. / per shopper">
            Shopping Duration
          </TableHeader>
        ),
        align: 'right',
        render: (text, record) => (
          <TableCell
            description={`over ${
              getKey(record, 'stat.project.sample.total', 0)
            } shoppers`}
          >
            {`${
              ((
                getKey(record, 'stat.project.durations.shopping', 0)
                / 1000
              ) || 0).toFixed(0)
            }s`}
          </TableCell>
        ),
      },
    ]
  ))

  extractQueryData = (response) => {
    if (!response || !response.data) {
      return null;
    }
    const {
      content: { data: { resources } },
      filters: { values: { product: productId, age, gender } },
    } = this.props;
    const map = response.data.reduce(
      (agr, item) => {
        if (!agr[item.variation]) {
          agr[item.variation] = {};
        }
        agr[item.variation][item.type] = item;
        return agr;
      },
      {},
    );
    const product = resources.product[productId];
    const brand = resources.brand[product.brand];
    const category = resources.category[product.category];
    const { content: { variationValues } } = this.props;
    const data = [];
    for (let i = 0; i < variationValues.length; i++) {
      const variation = variationValues[i];
      const stat = map[variation];
      const item = {
        key: variation,
        variation,
        age,
        gender,
        product,
        brand,
        category,
        stat: stat || {},
      };
      data.push(item);
    }
    return data;
  }

  getQueryUrl = () => {
    const {
      filters: { values: { product, age, gender } },
      content: { data, project, variationValues },
    } = this.props;
    return `/projects/${project._id}/stats/compact?${stringifyQuery({
      query: JSON.stringify({
        compact: [
          [{
            type: 'project',
            content: 'project',
          }, {
            age: [age],
            gender: [gender],
            variation: [...variationValues],
          }, {
            select: { sample: 1, spending: 1, durations: 1 },
          }], [{
            type: 'category',
            content: data.resources.product[product].category,
          }, {
            age: [age],
            gender: [gender],
            variation: [...variationValues],
          }, {
            select: { sample: 1, spending: 1, durations: 1 },
          }],
        ],
      }),
    })}`;
  }

  renderContent = ({ data, error }) => {
    if (error) {
      console.log(error);
      return null;
    }
    if (!data) {
      return null;
    }
    return (
      <>
        <Header
          title="Metrics"
          // eslint-disable-next-line max-len
          description="Explore general shopper behaviour."
        />
        <Table
          dataSource={data}
          columns={this.getTableColumns(data)}
          pagination={false}
          size="small"
          bordered
        />
      </>
    );
  }

  render() {
    return (
      <Query
        client={this.props.client}
        content={this.props.content}
        extractData={this.extractQueryData}
        url={this.getQueryUrl()}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }
}
