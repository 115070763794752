/* eslint-disable no-console */
import getKey from 'lodash/get';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';

import React from 'react';

import Query from 'hive-admin/src/components/Query';

import AntdRate from 'antd/lib/rate';

import { stringify as stringifyQuery } from 'querystring';

import TableReport from './TableReport';
import {
  Header,
  Table as InitialTable,
  TableHeader,
  TableCell,
} from './common';
import { Tooltip } from '../../Popover';

import Types from '../../../modules/types';

const Table = styled(InitialTable)`
  tbody > tr > td:last-child {
    position: relative;
    overflow: hidden;
  }
  tbody > tr > td,
  thead > tr > th {
    white-space: nowrap;
  }
  .report-table-review-column-words {
    max-width: 350px;
    min-width: 150px;
    width: 100%;
  }
  /* tbody > tr > td {
    white-space: nowrap;
  } */
`;

const Rate = styled(AntdRate)`
  pointer-events: none !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
  li {
    &:not(:last-child) {
      margin-right: 4px !important;
    }
  }
`;

const WordTooltipWrapper = styled.div`
  display: flex;
  margin-top: -4px !important;
  margin: -4px !important;
`;

const WordTooltipLabel = styled.span`
  font-size: 14px;
  opacity: 0.8;
  text-transform: capitalize;
`;

const WordTooltipValue = styled.span`
  font-size: 14px;
  opacity: 0.5;
  margin-left: 10px;
`;

// eslint-disable-next-line arrow-body-style
const WordTooltip = ({ label, sum, children }) => {
  return (
    <Tooltip
      content={(
        <WordTooltipWrapper>
          <WordTooltipLabel>{label}</WordTooltipLabel>
          <WordTooltipValue>{`(${sum})`}</WordTooltipValue>
        </WordTooltipWrapper>
      )}
      destroyTooltipOnHide
    >
      {children}
    </Tooltip>
  );
};

const Words = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Word = styled.div.attrs(props => ({
  style: {
    backgroundColor: props.theme.less.primaryColor,
    opacity: 0.15 + (props.value * 0.85),
  },
}))`
  position: relative;
  display: flex;
  color: white;
  font-size: 13px;
  line-height: 1.4;
  text-transform: capitalize;
  padding: 2px 8px;
  margin: 2px;
  border-radius: 4px;
`;

export default class TableReview extends TableReport {
  getTableColumns = memoizeOne(() => (
    [
      {
        key: 'variation',
        title: (
          <TableHeader description="Scenarios Tested">
            Variation
          </TableHeader>
        ),
        width: '130px',
        align: 'left',
        render: (text, record, index) => (
          <TableHeader
            description={`${
              getKey(record, 'stat.project.sample.total', 0)
            } Shoppers`}
          >
            {
                index === 0
              ? 'Control'
              : `${Types.VARIATION_LETTERS[record.variation]}`
            }
          </TableHeader>
        ),
      }, {
        key: 'rating',
        title: (
          <TableHeader
            description="avg / per shopper"
          >
            Rating
          </TableHeader>
        ),
        align: 'right',
        render: (text, record) => {
          const value = getKey(record, 'stat.project.rating', 0);
          return (
            <TableCell
              description={(
                <Rate
                  count={5}
                  value={Math.round(parseFloat(
                    getKey(record, 'stat.project.rating', 0)
                  ))}
                  allowClear={false}
                  allowHalf
                />
              )}
            >
              {value.toFixed(1)}
            </TableCell>
          );
        },
      }, {
        key: 'words',
        title: (
          <TableHeader
            description="10 most popular words"
          >
            Impressions
          </TableHeader>
        ),
        align: 'left',
        className: 'report-table-review-column-words',
        render: (text, record) => {
          const words = getKey(record, 'stat.project.words', []).slice(0, 10);
          return (
            <Words>
              {words.map(({ _id: word, sum, score }) => (
                <WordTooltip key={word} label={word} sum={sum}>
                  <Word value={score}>
                    {word}
                  </Word>
                </WordTooltip>
              ))}
            </Words>
          );
        },
      },
    ]
  ))

  extractQueryData = (response) => {
    if (!response || !response.data) {
      return null;
    }
    const {
      content: { data: { resources } },
      filters: { values: { product: productId, age, gender } },
    } = this.props;
    const map = response.data.reduce(
      (agr, item) => {
        if (!agr[item.variation]) {
          agr[item.variation] = {};
        }
        agr[item.variation][item.type] = item;
        return agr;
      },
      {},
    );
    const product = resources.product[productId];
    const brand = resources.brand[product.brand];
    const category = resources.category[product.category];
    const { content: { variationValues } } = this.props;
    const data = [];
    for (let i = 0; i < variationValues.length; i++) {
      const variation = variationValues[i];
      const stat = map[variation];
      const item = {
        key: variation,
        variation,
        age,
        gender,
        product,
        brand,
        category,
        stat: stat || {},
      };
      data.push(item);
    }
    return data;
  }

  getQueryUrl = () => {
    const {
      content: { project, variationValues },
      filters: { values: { age, gender } },
    } = this.props;
    return `/projects/${project._id}/stats/compact?${stringifyQuery({
      query: JSON.stringify({
        compact: [[{
          type: 'project',
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1, words: 1, rating: 1 },
        }]],
      }),
    })}`;
  }

  renderContent = ({ data, error }) => {
    if (error) {
      console.log(error);
      return null;
    }
    if (!data) {
      return null;
    }
    return (
      <>
        <Header
          title="Review"
          // eslint-disable-next-line max-len
          description={`Shopper impressions for${
            this.props.content.reviewType === 'product'
          ? ' Product:'
          : this.props.content.reviewType === 'posMaterial'
          ? ' POS Material:'
          : ''
          } "${
            this.props.content.review.name
          }"`}
        />
        <Table
          dataSource={data}
          columns={this.getTableColumns(data)}
          pagination={false}
          size="small"
          bordered
        />
      </>
    );
  }

  render() {
    return (
      <Query
        client={this.props.client}
        content={this.props.content}
        extractData={this.extractQueryData}
        url={this.getQueryUrl()}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }
}
