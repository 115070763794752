import React, { cloneElement } from 'react';
import styled, { css } from 'styled-components';

import AntdRadio from 'antd/lib/radio';

import Admin from 'hive-admin';
import FieldRadio from 'hive-admin/src/components/FieldRadio';

import Types from '../modules/types';

const RadioGroupHTML = ({ ...props }) => (
  <AntdRadio.Group
    // buttonStyle="solid"
    {...props}
  />
);

const RadioGroup = styled(RadioGroupHTML)`
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  > label {
    position: relative;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    height: auto;
    padding: 10px 15px;
    &:first-child {
      border-radius: 12px 0 0 12px;
    }
    &:last-child {
      border-radius: 0 12px 12px 0;;
    }
    > span:nth-child(2) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const RadioButtonHTML = ({ isDefault, ...props }) => (
  <AntdRadio.Button {...props} />
);

const ChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ChoiceLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
`;

const ChoiceDescription = styled.div`
  line-height: initial;
  max-width: 215px;
`;

const ChoicePrice = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ChoiceArrowHTML = ({ active, ...props }) => (
  <div {...props} />
);

const ChoiceArrow = styled(ChoiceArrowHTML)`
  background: #fff;
  position: absolute;
  opacity: 0;
  bottom: 0px;
  left: 50%;
  width: 0px;
  height: 0px;
  transition: all 200ms;
  transform-origin: center;
  transform: translate(-50%, 50%) rotate(-45deg);
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-color: ${({ theme }) => theme.less.primaryColor};
  ${({ active }) => active && css`
    bottom: -1px;
    width: 20px;
    height: 20px;
    opacity: 1;
  `}
`;

const RadioButton = styled(RadioButtonHTML)`
  &.ant-radio-button-wrapper-disabled {
    color: inherit;
    background-color: inherit;
    opacity: 0.5;
    &.ant-radio-button-wrapper-checked {
      opacity: 1;
      color: ${({ theme }) => theme.less.primaryColor};
      border-color: ${({ theme }) => theme.less.primaryColor};
    }
  }
  ${({ isDefault }) => isDefault && css`
    margin-top: -10px;
    margin-bottom: -10px;
    zoom: 1.2;
  `}
`;

export default class FieldPaymentPlan extends FieldRadio {
  static config = {
    ...FieldRadio.config,
    Component: RadioGroup,
    choiceClass: RadioButton,
    renderChoices: (field, props) => FieldRadio.config.renderChoices(
      field,
      { ...props, choices: props.data.payment.plans },
    ),
    renderChoice: (choice, field, props) => {
      const result = FieldRadio.config.renderChoice(choice, field, props);
      const {
        default: isDefault,
      } = Types.PROJECT_PAYMENT_PLAN_DEFINITION_MAP[choice._id];
      return cloneElement(result, { isDefault: !!isDefault });
    },
    getChoiceLabel: (choice, field, props) => {
      const {
        label,
        default: isDefault,
        description,
      } = Types.PROJECT_PAYMENT_PLAN_DEFINITION_MAP[choice._id];
      return (
        <ChoiceWrapper isDefault={isDefault}>
          <ChoiceLabel>
            {label}
          </ChoiceLabel>
          <ChoiceDescription>
            {description}
          </ChoiceDescription>
          <ChoicePrice>
            {
                choice._id !== 'QUOTE' || choice.price > -1
              ? `$ ${choice.price.toFixed(2)}`
              : 'Request'
            }
          </ChoicePrice>
          <ChoiceArrow active={props.value === choice._id} />
        </ChoiceWrapper>
      );
    },
    getChoiceValue: choice => choice._id,
  }

  static inputPropsMap = {
    ...FieldRadio.inputPropsMap,
  }
}

Admin.addToLibrary(
  'FieldPaymentPlan',
  config => FieldPaymentPlan.create(config),
);
