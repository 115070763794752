/* global window */
/* eslint-disable import/prefer-default-export */

import omit from 'lodash/omit';

import React from 'react';
import styled from 'styled-components';

import {
  RightOutlined,
  LeftOutlined,
  DownloadOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';

import AntdButton from 'antd/lib/button';

import ActionSave from 'hive-admin/src/components/ActionSave';

import Input from 'hive-admin/src/components/Input/Input';

import tests from '../../helpers/tests';

import {
  renderActionWithSavePromptAndProceed,
} from '../../components/PageSingleProject';

const {
  viewerIsAdmin,
  isViewerCustomerWhichCanManageOrganization,
  isViewerCustomerWhichCanManageProjects,
  isViewerProjectCreator,
} = tests;

const IconExclamation = styled(ExclamationOutlined)`
  color: ${({ theme }) => theme.less.errorColor} !important;
  i { color: ${({ theme }) => theme.less.errorColor} !important; }
`;

const ActionIconRight = styled(RightOutlined)`
  margin-right: -5px;
  margin-left: 5px;
`;

const ActionIconLeft = styled(LeftOutlined)`
  margin-right: 5px;
  margin-left: -5px;
`;

const ActionIconDownload = styled(DownloadOutlined)`
  margin-right: -5px;
  margin-left: 5px;
`;

const ActionPopconfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

const ActionPopconfirmButton = styled(AntdButton)`
  margin-top: 10px;
`;

export const actionSave = ['ActionSave', {
  section: 'bottom',
  skip: [
    props => props.data && props.data.status !== 'DRAFT',
  ],
}];

export const actionSaveAndReload = ['ActionSaveAndReload', {
  section: 'bottom',
  skip: [
    props => props.data && props.data.status !== 'DRAFT',
  ],
}];

export const actionSaveContent = ['ActionSaveAndReload', {
  section: 'bottom',
  getRequestConfig: (props, data) => {
    const { viewer: { units } } = props;
    if (data.content) {
      return ActionSave.config.getRequestConfig(props, {
        ...data,
        content: {
          ...data.content,
          products: data.content.products.map(
            product => omit(
              (
                units === 'INCH'
                ? omit(product, 'width', 'height')
                : omit(product, 'widthInch', 'heightInch')
              ),
              'brandEmbed',
              'companyEmbed',
              'categoryEmbed',
            ),
          ),
        },
      });
    }
    return ActionSave.config.getRequestConfig(props, data);
  },
  skip: [
    props => props.data && props.data.status !== 'DRAFT',
  ],
}];

export const actionSaveVariations = ['ActionSaveAndReload', {
  section: 'bottom',
  getRequestConfig: (props, data) => {
    if (data.variations && data.variations.length) {
      return ActionSave.config.getRequestConfig(props, {
        ...data,
        variations: data.variations.map(
          variation => Object.keys(variation).reduce(
            (agr, key) => {
              if (key[0] !== '_') {
                const file = variation[key];
                if (file) {
                  const [targetType, targetInfo] = key.split('-');
                  agr.resources.push({
                    targetType,
                    targetInfo,
                    file,
                  });
                }
              }
              return agr;
            },
            {
              resources: [],
              primary: !!variation._primary,
            },
          ),
        ),
      });
    }
    return ActionSave.config.getRequestConfig(props, data);
  },
  skip: [
    props => props.data && props.data.status !== 'DRAFT',
  ],
}];

export const actionDelete = ['ActionDelete', {
  section: 'bottom',
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdmin,
      isViewerCustomerWhichCanManageOrganization,
      isViewerCustomerWhichCanManageProjects,
      isViewerProjectCreator,
    ]]],
  ],
  skip: [
    props => (
        (props.viewer && props.viewer.role === 'ADMIN')
      ? false
      : (props.data && props.data.status !== 'DRAFT')
    ),
  ],
}];

export const actionSwitchToContentBack = ['Action', {
  name: 'switchToContentBack',
  section: 'bottom',
  ghost: true,
  title: (
    <>
      <ActionIconLeft />
      Content
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/content`,
  ),
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSwitchToVariations = ['Action', {
  name: 'switchToVariations',
  section: 'bottom',
  right: true,
  ghost: true,
  title: (
    <>
      Variations
      <ActionIconRight />
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/variations`,
  ),
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSwitchToVariationsBack = ['Action', {
  name: 'switchToVariationsBack',
  section: 'bottom',
  ghost: true,
  title: (
    <>
      <ActionIconLeft />
      Variations
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/variations`,
  ),
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSwitchToPlanogram = ['Action', {
  name: 'switchToPlanogram',
  section: 'bottom',
  right: true,
  ghost: true,
  title: (
    <>
      Planogram
      <ActionIconRight />
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/planogram`,
  ),
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSwitchToPlanogramBack = ['Action', {
  name: 'switchToPlanogramBack',
  section: 'bottom',
  ghost: true,
  title: (
    <>
      <ActionIconLeft />
      Planogram
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/planogram`,
  ),
}];

export const actionSwitchToFinalize = ['Action', {
  name: 'switchToFinalize',
  section: 'bottom',
  right: true,
  ghost: true,
  title: (
    <>
      Finalize
      <ActionIconRight />
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/finalize`,
  ),
  skip: [props => props.data && props.data.status === 'REPORT'],
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSwitchToReport = ['Action', {
  name: 'switchToReport',
  section: 'bottom',
  right: true,
  ghost: true,
  title: (
    <>
      Report
      <ActionIconRight />
    </>
  ),
  onClick: props => props.history.replace(
    `/projects/${props.data._id}/finalize`,
  ),
  skip: [props => props.data && props.data.status !== 'REPORT'],
  renderAction: renderActionWithSavePromptAndProceed,
}];

export const actionSubmit = ['ActionWithRequest', {
  name: 'submit',
  section: 'bottom',
  right: true,
  title: 'Submit',
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdmin,
      isViewerCustomerWhichCanManageOrganization,
      isViewerCustomerWhichCanManageProjects,
    ]]],
  ],
  getRequestConfig: props => ({
    url: `projects/${props.data._id}/actions/submit`,
    data: {},
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionUnsubmit = ['ActionWithRequest', {
  name: 'unsubmit',
  section: 'bottom',
  ghost: true,
  title: 'Back To Editing',
  skip: [props => props.data && props.data.status !== 'READY'],
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdmin,
      isViewerCustomerWhichCanManageOrganization,
      isViewerCustomerWhichCanManageProjects,
    ]]],
  ],
  getRequestConfig: props => ({
    url: `projects/${props.data._id}/actions/unsubmit`,
    data: {},
    method: 'POST',
  }),
  handleSuccess: (data, props) => props.history.replace(
    `/projects/${props.data._id}/content`,
  ),
}];

export const actionPreapprove = ['ActionWithRequest', {
  name: 'actionPreapprove',
  title: 'Approve Without Payment',
  ghost: true,
  right: true,
  skip: [props => props.data && props.data.status !== 'READY'],
  disabled: [
    ['condition.not', ['condition.or', [
      viewerIsAdmin,
    ]]],
  ],
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/quote-response`,
    data: { preapprove: true },
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionPay = ['ActionStripePay', {
  name: 'actionPay',
  title: 'Process Payment',
}];

export const actionQuoteRequest = ['ActionWithRequest', {
  name: 'actionQuoteRequest',
  title: 'Request Custom Pricing',
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/quote-request`,
    data: {
      message: props.form.getFieldValue('quoteRequestMessage'),
    },
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionQuoteRequestApprove = ['ActionWithFormBasedRequest', {
  name: 'actionQuoteRequestApprove',
  title: 'Approve',
  getRequestConfig: (
    props,
    {
      quoteRequestResponseType: type,
      quoteRequestResponseSamplePerVariation: samplePerVariation,
      quoteRequestResponsePrice: price,
    },
  ) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/quote-response`,
    data: { success: true, type, samplePerVariation, price },
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionQuoteRequestDeny = ['ActionWithPopoverAndRequest', {
  name: 'actionQuoteRequestDeny',
  title: 'Deny',
  renderPopoverContent: (props, action) => (
    <ActionPopconfirmWrapper>
      <Input.TextArea
        id="action-quote-request-deny-message"
        placeholder="Reason"
        autoSize={{ minRows: 2 }}
        autoFocus
      />
      <ActionPopconfirmButton
        onClick={action.handleClick}
        type="primary"
        ghost
      >
        Submit
      </ActionPopconfirmButton>
    </ActionPopconfirmWrapper>
  ),
  getRequestConfig: (props) => {
    const { value: message } = window.document.getElementById(
      'action-quote-request-deny-message',
    );
    return {
      method: 'POST',
      url: `/projects/${props.data._id}/actions/quote-response`,
      data: { success: false, message },
    };
  },
  handleSuccess: (data, props) => props.reload(),
}];

export const actionApprovalApprove = ['ActionWithRequest', {
  name: 'actionApprovalApprove',
  title: 'Approve',
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/approval`,
    data: { success: true },
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionApprovalDeny = ['ActionWithPopoverAndRequest', {
  name: 'actionApprovalDeny',
  title: 'Deny',
  renderPopoverContent: (props, action) => (
    <ActionPopconfirmWrapper>
      <Input.TextArea
        id="action-approval-deny-message"
        placeholder="Reason"
        autoSize={{ minRows: 2 }}
        autoFocus
      />
      <ActionPopconfirmButton
        onClick={action.handleClick}
        type="primary"
        ghost
      >
        Submit
      </ActionPopconfirmButton>
    </ActionPopconfirmWrapper>
  ),
  getRequestConfig: (props) => {
    const { value: message } = window.document.getElementById(
      'action-approval-deny-message',
    );
    return {
      method: 'POST',
      url: `/projects/${props.data._id}/actions/approval`,
      data: { success: false, message },
    };
  },
  handleSuccess: (data, props) => props.reload(),
}];

export const actionGoLive = ['ActionWithFormBasedRequest', {
  name: 'actionGoLive',
  title: 'Go Live',
  getRequestConfig: (
    props,
    { testing: { sample: { variations } = {}, redirect } = {} },
  ) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/live`,
    data: { samplePerVariation: variations, redirect },
  }),
  handleSuccess: (data, props) => props.reload(),
  disabled: [],
}];

export const actionProcessingAnalysisSave = ['ActionWithFormBasedRequest', {
  name: 'actionProcessingAnalysisSave',
  title: 'Save Analysis',
  ghost: true,
  icon: 'save',
  getRequestConfig: (
    props,
    { analysis },
  ) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/analysis`,
    data: { analysis },
  }),
  handleSuccess: (data, props) => props.reload(),
  disabled: [
    (props) => {
      const value = props.form
      ? props.form.getFieldValue('analysis')
      : undefined;
      if ((props.data && props.data.analysis === value)) {
        return true;
      }
      return false;
    },
  ],
}];

export const actionProcessingSessionsAudit = ['ActionProcessingSessionsAudit', {
  name: 'actionProcessingSessionsAudit',
  title: 'Audit Sessions',
  ghost: true,
  icon: 'search',
}];

export const actionProcessingCompileReport = ['ActionProcessingCompileReport', {
  name: 'actionProcessingCompileReport',
  ghost: true,
}];

export const actionProcessingPublishReport = ['ActionWithRequest', {
  name: 'actionProcessingPublishReport',
  title: 'Publish Report',
  ghost: true,
  icon: 'rocket',
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/publish`,
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionUnpublishReport = ['ActionWithRequest', {
  name: 'actionUnpublishReport',
  title: 'Unpublish Report',
  section: 'bottom',
  ghost: true,
  skip: [props => (
       !props.data
    || props.data.status !== 'REPORT'
    || !props.viewer
    || props.viewer.role !== 'ADMIN'
  )],
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/unpublish`,
  }),
  handleSuccess: (data, props) => props.reload(),
}];

export const actionProcessing = ['ActionWithRequest', {
  name: 'actionProcessing',
  title: 'Start Processing',
  ghost: true,
  right: true,
  skip: [
    props => (
         (props.viewer && props.viewer.role !== 'ADMIN')
      || (props.data && props.data.status !== 'LIVE')
    ),
  ],
  getRequestConfig: (props) => ({
    method: 'POST',
    url: `/projects/${props.data._id}/actions/processing`,
    data: { preapprove: true },
  }),
  handleSuccess: (data, props) => props.reload(),
  disabled: [],
  popconfirm: {
    title: 'End gathering shopper data now?',
    okButtonProps: { type: 'danger' },
    okText: 'Confirm',
    cancelButtonProps: { },
    icon: <IconExclamation />,
  },
}];

export const actionDownloadSessions = ['Action', {
  name: 'downloadSessions',
  section: 'bottom',
  right: true,
  ghost: true,
  title: (
    <>
      Download Sessions
      <ActionIconDownload />
    </>
  ),
  onClick: (props) => {
    window.location.href = props.client.prefix(`projects/${
      props.data._id
    }/sessions/download?access_token=${
      props.client.getAccessToken()
    }`);
  },
  skip: [
    ['condition.or', [
      ['condition.not', viewerIsAdmin],
      props => !props.data || !['LIVE', 'PROCESSING', 'REPORT'].includes(
        props.data.status,
      ),
    ]],
  ],
}];
