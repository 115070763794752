import Admin from 'hive-admin/src/components/Admin';
import FieldText from 'hive-admin/src/components/FieldText';

import Types from '../modules/types';

export default class FieldLengthUnit extends FieldText {
  static inputPropsMap = {
    ...FieldText.inputPropsMap,
  }

  renderInput(props, ...args) {
    const addonAfter = (
      this.props.viewer
      && this.props.viewer.units
      ? Types.LENGTH_UNIT_LABELS_MAP[this.props.viewer.units]
      : 'cm'
    );
    return super.renderInput({
      ...props,
      args,
      addonAfter,
    });
  }
}

Admin.addToLibrary(
  'FieldLengthUnit',
  config => FieldLengthUnit.create(config),
);
