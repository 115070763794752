// import getKey from 'lodash/get';

import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isFinite from 'lodash/isFinite';

import React from 'react';
import styled from 'styled-components';

import AntTable from 'antd/lib/table';

import { Tooltip } from '../../Popover';

// import Types from '../../../modules/types';

export function cm2px(cm = 0) {
  return Math.round(cm * 1);
}

export function withPixelValues(value) {
  if (isArray(value)) {
    value.forEach(item => withPixelValues(item));
  } else if (isPlainObject(value)) {
    Object.keys(value).forEach((key) => {
      if (isFinite(value[key])) {
        value[`${key}Px`] = cm2px(value[key]);
      } else {
        withPixelValues(value[key]);
      }
    });
  }
  return value;
}

// export const Bold = styled.span`
//   font-weight: bold;
// `;

// export const Red = styled.span`
//   color: red;
// `;

// export const Green = styled.span`
//   color: green;
// `;

export function TableHTML(props) {
  return (
    <AntTable
      scroll={{ x: 'max-content', y: false }}
      {...props}
    />
  );
}

export const Table = styled(TableHTML)`
  .ant-table-thead > tr > th {
    color: ${({ theme }) => theme.less.textColor};
  }
`;

export const StyledSpan = styled.span.attrs(props => ({
  style: Object.assign(
    {},
    props.weight ? { fontWeight: props.weight } : {},
    props.color ? { color: props.color } : {},
  ),
}))`
`;

export const TableHeaderMajor = styled.span`
  font-size: 18px;
`;

export const TableHeaderMinor = styled.span`
  opacity: 0.6;
`;

export function TableHeader({
  children,
  description,
  tooltipTitle = null,
  tooltipContent = null,
}) {
  const content = (
    <>
      <TableHeaderMajor>{children}</TableHeaderMajor>
      {
        description
        ? (
            <>
              <br />
              <TableHeaderMinor>{description}</TableHeaderMinor>
            </>
          )
        : null
      }
    </>
  );
  if (tooltipTitle || tooltipContent) {
    return (
      <Tooltip title={tooltipTitle} content={tooltipContent}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

export const TableCellMajor = styled.span`
  font-size: 16px;
`;

const TableCellMinorHTML = ({ dimmed, ...props }) => (
  <span {...props} />
);

export const TableCellMinor = styled(TableCellMinorHTML).attrs(props => ({
  style: props.dimmed ? { opacity: 0.2 } : undefined,
}))`
  font-size: 12px;
  opacity: 0.6;
`;

export function TableCell({
  children,
  description,
  descriptionDimmed,
  tooltipTitle = null,
  tooltipContent = null,
}) {
  const content = (
    <span>
      <TableCellMajor>{children}</TableCellMajor>
      {
        description
        ? (
            <>
              <br />
              <TableCellMinor dimmed={descriptionDimmed}>
                {description}
              </TableCellMinor>
            </>
          )
        : null
      }
    </span>
  );
  if (tooltipTitle || tooltipContent) {
    return (
      <Tooltip title={tooltipTitle} content={tooltipContent}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  text-align: center;
  margin-top: 20px;
`;

export const HeaderTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.58);
`;

export const HeaderDescription = styled.div`
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  max-width: 400px;
  color: rgba(0, 0, 0, 0.45);
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
`;

export const HeaderInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 5px;
  min-width: 125px;
  margin-right: 10px;
  margin-top: 15px;
`;

export const HeaderInfoItemLabel = styled.div`
  font-size: 14px;
  text-align: center;
  flex: 1;
  opacity: 0.5;
`;

export const HeaderInfoItemValue = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

// const HeaderInfoSeparator = styled.div`
//   height: 30px;
//   width: 100%;
//   border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
// `;

export function Header({ title, description, info }) {
  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderDescription>
        {description}
      </HeaderDescription>
      {
          info && info.length
        ? (
            <HeaderInfo>
              {info.map(({ label, value }) => (
                <HeaderInfoItem key={label}>
                  <HeaderInfoItemLabel>{label}</HeaderInfoItemLabel>
                  <HeaderInfoItemValue>{value}</HeaderInfoItemValue>
                </HeaderInfoItem>
              ))}
            </HeaderInfo>
          )
        : null
      }
    </HeaderWrapper>
  );
}

// export const COLUMN_VARIATION = {
//   key: 'variation',
//   title: (
//     <TableHeader description="Scenarios Tested">
//       Variation
//     </TableHeader>
//   ),
//   width: '130px',
//   align: 'left',
//   render: (text, record, index) => (
//     <TableHeader
//       description={`${
//         getKey(record, 'stat.project.sample.total', 0)
//       } Shoppers`}
//     >
//       {
//         index === 0
//         ? 'Control'
//         : `${Types.VARIATION_LETTERS[record.variation]}`
//       }
//     </TableHeader>
//   ),
// };
