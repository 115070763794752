// import React, { useEffect } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { useProduct } from '../../Context';

import GroupSettings from './GroupSettings';

import Types from '../../../../../modules/types';

const TRANSITION = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

export const GroupWrapperHTML = ({
  draggable,
  innerRef,
  index,
  dragging,
  shelfId,
  segmentId,
  padding,
  layoutId,
  layoutStyle,
  ...props
}) => (
  draggable
  ? (
      <motion.div
        ref={innerRef}
        transition={TRANSITION}
        data-index={index || 0}
        data-dragging={dragging}
        dragging={dragging}
        drag
        layout
        layoutId={layoutId}
        style={layoutStyle}
        {...props}
      />
    )
  : (
      <div
        ref={innerRef}
        {...props}
      />
    )
);

GroupWrapperHTML.defaultProps = {
  draggable: true,
};

const GroupWrapper = styled(GroupWrapperHTML)`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: opacity 200ms;
  pointer-events: initial !important;
  ${({ dragging }) => dragging && css`
    /* pointer-events: none !important; */
    z-index: 999999;
  `}
  ${({ draggable }) => draggable && css`
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  `}
`;

const GroupWrapperHorizontal = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px ${({ padding }) => padding}px;
`;

const ProductImageHTML = ({ image, width, height, margin, ...props }) => (
  <div {...props} />
);

const ProductImage = styled(ProductImageHTML)`
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  ${({ width, height, margin }) => css`
    width: ${width}px;
    height: ${height}px;
    &:not(:first-child) {
      margin-left: ${margin}px;
    }
  `}
  ${({ image }) => (
    image
    ? css`
        background-image: url("${image}");
      `
    : css`
        background-color: #ededed;
      `
  )}
`;

const GroupPriceTagHTML = ({ height, ...props }) => (
  <div {...props} />
);

const GroupPriceTag = styled(GroupPriceTagHTML)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 0px;
  ${({ height }) => css`
    height: ${height}px;
    bottom: ${height * -1}px;
    [data-last="true"] & {
      bottom: 0px;
    }
  `}
`;

const GroupPriceHTML = ({ color, ...props }) => (
  <div {...props} />
);

const GroupPrice = styled(GroupPriceHTML)`
  display: flex;
  font-size: 10px;
  font-weight: 500;
  transform: translateY(1px);
  /* background: #fff; */
  /* background: yellow; */
  background: ${({ color }) => (
    Types.PRICE_TAG_COLOR_MAP[color]?.color
    || color
  )};
  padding: 1px 3px 0px;
  border: 1px solid #ddd;
  color: black;
  [data-last="true"] & {
    padding: 0px 3px;
  }
`;

export default function Group({
  id,
  product: productInput,
  currency,
  span = 1,
  spanY = 1,
  shelf,
  shelfId,
  segmentId,
  priceTagColor,
  priceTagColorDefault,
  onAction,
  dragging,
  draggable,
  disabled,
  organization,
  getBrand,
  getCategory,
  target,
  ...props
}) {
  const product = useProduct(productInput);
  const {
    scale,
    map: {
      [shelfId]: {
        type: {
          size: {
            priceHeightPx,
            gutterProductPx,
            gutterGroupPx,
          },
        },
      },
    },
  } = Types.PLANOGRAM;
  const image = (
      product.imageShopper
    ? product.imageShopper.src
    : product.image
    ? product.image.src
    : null
  );
  const width = product.width * scale;
  const height = product.height * scale;
  return (
    <GroupSettings
      id={id}
      segmentId={segmentId}
      priceTagColor={priceTagColor}
      onAction={onAction}
      dragging={dragging}
      product={product}
      currency={currency}
      disabled={disabled}
      organization={organization}
      getBrand={getBrand}
      getCategory={getCategory}
      showPriceTagColor={target === 'project'}
      offset={[0, 10]}
    >
      <GroupWrapper
        layoutId={id}
        dragging={dragging}
        draggable={!disabled && draggable}
        {...props}
      >
        {
          new Array(spanY).fill(null).map((_v1, iY) => (
            <GroupWrapperHorizontal
              // eslint-disable-next-line react/no-array-index-key
              key={iY}
              padding={gutterGroupPx / 2}
            >
              {
                new Array(span).fill(null).map((_v2, iX) => (
                  <ProductImage
                    // eslint-disable-next-line react/no-array-index-key
                    key={iX}
                    image={image}
                    width={width}
                    height={height}
                    margin={gutterProductPx}
                  />
                ))
              }
            </GroupWrapperHorizontal>
          ))
        }
        <GroupPriceTag height={priceHeightPx}>
          <GroupPrice
            color={priceTagColor || priceTagColorDefault}
          >
            {product.price.toFixed(2)}
          </GroupPrice>
        </GroupPriceTag>
      </GroupWrapper>
    </GroupSettings>
  );
}

Group.defaultProps = {
  draggable: true,
};
