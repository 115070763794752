/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import styled, { css, createGlobalStyle, keyframes } from 'styled-components';

import omit from 'lodash/omit';
import isArray from 'lodash/isArray';

import Admin from 'hive-admin';
import Field from 'hive-admin/src/components/Field';

import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Switch from 'antd/lib/switch';
import AntdEmpty from 'antd/lib/empty';
import AntdForm from 'antd/lib/form';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
// eslint-disable-next-line import/no-extraneous-dependencies
import arrayMove from 'array-move';

import { Tooltip } from './Popover';

import Form from './Form';

import './FieldSortableList.less';

import Types from '../modules/types';

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

let SORT_ID_COUNTER = 1;

const PrimaryVariationGlobalStyle = createGlobalStyle`
  ${({ variation, index }) => css`
    .field-sortable-container > div:not(:nth-child(${index + 1})) {
      ${css`${
        Object.keys(variation).reduce(
          (agr, key) => {
            if (variation[key] && key[0] !== '_') {
              agr.push(`
                .variation-resource-${key} .file-upload-refs-add-wrapper {
                  &:before {
                    content: "";
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    right: 8px;
                    bottom: 8px;
                    background-image: url("${variation[key].src}");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    pointer-events: none;
                    opacity: 0.35;
                  }
                  > .file-upload-refs-add-button {
                    > i, span {
                      background-color: #fafafa;
                      box-shadow: 0px 0px 0px 6px #fafafa;
                    }
                  }
                }
              `);
            }
            return agr;
          },
          [],
        ).join('\n')
      }`}
    }
  `}
`;

const Empty = styled(AntdEmpty)`
  display: flex;
  align-items: center;
  .ant-empty-image {
    margin: 0px;
  }
  .ant-empty-description {
    text-align: left;
    margin-left: 20px;
  }
`;

const InputWrapperHTML = ({ disabled, ...props }) => (
  <span {...props} />
);

const InputWrapper = styled(InputWrapperHTML)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.3;
  `}
  .ant-upload {
    position: relative !important;
  }
`;

const FieldSortableListItemWrapper = styled.div`
  position: relative;
  padding-bottom: 0px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: white;
  animation: ${FadeInAnimation} 600ms linear 0ms normal forwards;
`;

const FieldSortableListItemBody = styled.div`
  position: relative;
  padding: 20px;
  padding-left: 55px;
  > .ant-form > .ant-row:last-child {
    margin-bottom: 0px;
  }
  > .ant-form .ant-form-item .ant-form-item-label label {
    text-align: center !important;
    width: 100% !important;
  }
`;

const FieldSortableListItemBodyTitle = styled.div`
  display: flex;
  margin-top: -4px;
  align-items: center;
  cursor: pointer;
`;

const FieldSortableListItemBodyTitleText = styled.div`
  display: flex;
  font-size: 16px;
  padding-right: 15px;
`;

const FieldSortableListItemAction = styled(Icon)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const FieldSortableListItemHandleLine = styled.div`
  display: flex;
  border-left: 1px solid #ddd;
  align-self: stretch;
`;

const FieldSortableListItemHandle = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  cursor: grab;
`;

const FieldSortableListItemHead = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 20px;
  bottom: 20px;
  left: 20px;
  width: 15px;
`;

export class FieldSortableListVariationsItem extends Component {
  constructor(props) {
    super(props);
    this.Wrapped = AntdForm.create({
      onFieldsChange: (_, changedFields, allFields) => {
        this.props.onChange(
          this.props.index,
          {
            ...this.props.value,
            ...Object.keys(allFields).reduce(
              (agr, key) => {
                agr[key] = allFields[key].value;
                return agr;
              },
              {},
            ),
          },
        );
      },
    })(this.props.Component);
  }

  render() {
    const {
      Component: _,
      form,
      onChange,
      value,
      index,
      onRemove,
      onDuplicate,
      onPrimaryToggle,
      renderHandle,
      wrapperStyle,
      className,
      prepareValueForInput,
      prepareValueForForm,
      ...props
    } = this.props;
    const { Wrapped } = this;
    return (
      <FieldSortableListItemWrapper
        style={wrapperStyle}
        className={className}
      >
        <FieldSortableListItemBody>
          <FieldSortableListItemBodyTitle
            onClick={() => onPrimaryToggle(index)}
          >
            <FieldSortableListItemBodyTitleText>
              {`Variation ${Types.VARIATION_LETTERS[index]}`}
            </FieldSortableListItemBodyTitleText>
            <Tooltip content="Control Group">
              <Switch
                size="small"
                checked={!!value._primary}
              />
            </Tooltip>
          </FieldSortableListItemBodyTitle>
          {
            this.props.fields && this.props.fields.length
            ? (
                <Wrapped
                  {...props}
                  index={index}
                  data={value}
                  parentData={this.props.data}
                />
              )
            : (
                <Empty
                  image={AntdEmpty.PRESENTED_IMAGE_SIMPLE}
                  description={(
                    <>
                      Project has no testable content to set
                      <br />
                      the resources for.
                    </>
                  )}
                />
              )
          }
        </FieldSortableListItemBody>
        <FieldSortableListItemHead>
          <FieldSortableListItemAction
            type="delete"
            onClick={() => onRemove(index)}
          />
          {
            onDuplicate
            ? (
                <FieldSortableListItemAction
                  type="copy"
                  onClick={() => onDuplicate(index)}
                />
              )
            : null
          }
          {renderHandle(value)}
        </FieldSortableListItemHead>
      </FieldSortableListItemWrapper>
    );
  }
}

export default class FieldSortableListVariations extends Field {
  static config = {
    ...Field.config,

    maxCount: 10,
    shouldRenderAddButton: ({ value = [], disabled, maxCount }) => (
      disabled
      ? false
      : (
          isArray(value)
          ? value
          : value
          ? [value]
          : []
        ).filter(item => !!item).length < maxCount
      ? true
      : false
    ),

    useDragHandle: true,
    generateNewItem: (/* props, self */) => ({}),
    renderAddButton: (props, self) => (
      <Button
        icon="plus"
        onClick={() => self.addItem(props.generateNewItem(props, self))}
      >
        Add
      </Button>
    ),
    renderItemName: item => ((item && item.name) ? item.name : ''),

    fields: [],
    excludeFields: [],
    getFields: props => props.fields.filter(
      field => props.excludeFields.indexOf(field.name) === -1
    ),
    getRuntimeFields: props => props.fields || [],

    supportDuplicate: false,

    FormComponent: Form,
  }

  static inputPropsMap = {
    ...Field.inputPropsMap,
    pageform: true,
  }

  static create(config) {
    const staticConfig = super.create(config);
    return {
      ...staticConfig,
      render: props => (
        <this
          {...this.getProps(staticConfig, config, props)}
          fields={staticConfig.fields}
          key={staticConfig.name}
          disabled={staticConfig.isDisabled({ ...staticConfig, ...props })}
        />
      ),
    };
  }

  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.fields = Admin.compileFromLibrary(
      this.props.getRuntimeFields(this.props),
      true,
    );
    this.SortableItem = sortableElement(this.renderItem);
    this.SortableContainer = sortableContainer(this.renderContainer);
    this.SortableHandle = sortableHandle(this.renderHandle);
  }

  addItem = (item = {}) => {
    const value = (
      isArray(this.props.value)
      ? this.props.value
      : []
    );
    const currentPrimaryIndex = value.findIndex(({ _primary }) => _primary);
    this.props.onChange([
      ...value,
      { ...item, _primary: currentPrimaryIndex === -1 },
    ]);
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.props.onChange(
        isArray(this.props.value)
        ? arrayMove(this.props.value, oldIndex, newIndex)
        : []
      );
    }
  }

  renderHandle = ({ value: item }) => (
    this.props.renderHandle
    ? this.props.renderHandle(item, this.props, this)
    : (
        <FieldSortableListItemHandle>
          <FieldSortableListItemHandleLine />
          <FieldSortableListItemHandleLine />
          <FieldSortableListItemHandleLine />
        </FieldSortableListItemHandle>
      )
  )

  renderSortableHandle = (item) => {
    const { SortableHandle } = this;
    return <SortableHandle value={item} />;
  }

  renderAddButton() {
    return this.props.renderAddButton(this.props, this);
  }

  renderItem = ({ value, itemIndex: index, ...rest }) => (
      this.props.renderItem
    ? this.props.renderItem(value, index, { ...this.props, ...rest }, this)
    : (
        <FieldSortableListVariationsItem
          {...rest}
          {...this.props}
          fields={this.fields}
          value={value}
          index={index}
          Component={this.props.FormComponent}
          onChange={this.handleChange}
          onRemove={this.removeItem}
          onDuplicate={this.props.supportDuplicate ? this.duplicateItem : null}
          onPrimaryToggle={this.handlePrimaryToggle}
          renderHandle={this.renderSortableHandle}
          wrapperStyle={this.props.sortableListItemWrapperStyle}
          className={this.props.sortableListItemWrapperClassName}
        />
      )
  )

  renderSortableItem = (item, index, props) => {
    const { SortableItem } = this;
    if (!item.__SORT_ID) {
      item.__SORT_ID = SORT_ID_COUNTER++;
    }
    return (
      <SortableItem
        key={item.__SORT_ID}
        {...props}
        index={index}
        itemIndex={index}
        value={item}
      />
    );
  }

  renderContainer = ({ children }) => (
    this.props.renderContainer
    ? this.props.renderContainer(children, this.props, this)
    : (
        <div
          style={this.props.sortableContainerStyle || {}}
          className="field-sortable-container"
        >
          {children}
        </div>
      )
  )

  removeItem = (index) => {
    const value = (
      isArray(this.props.value)
      ? this.props.value
      : []
    );
    if (index < value.length) {
      const currentPrimaryIndex = value.findIndex(({ _primary }) => _primary);
      const newPrimaryIndex = currentPrimaryIndex === index ? 0 : -1;
      this.props.onChange(
        this.props.prepareValueForForm(
          value.reduce(
            (agr, item, itemIndex) => {
              if (itemIndex !== index) {
                if (itemIndex === newPrimaryIndex) {
                  agr.push({ ...item, _primary: true });
                } else {
                  agr.push(item);
                }
              }
              return agr;
            },
            [],
          ),
          this.props,
        ),
      );
    }
  }

  duplicateItem = (index) => {
    this.props.onChange(
      this.props.prepareValueForForm(
        isArray(this.props.value)
        ? this.props.value.reduce(
            (agr, item, itemIndex) => {
              agr.push(item);
              if (itemIndex === index) {
                agr.push({
                  ...this.props.generateNewItem(this.props, this),
                  ...omit(item, '_id', '_primary', '__SORT_ID'),
                });
              }
              return agr;
            },
            [],
          )
        : []
      ),
    );
  }

  handleChange = (index, value) => {
    this.props.onChange(
      this.props.prepareValueForForm(
        isArray(this.props.value)
        ? this.props.value.map((item, itemIndex) => (
            itemIndex === index
            ? { ...item, ...value }
            : item
          ))
        : [value],
        this.props,
      ),
    );
  }

  handlePrimaryToggle = (index) => {
    const value = (
      isArray(this.props.value)
      ? this.props.value
      : []
    );
    if (index < value.length) {
      const currentPrimaryIndex = value.findIndex(({ _primary }) => _primary);
      if (currentPrimaryIndex !== index) {
        this.props.onChange(
          this.props.prepareValueForForm(
            value.map((item, itemIndex) => (
                itemIndex === currentPrimaryIndex
              ? { ...item, _primary: false }
              : itemIndex === index
              ? { ...item, _primary: true }
              : item
            )),
            this.props,
          ),
        );
      }
    }
  }

  renderInput(props) {
    const { SortableContainer } = this;
    const value = (
      isArray(this.props.value)
      ? this.props.value
      : []
    );
    const primaryIndex = value.findIndex(({ _primary }) => _primary);
    const primary = primaryIndex > -1 ? value[primaryIndex] : null;
    return (
      <InputWrapper
        data-input-id={this.props.inputId || undefined}
        disabled={this.props.disabled}
      >
        {
          primary
          ? (
              <PrimaryVariationGlobalStyle
                variation={primary}
                index={primaryIndex}
              />
            )
          : null
        }
        <SortableContainer
          {...props}
          onSortEnd={this.handleSortEnd}
          useDragHandle={this.props.useDragHandle}
          lockAxis={this.props.lockAxis || 'y'}
        >
          {
            isArray(this.props.value)
            ? this.props.value.map((item, index) => this.renderSortableItem(
                item,
                index,
                { ...props, pageform: this.props.form },
              ))
            : null
          }
        </SortableContainer>
        {
          this.props.shouldRenderAddButton(this.props)
          ? this.renderAddButton()
          : null
        }
      </InputWrapper>
    );
  }
}

Admin.addToLibrary(
  'FieldSortableListVariations',
  config => FieldSortableListVariations.create(config || {}),
);
