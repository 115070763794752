import isUndefined from 'lodash/isUndefined';

import React from 'react';
import styled from 'styled-components';

import {
  UserOutlined,
} from '@ant-design/icons';

import Types from '../modules/types';

import tests from '../helpers/tests';

const { viewerIsNotAdmin } = tests;

const PricingPlanTitle = styled.h2`
  font-weight: bold;
  color: ${({ theme }) => theme.less.textColor};
  margin-bottom: 25px;
`;

export default [
  'PageSingle',
  {
    icon: 'tool',
    path: '/settings',
    title: 'Settings',
    headerTitle: 'Settings',
    topTitleKey: null,
    hidden: false,
    activity: 'edit',
    url: '/settings/general',
    redirect: [['redirect.unauthorized']],
    actions: [['ActionSave']],
    skip: [viewerIsNotAdmin],
    fields: [
      ...Types.PROJECT_PAYMENT_PLAN_DEFINITION.reduce(
        (agr, { name, label, custom: isCustom }) => {
          if (!isCustom) {
            agr.push(['FieldReact', {
              isDivider: true,
              renderContent: () => (
                <PricingPlanTitle>
                  {`Plan: ${label}`}
                </PricingPlanTitle>
              ),
            }]);
            agr.push(['FieldText', {
              name: `plans.${name}.pricePerVariation`,
              label: 'Price / Variation',
              align: 'right',
              type: 'number',
              addonAfter: '$',
              placeholder: '0',
              rules: [
                ['validation.isRequired'],
                ['validation.isNumber'],
              ],
              col: { xs: 24, md: 12 },
            }]);
            agr.push(['FieldText', {
              name: `plans.${name}.samplePerVariation`,
              label: 'Shoppers / Variation',
              align: 'right',
              type: 'number',
              placeholder: '0',
              addonAfter: <UserOutlined />,
              rules: [
                ['validation.isRequired'],
                ['validation.isNumber'],
              ],
              col: { xs: 24, md: 12 },
            }]);
          }
          return agr;
        },
        [],
      ),
      ['FieldSwitch', {
        name: 'signup.open',
        label: 'Allow Signups?',
      }],
      ['FieldTextArea', {
        name: 'signup.blacklist',
        label: 'Blacklisted Signup Domains',
        placeholder: 'gmail.com, yahoo.com, etc.',
        autoSize: { minRows: 2 },
        prepareValueForForm: event => (
          isUndefined(event.target.value) || !event.target.value
          ? ' '
          : event.target.value
        ),
        prepareValueForInput: value => (
          value === ' '
          ? undefined
          : value
        ),
      }],
    ],
  },
];
