/* eslint-disable react/no-unused-state */

import React, { Component, createContext, useContext } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { TabLocal } from './Tab';

export const Context = createContext();

export class Provider extends Component {
  static defaultProps = {
    max: 10,
  }

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      addProduct: this.addProduct,
      removeProduct: this.removeProduct,
    };
  }

  addProduct = (product) => {
    this.setState(state => ({
      ...state,
      products: [
        product,
        ...state.products.filter(({ _id }) => product._id !== _id),
      ].slice(-this.props.max),
    }));
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default function TabRemoved(props) {
  const { products } = useContext(Context);
  return (
    <TabLocal
      products={products}
      emptyDescription={(
        <>
          No Removed Products
          <br />
          Found
        </>
      )}
      {...props}
    />
  );
}

export const CONFIG = {
  id: 'removed',
  label: 'Removed',
  ComponentIcon: DeleteOutlined,
  Component: TabRemoved,
};
