import {
  columnCreatedAt as columnCreatedAtCommon,
} from '../common';

export const columnName = { path: 'name', link: '/organizations/:_id' };

export const columnCreatedAt = {
  ...columnCreatedAtCommon,
  title: 'Joined',
};

export const ALL = [
  columnName,
  columnCreatedAt,
];
