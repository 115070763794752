/* global Tawk_API */
/* eslint-disable camelcase */

import React, { useRef, useEffect } from 'react';

import tawkTo from 'tawkto-react';
import DefaultAdmin from 'hive-admin';

const {
  REACT_APP_TAWK_TO_PROPERTY_ID,
  REACT_APP_TAWK_TO_KEY,
} = process.env;

function TawkToWrapper({ viewer }) {
  const tawkToInitRef = useRef(false);
  useEffect(
    () => {
      if (viewer) {
        if (!tawkToInitRef.current) {
          tawkTo(REACT_APP_TAWK_TO_PROPERTY_ID, REACT_APP_TAWK_TO_KEY);
          tawkToInitRef.current = true;
        }
        if (typeof Tawk_API !== 'undefined') {
          if (Tawk_API.setAttributes) {
            Tawk_API.setAttributes({
              name: viewer.name,
              email: viewer.email,
            });
          } else {
            Tawk_API.visitor = {
              name: viewer.name,
              email: viewer.email,
            };
          }
          if (Tawk_API.showWidget) {
            Tawk_API.showWidget();
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof Tawk_API !== 'undefined') {
          if (Tawk_API.hideWidget) {
            Tawk_API.hideWidget();
          }
        }
      }
    },
    [viewer],
  );
  return null;
}

export default class Admin extends DefaultAdmin {
  renderContent(extraProps = {}) {
    return (
      <>
        <TawkToWrapper viewer={this.props.viewer} />
        {super.renderContent(extraProps)}
      </>
    );
  }
}
