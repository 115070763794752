import React from 'react';

import { Link } from 'hive-admin/node_modules/react-router-dom';

import Types from '../../modules/types';

import {
  columnCreatedAt as columnCreatedAtCommon,
  columnUpdatedAt as columnUpdatedAtCommon,
} from '../common';

export const columnName = {
  path: 'name',
  render: record => (
    <Link
      to={
          record.status === 'DRAFT'
        ? `/projects/${record._id}/content`
        : record.status === 'REPORT'
        ? `/projects/${record._id}/report`
        : `/projects/${record._id}/finalize`
      }
    >
      {record.name}
    </Link>
  ),
};

export const columnStatus = {
  path: 'status',
  render: record => `${Types.PROJECT_STATUS_LABELS_MAP[record.status]}`,
};

export const columnRegion = {
  path: 'region',
  title: 'Region',
  render: record => (
    record && record.region && record.region.name
    ? record.region.name
    : '-'
  ),
};

export const columnCreatedBy = {
  path: 'createdBy.name',
  link: '/users/:createdBy._id',
  title: 'Created By',
  isAvailable: ({ viewer }) => (
    viewer
    && (
      viewer.role === 'ADMIN'
      || (
        viewer.role === 'CUSTOMER'
        && (
          viewer.permissions.manageOrganization
          || viewer.permissions.manageProjects
        )
      )
    )
  ),
};

export const columnCreatedAt = {
  ...columnCreatedAtCommon,
  title: 'Created At',
};

export const columnUpdatedAt = {
  ...columnUpdatedAtCommon,
  title: 'Last Activity',
};

export const columnOrganization = {
  path: 'organization.name',
  title: 'Organization',
  link: '/organizations/:organization._id',
  isAvailable: ({ viewer }) => (
    viewer
    && viewer.role === 'ADMIN'
  ),
};

export const ALL = [
  columnName,
  columnStatus,
  columnRegion,
  columnCreatedBy,
  columnCreatedAt,
  columnUpdatedAt,
  columnOrganization,
];
