import React from 'react';
import styled from 'styled-components';

// import {
//   columnCreatedAt as columnCreatedAtCommon,
// } from '../common';

import Types from '../../modules/types';

const SingleLine = styled.span`
  white-space: nowrap;
`;

export const columnName = {
  path: 'name',
  link: '/regions/:_id',
  width: '100%',
};

export const columnCurrency = {
  path: 'currency',
};

export const columnCurrencyPosition = {
  path: 'currencyPosition',
  title: <SingleLine>Currency Position</SingleLine>,
  render: record => (
    <SingleLine>
      {Types.CURRENCY_POSITION_LABELS_MAP[record.currencyPosition]}
    </SingleLine>
  ),
};

export const ALL = [
  columnName,
  columnCurrency,
  columnCurrencyPosition,
];
