import React, {
  Component,
  forwardRef,
  useContext,
  useState,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';

import Group from './Group';

import POSMaterialPosition, {
  Icon as POSMaterialPositionIcon,
} from './POSMaterialPosition';

import {
  List as DNDList,
  getListItems,
} from '../../DND';

import { Context as TabRemovedContext } from '../../Sidebar/TabRemoved';

import Types from '../../../../../modules/types';

import {
  SHELF_PADDING_X,
  SHELF_PADDING_Y,
  SHELF_HEADER_HEIGHT,
} from '../../../config';

import Toolbar from './Toolbar';

const shadow = '0 0 30px rgba(0, 0, 0, 0.08)';

const WrapperHTML = ({
  width,
  height,
  active,
  ...props
}) => (
  <div {...props} />
);

const Wrapper = styled(WrapperHTML)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: visible;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms linear;
  ${({ active }) => !active && css`
    opacity: 0.4;
  `}
  &:hover {
    opacity: 1;
  }
`;

const HeaderHTML = ({ width, ...props }) => (
  <div {...props} />
);

const Header = styled(HeaderHTML)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width}px;
  height:${({ height }) => height}px;
  padding-left: 20px;
  padding-right: 10px;
`;

const Title = styled.div`
  display: flex;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
`;

const BodyHTML = ({
  width,
  height,
  active,
  isFirst,
  isLast,
  inactive,
  ...props
}) => (
  <div {...props} />
);

const Body = styled(BodyHTML)`
  display: flex;
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: visible;
  box-shadow: ${shadow};
  border: 1px solid transparent;
  ${({ openAir }) => !openAir && css`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  `}
  ${({ inactive }) => inactive && css`
    pointer-events: none !important;
    * {
      pointer-events: none !important;
    }
  `}
`;

const SegmentsHTML = ({ top, bottom, ...props }) => (
  <div {...props} />
);

const Segments = styled(SegmentsHTML)`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding-top: ${({ top }) => `${top}px`};
  padding-bottom: ${({ bottom }) => `${bottom}px`};
  overflow-x: visible;
  overflow-y: visible;
`;

const SegmentHTML = ({ paddingTop, ...props }) => (
  <div {...props} />
);

const Segment = styled(SegmentHTML)`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* align-items: flex-end; */
  justify-content: flex-end;
  font-size: 20px;
  width: 100%;
  /* pointer-events: none; */
  & > * {
    /* pointer-events: initial; */
  }
`;

const GroupsHTML = ({
  innerRef,
  dragging,
  droppable,
  alignment,
  count,
  top,
  ...props
}) => (
  <div
    ref={innerRef}
    data-dragging={dragging}
    data-droppable={droppable}
    {...props}
  />
);

GroupsHTML.defaultProps = {
  dragging: false,
  droppable: true,
};

const GroupsStyled = styled(GroupsHTML)`
  position: absolute;
  /* top: ${({ top }) => `${top}px`}; */
  top: 0px;
  bottom: 0px;
  padding-top: ${({ top }) => `${top}px`};
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ alignment }) => alignment};
  transition: background 200ms;
  &[data-dragging="true"] {
    > *:not([data-dragging="true"]) {
      pointer-events: none !important;
    }
  }
  &[data-droppable="false"] {
    background: linear-gradient(
      180deg,
      rgba(255,166,166,0) 0%,
      rgba(255,166,166,0) 50%,
      rgb(253 59 59 / 14%) 100%
    );
    > * {
      opacity: 0;
    }
  }
`;

const Groups = forwardRef((
  {
    count,
    alignment,
    isLast = false,
    ...props
  },
  ref,
) => (
  <GroupsStyled
    innerRef={ref}
    data-last={!!isLast}
    {...props}
    count={count}
    alignment={
      count <= 1
      ? 'center'
      : alignment === 'EVEN'
      ? 'space-evenly'
      : alignment === 'JUSTIFY'
      ? 'space-between'
      : 'center'
    }
  />
));

const SegmentPricingHTML = ({ height, ...props }) => (
  <div {...props} />
);

const MaskWrapper = styled.div`
  position: absolute;
  display: flex;
  pointer-events: none;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: ${({ bottom }) => (bottom ? `${bottom - 1}px` : '-1px')};
  z-index: 2;
`;

const MaskFooterWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  bottom: ${({ height }) => `-${height - 1}px`};
  height: ${({ height }) => `${height - 1}px`};
  left: 13px;
  right: 13px;
  border-top: 1px solid ${({ theme }) => theme.less.borderColor};
  background: #fff;
`;

const FridgeFooterLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.less.borderColor};
`;

const MaskOutter = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
`;

const FridgeWingMiddle = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border: 12px solid #fff;
`;

const FridgeWingInner = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  box-shadow: 0 0 30px inset rgba(0, 0, 0, 0.08);
`;

const FridgeWingSpacer = styled.div`
  width: 1px;
`;

const FridgeOpenAirSide = styled.div`
  position: relative;
  width: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

const FridgeOpenAirSideEdge = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.less.borderColor};
  }
  &:nth-child(2) {
    background: linear-gradient(
      0deg,
      rgba(238,238,238,0) 0%,
      rgba(238,238,238,1) 100%
    );
  }
  &:nth-child(4) {
    border-top: 0px;
    background: linear-gradient(
      0deg,
      rgba(238,238,238,1) 0%,
      rgba(238,238,238,0) 100%
    );
  }
`;

const FridgeOpenAirSideMiddle = styled.div`
  display: flex;
  flex: 1;
  // border-top: 1px solid ${({ theme }) => theme.less.borderColor};
  // background: #eee;
`;

const FridgeOpenAirSpacer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  border-top: 0px;
  border-bottom: 0px;
  box-shadow: 0 0 30px inset rgba(0, 0, 0, 0.08);
`;

const FridgeOpenAirSpacerTop = styled.div`
  position: absolute;
  top: 0px;
  left: -1px;
  right: -1px;
  height: 12px;
  background: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
`;

function Mask({ fridge, openAir, footerPx }) {
  return (
      !fridge
    ? (
        <MaskWrapper openAir bottom={footerPx}>
          <MaskOutter
            style={{
              border: '0px',
            }}
          />
          <MaskFooterWrapper
            height={footerPx}
            style={{
              left: '1px',
              right: '1px',
            }}
          />
        </MaskWrapper>
      )
    : openAir
    ? (
        <MaskWrapper openAir bottom={footerPx}>
          <MaskOutter
            style={{ borderBottom: '0px' }}
          >
            <FridgeOpenAirSide>
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideMiddle />
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideEdge height={footerPx} />
            </FridgeOpenAirSide>
            <FridgeOpenAirSpacer>
              <FridgeOpenAirSpacerTop />
            </FridgeOpenAirSpacer>
            <FridgeOpenAirSide>
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideMiddle />
              <FridgeOpenAirSideEdge height={footerPx} />
              <FridgeOpenAirSideEdge height={footerPx} />
            </FridgeOpenAirSide>
          </MaskOutter>
          <MaskFooterWrapper height={footerPx}>
            <FridgeFooterLine />
            <FridgeFooterLine />
            <FridgeFooterLine />
          </MaskFooterWrapper>
        </MaskWrapper>
      )
    : (
        <MaskWrapper bottom={footerPx}>
          <MaskOutter>
            <FridgeWingMiddle>
              <FridgeWingInner />
            </FridgeWingMiddle>
          </MaskOutter>
          <FridgeWingSpacer />
          <MaskOutter>
            <FridgeWingMiddle>
              <FridgeWingInner />
            </FridgeWingMiddle>
          </MaskOutter>
          <MaskFooterWrapper
            height={footerPx}
            style={{ left: '1px', right: '1px', borderTop: '0px' }}
          >
            <FridgeFooterLine style={{ width: 'calc(100% - 24px)' }} />
            <FridgeFooterLine style={{ width: 'calc(100% - 24px)' }} />
            <FridgeFooterLine style={{ width: 'calc(100% - 24px)' }} />
          </MaskFooterWrapper>
        </MaskWrapper>
      )
  );
}

const SegmentToolbarWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  transform: translateX(-100%);
  padding: 5px;
  padding-bottom: 0px;
  ${POSMaterialPositionIcon} {
    padding: 5px;
    font-size: 14px;
  }
`;

function SegmentToolbar({
  id,
  segmentIndex,
  value: shelfs,
  onChange: onChangeShelfs,
  valueShelf,
  onChangeShelf,
  target,
  client,
  region,
  organization,
  disabled,
}) {
  const [openItemId, setOpenItemId] = useState(null);
  const { map: { [id]: { type: { segment: { posMaterialPositions } } } } } = (
    Types.PLANOGRAM
  );
  const setClosedItemId = (itemId) => {
    if (itemId === openItemId) {
      setOpenItemId(null);
    }
  };
  const [
    value,
    onChange,
    onApplyInDirection,
  ] = useMemo(
    () => [
      valueShelf.segments[segmentIndex],
      (shelfId, newSegment) => onChangeShelf(shelfId, {
        ...valueShelf,
        segments: valueShelf.segments.map((segment, i) => (
            i === segmentIndex
          ? { ...segment, ...newSegment }
          : segment
        )),
      }),
      (direction, position, posMaterialId) => {
        switch (direction) {
          case 'HORIZONTAL': {
            const { section } = Types.PLANOGRAM.map[id];
            const newShelfs = { ...shelfs };
            section.list.forEach((shelfId) => {
              if (
                newShelfs[shelfId]
                && newShelfs[shelfId].segments
                && newShelfs[shelfId].segments[segmentIndex]
                && (
                  !newShelfs[shelfId].segments[segmentIndex].posMaterials
                  // eslint-disable-next-line max-len
                  || newShelfs[shelfId].segments[segmentIndex].posMaterials[position] !== posMaterialId
                )
              ) {
                newShelfs[shelfId] = {
                  ...newShelfs[shelfId],
                  segments: newShelfs[shelfId].segments.map((segment, i) => {
                    if (i === segmentIndex) {
                      return {
                        ...segment,
                        posMaterials: {
                          ...(segment.posMaterials || {}),
                          [position]: posMaterialId,
                        },
                      };
                    }
                    return segment;
                  }),
                };
              }
            });
            onChangeShelfs(newShelfs);
          }
          break;
          case 'VERTICAL': {
            const newShelf = {
              ...valueShelf,
              segments: valueShelf.segments.map((segment) => {
                if (
                  !segment.posMaterials
                  || segment.posMaterials[position] !== posMaterialId
                ) {
                  return {
                    ...segment,
                    posMaterials: {
                      ...(segment.posMaterials || {}),
                      [position]: posMaterialId,
                    },
                  };
                }
                return segment;
              }),
            };
            onChangeShelf(id, newShelf);
          }
          break;
          default:
        }
      },
    ],
    [
      id,
      shelfs,
      onChangeShelf,
      onChangeShelfs,
      valueShelf,
      segmentIndex,
    ],
  );
  return (
    <SegmentToolbarWrapper>
      {
        target === 'project' && posMaterialPositions.length
        ? posMaterialPositions.map(position => (
            <POSMaterialPosition
              key={position}
              id={id}
              icon={Types.POS_MATERIAL_POSITION_MAP[position].icon}
              client={client}
              position={position}
              value={value}
              onChange={onChange}
              onApplyInDirection={onApplyInDirection}
              toolbarItemId={`pos-position-${position}`}
              toolbarItemIdOpen={openItemId}
              onOpen={setOpenItemId}
              onClose={setClosedItemId}
              region={region}
              organization={organization}
              disabled={disabled}
            />
          ))
        : null
      }
    </SegmentToolbarWrapper>
  );
}

const SegmentPricing = styled(SegmentPricingHTML)`
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  height: ${({ height }) => `${height}px`};
  bottom: ${({ height }) => `${-height}px`};
  border: 1px solid transparent;
  border-left: 0px;
  border-right: 0px;
  background: white;
  box-shadow: ${shadow};
  pointer-events: none !important;
  & * {
    pointer-events: none !important;
  }
`;

export class ShelfComponent extends Component {
  static defaultProps = {
    draggableAroundActive: 1,
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.config = Types.PLANOGRAM.map[this.props.id];
  }

  handleSegmentDragEnd = (
    srcList,
    srcIndex,
    dstList,
    dstIndex,
    data,
  ) => {
    if (srcList || dstList) {
      const indexDiff = dstIndex - srcIndex;
      if (srcList !== dstList || ![0, 1].includes(indexDiff)) {
        const { shelfs, onChange } = this.props;
        onChange(Object.keys(shelfs).reduce(
          (agr, shelfId) => {
            const shelf = shelfs[shelfId];
            let shelfChanged = false;
            agr[shelfId] = {
              ...shelf,
              segments: shelf.segments.map((segment) => {
                if (![srcList, dstList].includes(segment._id)) {
                  return segment;
                }
                shelfChanged = true;
                return {
                  ...segment,
                  groups: getListItems(
                    shelfId,
                    segment._id,
                    segment.groups,
                    srcList,
                    srcIndex,
                    dstList,
                    dstIndex,
                    data,
                  ),
                };
              }),
            };
            if (!shelfChanged) {
              agr[shelfId] = shelf;
            }
            return agr;
          },
          {},
        ));
      }
    }
  }

  handleSegmentGroupAction = (action, id, segmentId, product, value) => {
    const {
      id: shelfId,
      value: shelf,
      onChangeShelf,
    } = this.props;
    const {
      map: { [shelfId]: { type: { id: shelfType } } },
    } = Types.PLANOGRAM;
    let newSegmentIndex = null;
    let newSegment = null;
    const newShelf = {
      ...shelf,
      segments: shelf.segments.map((segment, segmentIndex) => {
        if (segment._id !== segmentId) {
          return segment;
        }
        newSegmentIndex = segmentIndex;
        newSegment = {
          ...segment,
          groups: segment.groups.reduce(
            (agr, group) => {
              if (group._id !== id) {
                agr.push(group);
                return agr;
              }
              const { span = 1, spanY = 1 } = group;
              switch (action) {
                case 'SPAN_DEC':
                  if (span > 1) {
                    agr.push({ ...group, span: span - 1 });
                  } else {
                    this.props.addToRemoved(product);
                  }
                  break;
                case 'SPAN_INC':
                  agr.push({ ...group, span: span + 1 });
                  break;
                case 'SPAN_VERTICAL_INC':
                  agr.push({
                    ...group,
                    spanY: spanY + 1,
                  });
                  break;
                case 'SPAN_VERTICAL_DEC':
                  if (spanY > 1) {
                    agr.push({
                      ...group,
                      spanY: spanY - 1,
                    });
                  } else {
                    this.props.addToRemoved(product);
                  }
                  break;
                case 'PRICE_TAG_COLOR_CHANGE':
                  agr.push({
                    ...group,
                    priceTagColor: value,
                  });
                  break;
                case 'REMOVE':
                  this.props.addToRemoved(product);
                  break;
                default:
              }
              return agr;
            },
            [],
          ),
        };
        return newSegment;
      }),
    };
    if (
      newSegment
      && Types.PLANOGRAM.validateSegmentSpace(
        newSegment.groups.map(group => ({
          ...group,
          product: this.props.getProduct(group.product),
        })),
        shelfType,
        shelf.segments.length,
        newSegmentIndex,
      )
    ) {
      onChangeShelf(shelfId, newShelf);
    }
  }

  handleGetCanAcceptData = (data, { segmentId }) => {
    const { type: { id: shelfType } } = this.config;
    const { shelfs: { [this.props.id]: { segments } } } = this.props;
    const segmentIndex = segments.findIndex(({ _id }) => _id === segmentId);
    const { groups } = segments[segmentIndex];
    return Types.PLANOGRAM.validateSegmentSpace(
      [
        ...groups.reduce(
          (agr, group) => {
            if (group._id !== data._id) {
              agr.push({
                ...group,
                product: this.props.getProduct(group.product),
              });
            }
            return agr;
          },
          [],
        ),
        { ...data, product: this.props.getProduct(data.product) },
      ],
      shelfType,
      segments.length,
      segmentIndex,
    );
  }

  renderSegmentGroup = (item, params = {}) => (
    <Group
      key={item._id}
      id={item._id}
      product={item.product}
      currency={this.props.currency}
      span={item.span}
      spanY={item.spanY}
      priceTagColor={item.priceTagColor}
      priceTagColorDefault={this.props.priceTagColor}
      shelf={this.props.value}
      shelfId={this.props.id}
      onAction={this.handleSegmentGroupAction}
      disabled={this.props.disabled}
      organization={this.props.organization}
      getBrand={this.props.getBrand}
      getCategory={this.props.getCategory}
      target={this.props.target}
      {...params}
    />
  )

  renderSegmentGroups(segment, index, draggable, isLast) {
    const { value } = this.props;
    const { size } = this.config.type;
    if (draggable) {
      return (
        <DNDList
          shelf={value}
          shelfId={this.props.id}
          segmentId={segment._id}
          items={segment.groups}
          currency={this.props.currency}
          renderItem={this.renderSegmentGroup}
          onDragEnd={this.handleSegmentDragEnd}
          getCanAcceptData={this.handleGetCanAcceptData}
          disabled={this.props.disabled}
        >
          <Groups
            currency={this.props.currency}
            alignment={this.props.value.alignment}
            top={index === 0 ? 0 : size.priceHeightPx}
            isLast={isLast}
          />
        </DNDList>
      );
    }
    return (
      <Groups
        count={segment.groups.length}
        top={index === 0 ? 0 : size.priceHeightPx}
        isLast={isLast}
      >
        {segment.groups.map((group, i) => this.renderSegmentGroup(
          group,
          {
            index: i,
            shelfId: this.props.id,
            segmentId: segment._id,
            draggable: false,
          },
        ))}
      </Groups>
    );
  }

  renderSegment(segment, index, draggable, isLast) {
    const { size } = this.config.type;
    return (
      <Segment
        key={segment._id}
        paddingTop={size.priceHeightPx}
      >
        {this.renderSegmentGroups(segment, index, draggable, isLast)}
        {
          !isLast
          ? (
              <SegmentPricing
                className="planogram-editor-body-shelf-segment-pricing"
                height={size.priceHeightPx}
              />
            )
          : null
        }
        <SegmentToolbar
          id={this.props.id}
          segmentIndex={index}
          value={this.props.shelfs}
          valueShelf={this.props.value}
          onChange={this.props.onChange}
          onChangeShelf={this.props.onChangeShelf}
          client={this.props.client}
          target={this.props.target}
          region={this.props.region}
          organization={this.props.organization}
          disabled={this.props.disabled}
        />
      </Segment>
    );
  }

  renderSegments() {
    const { inactive, value: { segments } } = this.props;
    return segments.map((segment, i) => this.renderSegment(
      segment,
      i,
      !inactive,
      segments.length - 1 === i,
    ));
  }

  render() {
    const {
      id,
      active,
      inactive,
      activeIndexDiff,
      draggableAroundActive,
      value,
      shelfs,
      isFirst,
      isLast,
      onSwitch,
      onChange,
      onChangeShelf,
      client,
      target,
      getProduct,
      region,
      organization,
      disabled,
    } = this.props;
    const {
      name,
      type: {
        size: {
          widthPx,
          heightPx,
          topPx,
          bottomPx,
        },
      },
    } = this.config;
    const { fridge, fridgeOpenAir } = value;
    const outterWidthPx = (
      widthPx + (2 * SHELF_PADDING_X)
    );
    const outterHeightPx = (
      heightPx + (2 * SHELF_PADDING_Y) + SHELF_HEADER_HEIGHT
    );
    return (
      <Wrapper
        className="planogram-editor-body-shelf"
        width={outterWidthPx}
        height={outterHeightPx}
        active={active}
      >
        <Header
          className="planogram-editor-body-shelf-header"
          width={widthPx}
          height={SHELF_HEADER_HEIGHT}
        >
          <Title
            className="planogram-editor-body-shelf-header-title"
            onClick={() => onSwitch(id)}
          >
            {name}
          </Title>
          <Toolbar
            id={id}
            client={client}
            value={shelfs}
            valueShelf={value}
            onChange={onChange}
            onChangeShelf={onChangeShelf}
            getProduct={getProduct}
            target={target}
            region={region}
            organization={organization}
            disabled={disabled}
          />
        </Header>
        <Body
          className="planogram-editor-body-shelf-body"
          width={widthPx}
          height={heightPx}
          isFirst={isFirst}
          isLast={isLast}
          inactive={inactive}
        >
          {
              Math.abs(activeIndexDiff) > (draggableAroundActive + 1)
            ? null
            : (
                <Segments
                  top={topPx}
                  bottom={bottomPx}
                >
                  {this.renderSegments()}
                </Segments>
              )
          }
          <Mask
            fridge={fridge}
            footerPx={bottomPx}
            openAir={!!fridgeOpenAir}
          />
        </Body>
      </Wrapper>
    );
  }
}

export default function Shelf(props) {
  const { addProduct } = useContext(TabRemovedContext);
  return (
    <ShelfComponent
      addToRemoved={addProduct}
      {...props}
    />
  );
}
