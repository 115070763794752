import Admin from 'hive-admin';
import isUndefined from 'lodash/isUndefined';

import FieldSelect from 'hive-admin/src/components/FieldSelect';

import Types from '../modules/types';

FieldSelect.inputPropsMap.filterOption = true;

Admin.addToLibrary('validation.isPassword', (config = {}) => ({
  type: 'string',
  validator: (rule, value, cb) => {
    if (isUndefined(value) || value === '') {
      cb();
    } else {
      try {
        Types.validatePassword(value);
        cb();
      } catch (error) {
        cb(rule.message || error.message);
      }
    }
  },
  ...config,
}));

Admin.addToLibrary('validation.isPasswordSame', ({
  as = 'password',
  ...restConfig
} = {}) => props => ({
  validator: (rule, passwordRepeat, cb) => {
    const password = props.form.getFieldValue(as);
    if (
      isUndefined(password)
      || !password.length
      || `${password}` === `${passwordRepeat}`
    ) {
      cb();
    } else {
      cb(rule.message);
    }
  },
  message: 'Passwords do not match!',
  ...restConfig,
}));
