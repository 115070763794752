/* eslint-disable no-irregular-whitespace */

import getKey from 'lodash/get';
import setKey from 'lodash/set';
import memoizeOne from 'memoize-one';

import React from 'react';
import styled from 'styled-components';

import Query from 'hive-admin/src/components/Query';

import { stringify as stringifyQuery } from 'querystring';
import Help from '../../Help';

import Types from '../../../modules/types';

import TableReport from './TableReport';
import {
  StyledSpan,
  Header,
  Table as InitialTable,
  TableHeader,
  TableCell,
} from './common';

const VALUE_KEYS = Types.getCombinations([
  [
    '0',
    ['marketShare', 'marketPenetration'],
  ],
  [
    '1',
    ['brand', 'product'],
  ],
  [
    '2',
    (
        Types.STATS_SIGNIFICANCE_VARS_KEEP
      ? ['value', 'difference', 'significance', 'significanceVars']
      : ['value', 'difference', 'significance']
    ),
  ],
]).map(key => `${key['0']}.${key['1']}.${key['2']}`);

const VALUE_DEFAULTS = VALUE_KEYS.reduce(
  (agr, key, i) => {
    agr.defaults[key] = agr.values[i % agr.values.length];
    return agr;
  },
  {
    defaults: {},
    values: (
        Types.STATS_SIGNIFICANCE_VARS_KEEP
      ? [0, 0, 0, Types.STATS_SIGNIFICANCE_VARS.clone()]
      : [0, 0, 0]
    ),
  },
).defaults;

const SIG_GREEN = '#52c41a';
const SIG_RED = '#ff4d4f';

const Table = styled(InitialTable)`
  .ant-table-footer {
    background: transparent;
    padding: 8px;
  }
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme }) => theme.less.textColor};
  padding: 0px;
  font-size: 12px;
`;

const LegendTitle = styled.div`
  display: flex;
  text-align: right;
  font-size: 14px;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
`;

const LegendPopoverContainer = styled.div`
  max-width: 300px;
  > br {
    display: block;
    height: 20px;
  }
`;

const LegendPopoverContainerBlock = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const LegendItemLabel = styled.div`
  display: flex;
  /* font-weight: bold; */
  justify-content: end;
  text-align: right;
`;

const LegendItemDescription = styled.div`
  display: flex;
  padding-left: 5px;
`;

const CodeHTML = ({ children = null, ...props }) => (
  <code {...props}>
    <pre>
      {children}
    </pre>
  </code>
);
const Code = styled(CodeHTML)`
  > pre {
    margin-bottom: 0px;
    padding: 10px 15px;
    font-size: 80%;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export default class TableSummary extends TableReport {
  getTableColumns = memoizeOne(() => (
    [
      {
        key: 'variation',
        title: (
          <TableHeader description="Scenarios Tested">
            Variation
          </TableHeader>
        ),
        width: '150px',
        align: 'left',
        render: (text, record, index) => (
          <TableHeader
            description={`${
              getKey(record, 'data.sampleCategoryCart', 0)
            }/${
              getKey(record, 'data.sampleTotal', 0)
            } Shoppers`}
          >
            {
              index === 0
              ? 'Control'
              : `${Types.VARIATION_LETTERS[record.variation]}`
            }
          </TableHeader>
        ),
      },
      ...([
        ['Brand', [
          [
            'Market Share',
            'Share of category sales (price x unit sales)',
            'marketShare.brand',
          ],
          [
            'Market Penetration',
            '​% of shoppers putting in cart',
            'marketPenetration.brand',
          ],
        ]],
        ['Product', [
          [
            'Market Share',
            'Share of category sales (price x unit sales)',
            'marketShare.product',
          ],
          [
            'Market Penetration',
            '​% of shoppers putting in cart',
            'marketPenetration.product',
          ],
        ]],
      ].reduce(
        (agr, [title, items]) => {
          items.forEach(([description, tooltip, key]) => agr.push({
            key,
            title: (
              <TableHeader description={description}>
                {title}
              </TableHeader>
            ),
            align: 'right',
            render: (text, record, index) => {
              const value = getKey(record, `${key}.value`, 0);
              let significance = 0;
              let significanceVars = null;
              let difference = 0;
              let significant = 0;
              if (index > 0) {
                significance = getKey(
                  record,
                  `${key}.significance`,
                  significance,
                );
                significanceVars = getKey(
                  record,
                  `${key}.significanceVars`,
                  significanceVars,
                );
                difference = getKey(
                  record,
                  `${key}.difference`,
                  difference,
                );
                significant = (
                    significance > 1.64 ? 1
                  : significance < -1.64 ? -1
                  : 0
                );
              }
              const styles = {};
              if (significant !== 0) {
                Object.assign(styles, {
                  color: significant > 0 ? SIG_GREEN : SIG_RED,
                  weight: 'bold',
                });
              }
              return (
                <StyledSpan {...styles}>
                  <TableCell
                    tooltipTitle={tooltip}
                    tooltipContent={
                      Types.STATS_SIGNIFICANCE_VARS_KEEP && significanceVars
                      ? (
                          <Code>
                            {`${significance} = sig(\n${
                              significanceVars
                              .map(([vName, vValue]) => `  ${
                                vName
                              }: ${
                                vValue
                              },`)
                              .join('\n')
                            }\n)`}
                          </Code>
                        )
                      : null
                    }
                    descriptionDimmed={difference === 0}
                    description={`${(
                        difference > 0 ? '+'
                      : difference < 0 ? ''
                      : ''
                    )}${
                      (difference * 100).toFixed(2)
                    }%`}
                  >
                    {`${(value * 100).toFixed(2)}%`}
                  </TableCell>
                </StyledSpan>
              );
            },
          }));
          return agr;
        },
        [],
      )),
    ]
  ))

  extractQueryData = (response) => {
    if (!response || !response.data) {
      return null;
    }
    const {
      content: { data: { resources } },
      filters: { values: { product: productId, age, gender } },
    } = this.props;
    const { map } = response.data.reduce(
      (agr, item) => {
        if (!agr.map[item.variation]) {
          agr.map[item.variation] = {
            sampleTotal: 0,
            sampleCategoryCart: 0,
            marketShare: { brand: {}, product: {} },
            marketPenetration: { brand: {}, product: {} },
          };
        }
        const result = agr.map[item.variation];
        if (item.type === 'project') {
          result.sampleTotal = item.sample.total;
        } else if (item.type === 'category') {
          result.sampleCategoryCart = item.sample.cart;
        } else {
          result.marketShare[item.type] = item.marketShare;
          result.marketPenetration[item.type] = item.marketPenetration;
        }
        return agr;
      },
      { map: {} },
    );
    const product = resources.product[productId];
    const brand = resources.brand[product.brand];
    const category = resources.category[product.category];
    const { content: { variationValues } } = this.props;
    const data = [];
    for (let i = 0; i < variationValues.length; i++) {
      const variation = variationValues[i];
      const stat = map[variation];
      const item = {
        key: variation,
        variation,
        age,
        gender,
        product,
        brand,
        category,
        data: stat || {},
      };
      for (let k = 0; k < VALUE_KEYS.length; k++) {
        const valueKey = VALUE_KEYS[k];
        setKey(
          item,
          valueKey,
          getKey(stat, valueKey, VALUE_DEFAULTS[valueKey]),
        );
      }
      data.push(item);
    }
    return data;
  }

  getQueryUrl = () => {
    const {
      filters: { values: { product, age, gender } },
      content: { project, variationValues, data: { resources } },
    } = this.props;
    const { category, brand } = resources.product[product];
    return `/projects/${project._id}/stats/compact?${stringifyQuery({
      query: JSON.stringify({
        compact: [[{
          type: 'project',
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1 },
        }], [{
          type: 'category',
          content: category,
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1 },
        }], [{
          type: 'brand',
          content: `${brand}_${category}`,
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1, marketShare: 1, marketPenetration: 1 },
        }], [{
          type: 'product',
          content: product,
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1, marketShare: 1, marketPenetration: 1 },
        }]],
      }),
    })}`;
  }

  renderContent = ({ data, error }) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    }
    if (!data) {
      return null;
    }
    return (
      <>
        <Header
          title="Summary"
          // eslint-disable-next-line max-len
          description="Key performance indicators to make a go / no-go decision, or which variation to launch."
        />
        <Table
          dataSource={data}
          columns={this.getTableColumns(data)}
          pagination={false}
          size="small"
          footer={() => (
            <Legend>
              <LegendTitle>
                Statistical Significance Legend
                &nbsp;
                <Help
                  placement="topRight"
                  title="About Statistical Significance"
                  content={(
                    <LegendPopoverContainer>
                      <LegendPopoverContainerBlock>
                        S​tatistical significance represents likelihood that
                        the difference in results
                        between a given variation and the control variation
                        is not due to chance.
                      </LegendPopoverContainerBlock>
                      <LegendPopoverContainerBlock>
                        Basing decisions on statistically significant
                        differences is necessary
                        because you observe a sample of the population,
                        and use it to make inferences about the total
                        population.
                      </LegendPopoverContainerBlock>
                    </LegendPopoverContainer>
                  )}
                />
              </LegendTitle>
              <LegendItem>
                <LegendItemLabel style={{ color: SIG_GREEN }}>
                  % sig higher
                </LegendItemLabel>
                <LegendItemDescription>
                  than control (90% confidence level)
                </LegendItemDescription>
              </LegendItem>
              <LegendItem>
                <LegendItemLabel style={{ color: SIG_RED }}>
                  % sig lower
                </LegendItemLabel>
                <LegendItemDescription>
                  than control (90% confidence level)
                </LegendItemDescription>
              </LegendItem>
            </Legend>
          )}
          bordered
        />
      </>
    );
  }

  render() {
    return (
      <Query
        client={this.props.client}
        content={this.props.content}
        extractData={this.extractQueryData}
        url={this.getQueryUrl()}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }
}
