// import getKey from 'lodash/get';
// import memoizeOne from 'memoize-one';

// import isFinite from 'lodash/isFinite';
// import isPlainObject from 'lodash/isPlainObject';

import React, { useRef, useMemo, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import simpleheat from 'simpleheat';

import Query from 'hive-admin/src/components/Query';

import { stringify as stringifyQuery } from 'querystring';

import AntdSwitch from 'antd/lib/switch';
import AntdRadio from 'antd/lib/radio';
import AntdButton from 'antd/lib/button';

import { UpOutlined } from '@ant-design/icons';

import TableReport from './TableReport';
import {
  Header,
  cm2px,
} from './common';

import Types from '../../../modules/types';

const GlobalStyle = createGlobalStyle`
  @keyframes ShelfSegmentProductWrapperFadeInOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const HeatMapEntry = styled(UpOutlined)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 10px;
  font-size: 20px;
  color: ${({ theme }) => theme.less.primaryColor};
`;

// const HeatMapWrapper = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   display: flex;
//   z-index: 2;
//   pointer-events: none;
//   overflow: visible;
//   [data-map-orientation="0"] & {
//     flex-direction: column;
//   }
//   [data-map-orientation="-90"] & {
//     flex-direction: row;
//   }
//   [data-map-orientation="90"] & {
//     flex-direction: row;
//   }
//   [data-map-id="left"] &,
//   [data-map-id="right"] & {
//     top: -50px;
//     padding-top: 50px;
//     overflow: hidden;
//     bottom: -50px;
//     padding-bottom: 50px;
//   }
//   [data-map-id="left"] & {
//     left: -20px;
//     padding-left: 20px;
//     right: -200px;
//     padding-right: 200px;
//   }
//   [data-map-id="right"] & {
//     right: -20px;
//     padding-right: 20px;
//     left: -200px;
//     padding-left: 200px;
//   }
// `;

// const HeatMapRow = styled.div`
//   display: flex;
//   flex: 1;
//   overflow: visible;
//   [data-map-orientation="0"] & {
//     flex-direction: row;
//   }
//   [data-map-orientation="-90"] & {
//     flex-direction: column;
//   }
//   [data-map-orientation="90"] & {
//     flex-direction: column;
//   }
// `;

// const HeatMapValue = styled.div.attrs(props => ({
//   style: (
//       props.mode === 'GRID'
//     ? {
//         opacity: props.intensity,
//         background: 'rgb(94, 138, 239)',
//       }
//     : props.mode === 'HEATMAP'
//     ? {
//         opacity: (props.intensity ** 3) * 0.9,
//         transform: `scale(${3 + (1 - props.intensity) * 3})`,
//         background: `radial-gradient(
//           circle,
//           rgba(94, 138, 239, 0.8) 0%,
//           rgba(94, 138, 239, 0.8) 22%,
//           rgba(94, 138, 239, 0) 80%
//         )`,
//       }
//     : undefined
//   ),
// }))`
//   display: flex;
//   flex: 1;
//   flex-grow: 1;
//   justify-content: center;
//   align-items: center;
//   font-size: 8px;
//   overflow: visible;
//   /* border-radius: 100%; */
//   transform-origin: center;
//   transition: opacity 300ms, transform 300ms;
// `;

// const HeatMap = ({
//   map: {
//     values: mapValues,
//     config: { size: { width, height } },
//     max,
//   },
//   orientation = 0,
//   mode = 'HEATMAP',
// }) => {
//   const rows = [];
//   for (let y = 0; y < height; y++) {
//     const row = [];
//     for (let x = 0; x < width; x++) {
//       const vx = (
//         orientation !== 0 && orientation !== 90
//         ? width - 1 - x
//         : x
//       );
//       const vy = (
//         orientation !== 0 && orientation !== -90
//         ? height - 1 - y
//         : y
//       );
//       const value = mapValues[(vy * width) + vx];
//       row.push(
//         <HeatMapValue
//           key={x}
//           intensity={value / max}
//           mode={mode}
//         />
//       );
//     }
//     rows.push(
//       <HeatMapRow key={y}>
//         {row}
//       </HeatMapRow>
//     );
//   }
//   return (
//     <HeatMapWrapper>
//       {rows}
//     </HeatMapWrapper>
//   );
// };

const HeatMapSimpleHeatCanvas = styled.canvas.attrs(props => ({
  style: { opacity: props.opacity },
}))`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  /* opacity: 0.7; */
`;

HeatMapSimpleHeatCanvas.defaultProps = {
  opacity: 0.75,
};

const HeatMapSimpleHeat = ({
  map: {
    values: mapValues,
    config: { size: { width: mapWidth, height: mapHeight } },
    max,
  },
  scale = 20,
  orientation = 0,
  opacity = 0.7,
  colors = false,
}) => {
  const heatRef = useRef();
  const ref = useRef();
  const { width, height } = useMemo(
    () => (
      orientation === 0
      ? { width: mapWidth, height: mapHeight }
      : { width: mapHeight, height: mapWidth }
    ),
    [orientation, mapWidth, mapHeight]
  );
  const values = useMemo(
    () => {
      const data = [];
      for (let i = 0; i < mapValues.length; i++) {
        let mapValueIndex = i;
        if (orientation === -90) {
          mapValueIndex = (
              (height * (i % width))
            + (height - 1 - Math.floor(i / width))
          );
        } else if (orientation === 90) {
          mapValueIndex = (
              ((width - 1 - (i % width)) * height)
            + (Math.floor(i / width))
          );
        }
        data.push([
          (scale / 2) + ((i % width) * scale),
          (scale / 2) + (Math.floor(i / width) * scale),
          mapValues[mapValueIndex],
        ]);
      }
      return data;
    },
    [mapValues, width, height, orientation, scale],
  );
  useEffect(
    () => {
      if (ref.current) {
        if (!heatRef.current) {
          ref.current.width = width * scale;
          ref.current.height = height * scale;
          heatRef.current = simpleheat(ref.current);
        }
        /* eslint-disable quote-props */
        heatRef.current.gradient(
          colors
          ? {
              '0.4': 'blue',
              '0.65': 'lime',
              '1': 'red',
            }
          : {
              '0': '#fff',
              '0.2': '#fff',
              '1': '#4885ff',
            },
        );
        /* eslint-enable quote-props */
        heatRef.current.data(values);
        heatRef.current.max(max);
        heatRef.current.draw(0.01);
      }
    },
    [values, max, colors, width, height, scale],
  );
  return (
    <HeatMapSimpleHeatCanvas
      ref={ref}
      opacity={opacity}
    />
  );
};

const MapsScrollWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  overflow: scroll;
`;

const MapsScrollContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const MapsWrapper = styled.div.attrs(props => ({
  style: {
    zoom: props.zoom,
  },
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 50px 20px;
  justify-content: flex-start;
`;

const MapsWrapperRow = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  overflow: visible;
`;

const MapsWrapperColumn = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  overflow: visible;
`;

const SectionWrapper = styled.div.attrs(props => ({
  style: {
    border: `1px solid ${props.theme.less.borderColor}`,
    marginLeft: props.offsetLeft ? `${props.offsetLeft}px` : undefined,
    marginRight: props.offsetRight ? `${props.offsetRight}px` : undefined,
    marginTop: props.offsetTop ? `${props.offsetTop}px` : undefined,
    marginBottom: props.offsetBottom ? `${props.offsetBottom}px` : undefined,
    zIndex: props.elevation || undefined,
  },
}))`
  position: relative;
  display: flex;
  flex-shrink: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  background-color: white;

  overflow: hidden;
  /* border-radius: 4px; */
  [data-map-orientation="0"] & {
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  [data-map-orientation="-90"] & {
    flex-direction: row;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  [data-map-orientation="90"] & {
    flex-direction: row;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const SectionShelves = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  [data-map-orientation="0"] & {
    flex-direction: row;
    align-items: stretch;
  }
  [data-map-orientation="-90"] & {
    flex-direction: column;
    justify-content: stretch;
  }
  [data-map-orientation="90"] & {
    flex-direction: column;
    justify-content: stretch;
  }
`;

const ShelfWrapperHTML = ({
  width,
  height,
  top,
  bottom,
  orientation,
  ...props
}) => (
  <div {...props} />
);
const ShelfWrapper = styled(ShelfWrapperHTML).attrs(props => ({
  style: (
      props.orientation === -90
    ? {
        height: `${props.width}px`,
        width: `${props.height}px`,
        paddingLeft: `${props.top}px`,
        paddingRight: `${props.bottom}px`,
        borderTop: `1px solid ${props.theme.less.borderColor}`,
      }
    : props.orientation === 90
    ? {
        height: `${props.width}px`,
        width: `${props.height}px`,
        paddingRight: `${props.top}px`,
        paddingLeft: `${props.bottom}px`,
        borderTop: `1px solid ${props.theme.less.borderColor}`,
      }
    : {
        width: `${props.width}px`,
        height: `${props.height}px`,
        paddingTop: `${props.top}px`,
        paddingBottom: `${props.bottom}px`,
        borderLeft: `1px solid ${props.theme.less.borderColor}`,
      }
  ),
}))`
  position: relative;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  /* background-color: #ccc; */
  /* pointer-events: none; */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  [data-map-orientation="0"] & {
    flex-direction: column;
    &:first-child {
      border-left: 0px !important;
    }
  }
  [data-map-orientation="-90"] & {
    flex-direction: row;
    &:first-child {
      border-top: 0px !important;
    }
  }
  [data-map-orientation="90"] & {
    flex-direction: row;
    &:first-child {
      border-top: 0px !important;
    }
  }
`;

const ShelfSegment = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  opacity: 0.8;
  [data-map-orientation="0"] & {
    flex-direction: row;
    width: 100%;
  }
  [data-map-orientation="-90"] & {
    flex-direction: column;
    height: 100%;
  }
  [data-map-orientation="90"] & {
    flex-direction: column;
    height: 100%;
  }
`;

const ShelfSegmentProducts = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* z-index: 1; */
`;

const ShelfSegmentProductImageWrapper = styled.div.attrs(props => ({
  style: {
    width: `${props.width}px`,
    height: `${props.height}px`,
    backgroundImage: props.image ? `url("${props.image}")` : undefined,
  },
}))`
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center;
  top: 50%;
  left: 50%;
  [data-map-orientation="0"] & {
    transform: translate(-50%, -50%);
  }
  [data-map-orientation="-90"] & {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  [data-map-orientation="90"] & {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

// eslint-disable-next-line arrow-body-style
const ShelfSegmentProductWrapper = styled.div.attrs(props => ({
  style: (
      props.orientation === -90
    ? {
        height: `${props.width}px`,
        width: `${props.height}px`,
        bottom: `${props.offset}px`,
      }
    : props.orientation === 90
    ? {
        height: `${props.width}px`,
        width: `${props.height}px`,
        top: `${props.offset}px`,
      }
    : {
        width: `${props.width}px`,
        height: `${props.height}px`,
        left: `${props.offset}px`,
      }
  ),
}))`
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
  transition:
    background-color 300ms,
    transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
  z-index: 1;
  &:hover {
    transform: scale(1.2);
    z-index: 9000;
  }
  &[data-image="true"] {
    &[data-active="true"] {
      animation:
        ShelfSegmentProductWrapperFadeInOut
        0.7s
        cubic-bezier(0.5, 0, 0.75, 0)
        0s
        alternate
        infinite
        none
        running;
    }
  }
  &[data-image="false"] {
    background-color: white;
    border: 1px solid #aaa;
    &:hover,
    &[data-active="true"] {
      border: 1px solid rgb(73, 74, 89);
      background-color: rgb(73, 74, 89);
    }
  }
  [data-map-orientation="0"] & {
    bottom: 0px;
    border-bottom: 0px !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transform-origin: 50% 100%;
  }
  [data-map-orientation="-90"] & {
    right: 0px;
    border-right: 0px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transform-origin: 100% 50%;
  }
  [data-map-orientation="90"] & {
    left: 0px;
    border-left: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    transform-origin: 0% 50%;
  }
`;

function ShelfProduct({
  data,
  product: { product: productId, offsetPx },
  activeProductId,
  setActiveProductId,
  variation,
  orientation,
  image: showImage = false,
}) {
  const {
    variations,
    resources: {
      product: {
        [productId]: {
          widthPx,
          heightPx,
          imageShopper,
          image,
        },
      },
    },
  } = data;
  const imageSrc = useMemo(
    () => (
      variations[variation] && variations[variation][(imageShopper || image)]
      ? variations[variation][(imageShopper || image)]
      : (imageShopper || image)
    ),
    [variation, variations, imageShopper, image],
  );
  const active = activeProductId === productId;
  return (
    <ShelfSegmentProductWrapper
      width={widthPx}
      height={heightPx}
      offset={offsetPx}
      active={active}
      orientation={orientation}
      onClick={active ? undefined : () => setActiveProductId(productId)}
      data-active={!!active}
      data-image={showImage && !!imageSrc}
    >
      <ShelfSegmentProductImageWrapper
        width={widthPx}
        height={heightPx}
        image={showImage ? imageSrc : null}
      />
    </ShelfSegmentProductWrapper>
  );
}

const ShelfSegmentPricesHTML = ({
  height,
  orientation,
  ...props
}) => (
  <div {...props} />
);
const ShelfSegmentPrices = styled(ShelfSegmentPricesHTML).attrs(props => ({
  style: (
      props.orientation === -90
    ? {
        width: `${props.height}px`,
        right: `${-props.height}px`,
        border: `1px solid ${props.theme.less.borderColor}`,
      }
    : props.orientation === 90
    ? {
        width: `${props.height}px`,
        left: `${-props.height}px`,
        border: `1px solid ${props.theme.less.borderColor}`,
      }
    : {
        height: `${props.height}px`,
        bottom: `${-props.height}px`,
        border: `1px solid ${props.theme.less.borderColor}`,
      }
  ),
}))`
  position: absolute;
  background: white;
  /* z-index: 2; */
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05); */
  [data-map-orientation="0"] & {
    width: 100%;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
  }
  [data-map-orientation="-90"] & {
    height: 100%;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
  }
  [data-map-orientation="90"] & {
    height: 100%;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
  }
`;

// const PriceWrapper = styled.div.attrs(props => ({
//   style: {
//     width: `${props.width}px`,
//     height: `${props.height}px`,
//     left: `${props.offset}px`,
//   },
// }))`
//   position: absolute;
//   top: 0px;
// `;

// function Price({ price: { offset }, width, height }) {
//   return (
//     <PriceWrapper
//       width={width}
//       height={height}
//       offset={cm2px(offset)}
//     />
//   );
// }

function Shelf({
  data,
  shelf,
  activeProductId,
  setActiveProductId,
  variation,
  orientation,
  images,
}) {
  // eslint-disable-next-line no-unused-vars
  const { id, type: shelfType, segments } = shelf;
  const {
    config: {
      shelf: {
        [shelfType]: {
          topPx: top,
          bottomPx: bottom,
          widthPx: width,
          heightPx: height,
          price: {
            // widthPx: priceWidth,
            heightPx: priceHeight,
          },
        },
      },
    },
  } = data;
  return (
    <ShelfWrapper
      width={width}
      height={height}
      top={top}
      bottom={bottom}
      orientation={orientation}
    >
      {segments.map((_s, segmentIndex) => {
        if (orientation !== 0 && orientation !== -90) {
          segmentIndex = segments.length - 1 - segmentIndex;
        }
        const segment = segments[segmentIndex];
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ShelfSegment key={segmentIndex} orientation={orientation}>
            <ShelfSegmentProducts orientation={orientation}>
              {segment.products.map((_p, productIndex) => {
                if (orientation !== 0 && orientation !== 90) {
                  productIndex = segment.products.length - 1 - productIndex;
                }
                const product = segment.products[productIndex];
                return (
                  <ShelfProduct
                    // eslint-disable-next-line react/no-array-index-key
                    key={productIndex}
                    data={data}
                    product={product}
                    orientation={orientation}
                    activeProductId={activeProductId}
                    setActiveProductId={setActiveProductId}
                    variation={variation}
                    image={images}
                  />
                );
              })}
            </ShelfSegmentProducts>
            <ShelfSegmentPrices
              height={priceHeight}
              orientation={orientation}
            >
              {/* {segment.prices.map((price, p) => (
                <Price
                  // eslint-disable-next-line react/no-array-index-key
                  key={p}
                  data={data}
                  price={price}
                  width={priceWidth}
                  height={priceHeight}
                  orientation={orientation}
                />
              ))} */}
            </ShelfSegmentPrices>
          </ShelfSegment>
        );
      })}
    </ShelfWrapper>
  );
}

function Section({
  data,
  id: sectionId,
  section: sectionShelves,
  activeProductId,
  setActiveProductId,
  variation,
  orientation,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetBottom,
  elevation,
  images,
  children,
}) {
  return (
    <SectionWrapper
      orientation={orientation}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      offsetTop={offsetTop}
      offsetBottom={offsetBottom}
      elevation={elevation}
    >
      <SectionShelves orientation={orientation}>
        {sectionShelves.map((_s, shelfIndex) => {
          if (orientation !== 0 && orientation !== 90) {
            shelfIndex = sectionShelves.length - 1 - shelfIndex;
          }
          const shelf = sectionShelves[shelfIndex];
          return (
            <Shelf
              key={shelf.id}
              data={data}
              shelf={shelf}
              sectionId={sectionId}
              orientation={orientation}
              activeProductId={activeProductId}
              setActiveProductId={setActiveProductId}
              variation={variation}
              images={images}
            />
          );
        })}
      </SectionShelves>
      {children}
    </SectionWrapper>
  );
}

const FloorWrapper = styled.div.attrs(props => ({
  style: (
      props.orientation === -90
    ? {
        height: `${props.width}px`,
        padding: `${props.offsetX}px ${props.offsetY}px`,
      }
    : props.orientation === 90
    ? {
        height: `${props.width}px`,
        padding: `${props.offsetX}px ${props.offsetY}px`,
      }
    : {
        width: `${props.width}px`,
        padding: `${props.offsetY}px ${props.offsetX}px`,
      }
  ),
}))`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
`;

const FloorBoundsWrapper = styled.div.attrs(props => ({
  style: {
    border: `1px solid ${props.theme.less.borderColor}`,
  },
}))`
  position: relative;
  flex: 1;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
`;

function Floor({ orientation, children }) {
  const { size, bounds } = Types.PLANOGRAM.floor;
  return (
    <FloorWrapper
      width={cm2px(size.width)}
      offsetX={cm2px(((size.width / 2) - bounds.max.x) / 2)}
      offsetY={cm2px(((size.height / 2) - bounds.max.y) / 2)}
      orientation={orientation}
    >
      <FloorBoundsWrapper>
        {children}
      </FloorBoundsWrapper>
    </FloorWrapper>
  );
}

export function Maps({
  maps,
  data,
  activeProductId,
  setActiveProductId,
  variation,
  zoom,
  images,
  colors,
}) {
  return (
    <MapsScrollWrapper>
      <MapsScrollContainer>
        <MapsWrapper zoom={zoom}>
          <MapsWrapperRow>
            {
              data.planogram.DISPLAY
              ? (
                  <MapsWrapperColumn
                    key="display"
                    data-map-id="display"
                    data-map-orientation="0"
                  >
                    <Section
                      id="DISPLAY"
                      data={data}
                      section={data.planogram.DISPLAY}
                      activeProductId={activeProductId}
                      setActiveProductId={setActiveProductId}
                      variation={variation}
                      orientation={0}
                      offsetLeft={cm2px(
                          Types.PLANOGRAM.section.DISPLAY.size.x
                        - Types.PLANOGRAM.section.DISPLAY.size.width
                      )}
                      offsetBottom={cm2px(
                          (Types.PLANOGRAM.section.DISPLAY.size.y)
                        - (Types.PLANOGRAM.floor.size.height / 2)
                        - (Types.PLANOGRAM.section.DISPLAY.size.depth / 2)
                      )}
                      elevation={1}
                      images={images}
                    >
                      {
                        maps.display
                        ? (
                            <HeatMapSimpleHeat
                              map={maps.display}
                              orientation={0}
                              colors={colors}
                            />
                          )
                        : null
                      }
                    </Section>
                  </MapsWrapperColumn>
                )
              : null
            }
          </MapsWrapperRow>
          <MapsWrapperRow>
            <MapsWrapperColumn
              key="left"
              data-map-id="left"
              data-map-orientation="-90"
            >
              <Section
                id="LEFT"
                data={data}
                section={data.planogram.LEFT}
                activeProductId={activeProductId}
                setActiveProductId={setActiveProductId}
                variation={variation}
                orientation={-90}
                images={images}
              >
                {
                  maps.left
                  ? (
                      <HeatMapSimpleHeat
                        map={maps.left}
                        orientation={-90}
                        colors={colors}
                      />
                    )
                  : null
                }
              </Section>
            </MapsWrapperColumn>
            <MapsWrapperColumn
              key="floor"
              data-map-id="floor"
              data-map-orientation={0}
            >
              <Floor
                orientation={0}
              >
                {
                  maps.floor
                  ? (
                      <HeatMapSimpleHeat
                        map={maps.floor}
                        orientation={0}
                        colors={colors}
                      />
                    )
                  : null
                }
              </Floor>
              <HeatMapEntry />
            </MapsWrapperColumn>
            <MapsWrapperColumn
              key="right"
              data-map-id="right"
              data-map-orientation="90"
            >
              <Section
                id="RIGHT"
                data={data}
                section={data.planogram.RIGHT}
                activeProductId={activeProductId}
                setActiveProductId={setActiveProductId}
                variation={variation}
                orientation={90}
                images={images}
              >
                {
                  maps.right
                  ? (
                      <HeatMapSimpleHeat
                        map={maps.right}
                        orientation={90}
                        colors={colors}
                      />
                    )
                  : null
                }
              </Section>
            </MapsWrapperColumn>
          </MapsWrapperRow>
        </MapsWrapper>
      </MapsScrollContainer>
    </MapsScrollWrapper>
  );
}

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 15px;
`;

const FilterLabel = styled.div`
  padding-right: 15px;
`;

const FilterVariation = styled(AntdRadio.Group)`

`;

const FilterVariationButton = styled(AntdRadio.Button)`

`;

const FilterZoom = styled(AntdButton.Group)`

`;

const FilterZoomButton = styled(AntdButton)`

`;

const FilterVariationSwitch = styled(AntdSwitch)`

`;

export default class TableMaps extends TableReport {
  constructor(props) {
    super(props);
    this.zoomOptions = [
      0.25,
      0.50,
      0.75,
      1.00,
      1.25,
      1.50,
      2.00,
      2.50,
      3.00,
      4.00,
    ];
    this.state = {
      variation: 0,
      zoom: this.zoomOptions.findIndex(value => value === 1),
      images: false,
      colors: false,
    };
  }

  setActiveProductId = (productId = null) => {
    this.props.setFilterValue('product', productId);
  }

  extractQueryData = (response) => {
    if (!response || !response.data) {
      return null;
    }
    const {
      content: { data: { resources } },
      filters: { values: { product: productId, age, gender } },
    } = this.props;
    const map = response.data.reduce(
      (agr, item) => {
        agr[item.variation] = item;
        return agr;
      },
      {},
    );
    const product = resources.product[productId];
    const brand = resources.brand[product.brand];
    const category = resources.category[product.category];
    const { content: { variationValues, project } } = this.props;
    const data = [];
    for (let i = 0; i < variationValues.length; i++) {
      const variation = variationValues[i];
      const stat = map[variation];
      if (stat) {
        for (let m = 0; m < project.config.maps.length; m++) {
          const config = project.config.maps[m];
          stat.maps[config._id]._id = config._id;
          stat.maps[config._id].config = config;
        }
        const item = {
          key: variation,
          variation,
          age,
          gender,
          product,
          brand,
          category,
          data: stat || {},
        };
        data.push(item);
      }
    }
    return data;
  }

  getQueryUrl = () => {
    const {
      filters: { values: { age, gender } },
      content: { project, variationValues },
    } = this.props;
    return `/projects/${project._id}/stats/compact?${stringifyQuery({
      query: JSON.stringify({
        compact: [[{
          type: 'project',
        }, {
          age: [age],
          gender: [gender],
          variation: [...variationValues],
        }, {
          select: { maps: 1, variation: 1 },
        }]],
      }),
    })}`;
  }

  renderContent = ({ data, error }) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return null;
    }
    if (this.props.project) {
      return null;
    }
    if (!data) {
      return null;
    }
    const {
      variation,
      // mode,
      zoom,
      images,
      colors,
    } = this.state;
    if (!data[variation]) {
      return null;
    }
    return (
      <>
        <Header
          title="Behaviour"
          description="Shopper attention and movement"
        />
        <FiltersWrapper>
          <Filter>
            <FilterLabel>Variation:</FilterLabel>
            <FilterVariation
              value={variation}
              onChange={event => this.setState({
                variation: event.target.value,
              })}
            >
              {data.map((option, variationIndex) => (
                <FilterVariationButton
                  key={option.variation}
                  value={option.variation}
                >
                  {
                      variationIndex === 0
                    ? 'Control'
                    : `${Types.VARIATION_LETTERS[option.variation]}`
                  }
                </FilterVariationButton>
              ))}
            </FilterVariation>
          </Filter>
          <Filter>
            <FilterLabel>Zoom:</FilterLabel>
            <FilterZoom>
              <FilterZoomButton
                disabled={zoom === 0}
                onClick={() => this.setState({
                  zoom: Math.max(0, zoom - 1),
                })}
              >
                -
              </FilterZoomButton>
              <FilterZoomButton
                disabled={this.zoomOptions.length - 1 === zoom}
                onClick={() => this.setState({
                  zoom: Math.min(this.zoomOptions.length - 1, zoom + 1),
                })}
              >
                +
              </FilterZoomButton>
            </FilterZoom>
          </Filter>
          <Filter>
            <FilterLabel>Show Products:</FilterLabel>
            <FilterVariationSwitch
              onChange={newImages => this.setState({
                images: !!newImages,
              })}
              checked={!!images}
            />
          </Filter>
          <Filter>
            <FilterLabel>Show Details:</FilterLabel>
            <FilterVariationSwitch
              onChange={newColors => this.setState({
                colors: !!newColors,
              })}
              checked={!!colors}
            />
          </Filter>
        </FiltersWrapper>
        <Maps
          maps={data[variation].data.maps}
          data={this.props.content.data}
          activeProductId={this.props.filters.values.product}
          setActiveProductId={this.setActiveProductId}
          variation={variation}
          zoom={this.zoomOptions[zoom]}
          images={images}
          colors={colors}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Query
          client={this.props.client}
          content={this.props.content}
          extractData={this.extractQueryData}
          url={this.getQueryUrl()}
        >
          {queryProps => this.renderContent(queryProps)}
        </Query>
      </>
    );
  }
}
