/* eslint-disable no-console */
import getKey from 'lodash/get';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';

// eslint-disable-next-line no-unused-vars
import React, { Component, useState } from 'react';

import Query from 'hive-admin/src/components/Query';

import { stringify as stringifyQuery } from 'querystring';

import TableReport from './TableReport';
import { Header, Table as InitialTable, TableHeader } from './common';
import { Tooltip } from '../../Popover';

import Types from '../../../modules/types';

const MultiTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Table = styled(InitialTable)`
  tbody > tr > td:last-child {
    position: relative;
    overflow: hidden;
  }
`;

const ChartWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 5px;
  right: 5px;
  bottom: 10px;
  display: flex;
`;

const ChartTooltipWrapper = styled.div`
  display: flex;
  margin-top: -4px !important;
  margin: -4px !important;
`;

const ChartTooltipLabel = styled.span`
  font-size: 14px;
  opacity: 0.8;
`;

const ChartTooltipValue = styled.span`
  font-size: 14px;
  opacity: 0.5;
  margin-left: 10px;
`;

// eslint-disable-next-line arrow-body-style
const ChartTooltip = ({ label, percent, empty, children }) => {
  // const [visible, setVisible] = useState(false);
  return (
    <Tooltip
      content={(
        <ChartTooltipWrapper>
          <ChartTooltipLabel>{label}</ChartTooltipLabel>
          <ChartTooltipValue>
            {`(${(empty ? 0 : percent).toFixed(1)}%)`}
          </ChartTooltipValue>
        </ChartTooltipWrapper>
      )}
      align={{ offset: [0, '20px'] }}
      // visible={visible}
      // onVisibleChange={() => setVisible(true)}
      destroyTooltipOnHide
    >
      {children}
    </Tooltip>
  );
};

const ChartSegment = styled.div.attrs(props => ({
  style: {
    width: `${props.value * 100}%`,
  },
}))`
  position: relative;
  display: flex;
  min-width: 20px;
`;

const ChartSegmentBackgroundHTML = ({ disabled, ...props }) => (
  <div {...props} />
);

const ChartSegmentBackground = styled(ChartSegmentBackgroundHTML)`
  position: absolute;
  top: 25px;
  left: 5px;
  right: 5px;
  bottom: 0px;
  opacity: 0.8;
  background: ${({ disabled, theme }) => (
      disabled
    ? '#ddd'
    : theme.less.primaryColor)
  };
  border-radius: 5px;
`;

const ChartSegmentLabel = styled.div`
  font-size: 14px;
  opacity: 0.8;
  margin-left: 5px;
  overflow: hidden;
  height: 24px;
  &:before {
    content: "";
    display: inline-block;
  }
`;

const ChartSegmentLabelText = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export default class TableDemographics extends TableReport {
  shouldComponentUpdate(nextProps, nextState) {
    const should = super.shouldComponentUpdate(nextProps, nextState);
    return should || this.props.demographics !== nextProps.demographics;
  }

  getTableColumns = memoizeOne(() => (
    [
      {
        key: 'variation',
        title: (
          <TableHeader description="Scenarios Tested">
            Variation
          </TableHeader>
        ),
        width: '130px',
        align: 'left',
        render: (text, record, index) => (
          <TableHeader
            description={`${
              getKey(
                record,
                `stat.${this.props.demographics}.${
                  this.props.filters.values[this.props.demographics]
                }.sample.cart`,
                0,
              )
            } Shoppers`}
          >
            {
              index === 0
              ? 'Control'
              : `${Types.VARIATION_LETTERS[record.variation]}`
            }
          </TableHeader>
        ),
      }, {
        key: 'graph',
        title: (
          <TableHeader
            description="for shoppers buying the product"
          >
            {this.props.demographics === 'age' ? 'Age' : 'Gender'}
          </TableHeader>
        ),
        align: 'right',
        render: (text, record) => (
          <ChartWrapper>
            {record.demographics.map(({ _id, label, sample, percent }) => (
              <ChartTooltip
                key={_id}
                label={`${
                  this.props.demographics === 'age' ? 'Age' : 'Gender'
                }: ${
                  label
                }`}
                empty={sample <= 0}
                percent={percent * 100}
              >
                <ChartSegment value={percent}>
                  <ChartSegmentBackground disabled={sample < 1} />
                  <ChartSegmentLabel>
                    <ChartSegmentLabelText>
                      {label}
                    </ChartSegmentLabelText>
                  </ChartSegmentLabel>
                </ChartSegment>
              </ChartTooltip>
            ))}
          </ChartWrapper>
        ),
      },
    ]
  ))

  extractQueryData = (response) => {
    if (!response || !response.data) {
      return null;
    }
    const {
      demographics,
      content: { data: { resources } },
      filters: { values: { product: productId, age, gender } },
    } = this.props;
    const map = response.data.reduce(
      (agr, item) => {
        if (!agr[item.variation]) {
          agr[item.variation] = { [demographics]: {} };
        }
        agr[item.variation][demographics][item[demographics]] = item;
        return agr;
      },
      {},
    );
    const product = resources.product[productId];
    const brand = resources.brand[product.brand];
    const category = resources.category[product.category];
    const {
      content: { project, variationValues },
    } = this.props;
    const data = [];
    const demographicsValues = (
        demographics === 'age'
      ? project.config.age.map(value => value)
      : demographics === 'gender'
      ? project.config.gender.map(value => value)
      : []
    );
    for (let i = 0; i < variationValues.length; i++) {
      const variation = variationValues[i];
      const stat = map[variation];
      const demographicsTotal = getKey(
        stat,
        [
          demographics,
          (demographics === 'age' ? age : gender),
          'sample',
          'cart',
        ],
        0,
      );
      const item = {
        key: variation,
        variation,
        age,
        gender,
        product,
        brand,
        category,
        stat: stat || {},
        demographics: demographicsValues.map(({ _id: value, label }) => {
          const sample = getKey(
            stat,
            [demographics, value, 'sample', 'cart'],
            0,
          );
          const percent = demographicsTotal
          ? Types.getRoundedAmount(sample / demographicsTotal, 3)
          : 1 / demographicsValues.length;
          return {
            _id: value,
            label,
            sample,
            percent,
            total: demographicsTotal || 0,
          };
        }),
      };
      data.push(item);
    }
    return data;
  }

  getQueryUrl = () => {
    const {
      content: { project, ageValues, genderValues, variationValues },
      demographics = 'gender',
      filters: { values: { product, age, gender } },
    } = this.props;
    return `/projects/${project._id}/stats/compact?${stringifyQuery({
      query: JSON.stringify({
        compact: [[{
          type: 'product',
          content: product,
        }, {
          age: age ? [age] : demographics === 'age'
          ? [null, ...ageValues]
          : [age],
          gender: gender ? [gender] : demographics === 'gender'
          ? [null, ...genderValues]
          : [gender],
          variation: [...variationValues],
        }, {
          select: { sample: 1 },
        }]],
      }),
    })}`;
  }

  renderContent = ({ data, error }) => {
    if (error) {
      console.log(error);
      return null;
    }
    if (!data) {
      return null;
    }
    return (
      <>
        {
          this.props.noheader
          ? null
          : (
              <Header
                title="Demographics"
                // eslint-disable-next-line max-len
                description="Buyers split by age and gender."
              />
            )
        }
        <Table
          dataSource={data}
          columns={this.getTableColumns(data)}
          pagination={false}
          size="small"
          bordered
        />
      </>
    );
  }

  render() {
    return (
      <Query
        client={this.props.client}
        content={this.props.content}
        extractData={this.extractQueryData}
        url={this.getQueryUrl()}
      >
        {queryProps => this.renderContent(queryProps)}
      </Query>
    );
  }
}

export function AgeAndGender(props) {
  return (
    <MultiTableWrapper>
      <Header
        title="Demographics"
        // eslint-disable-next-line max-len
        description="Buyers split by age and gender."
      />
      <TableDemographics {...props} demographics="age" noheader />
      <TableDemographics {...props} demographics="gender" noheader />
    </MultiTableWrapper>
  );
}
