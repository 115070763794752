import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '../../../Popover';

import { CONFIG as TAB_LIBRARY } from './TabLibrary';
import { CONFIG as TAB_REMOVED } from './TabRemoved';
import { CONFIG as TAB_PROJECT } from './TabProject';

import {
  SIDEBAR_WIDTH,
  HEADER_HEIGHT,
} from '../../config';

const WrapperHTML = ({ disabled, ...props }) => (
  <div {...props} />
);

const Wrapper = styled(WrapperHTML)`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: ${SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  border-right: 1px solid transparent;
  ${({ disabled }) => disabled && css`
    pointer-events: none !important;
    filter: grayscale(100%);
  `}
`;

const Header = styled.div`
  display: flex;
  position: relative;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  flex-direction: row;
  overflow: visible;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid transparent;
`;

const HeaderTabHTML = ({ active = false, ...props }) => (
  <div data-active={active} {...props} />
);

const HeaderTab = styled(HeaderTabHTML)`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-out;
  font-size: 18px;
  cursor: pointer;
`;

const HeaderTabTrackHTML = ({ count, active, ...props }) => (
  <div {...props} />
);

const HeaderTabTrack = styled(HeaderTabTrackHTML)`
  position: fixed;
  left: 0px;
  bottom: -1px;
  height: 2px;
  width: ${({ count }) => (100 / count)}%;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
  transform: ${({ active }) => `translateX(${100 * active}%)`};
`;

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      tabs: [],
    };
    if (this.props.target === 'project') {
      this.state.tabs.push({ ...TAB_PROJECT, config: {} });
    }
    this.state.tabs.push({ ...TAB_LIBRARY, config: {} });
    this.state.tabs.push({ ...TAB_REMOVED, config: {} });
  }

  handleTabConfigChange = value => this.setState((state) => {
    const tabs = state.tabs.slice();
    tabs[state.active] = {
      ...tabs[state.active],
      config: { ...(tabs[state.active].config || {}), ...value },
    };
    return { ...state, tabs };
  })

  renderHeader() {
    return (
      <Header className="planogram-editor-sidebar-header">
        {this.state.tabs.map(({ id, label, ComponentIcon }, i) => (
          <Tooltip key={id} title={label}>
            <HeaderTab
              active={this.state.active === i}
              className="planogram-editor-sidebar-header-tab"
              onClick={() => this.setState({ active: i })}
            >
              <ComponentIcon />
            </HeaderTab>
          </Tooltip>
        ))}
        <HeaderTabTrack
          key="track"
          count={this.state.tabs.length}
          active={this.state.active}
          className="planogram-editor-sidebar-header-tab-track"
        />
      </Header>
    );
  }

  renderBody() {
    const { active, tabs } = this.state;
    const { id, Component: TabComponent, config } = tabs[active];
    return (
      <TabComponent
        key={id}
        client={this.props.client}
        config={config}
        region={this.props.region}
        currency={this.props.currency}
        organization={this.props.organization}
        getBrand={this.props.getBrand}
        getCategory={this.props.getCategory}
        updates={this.props.updates}
        onConfigChange={this.handleTabConfigChange}
      />
    );
  }

  render() {
    return (
      <Wrapper
        className="planogram-editor-sidebar"
        disabled={this.props.disabled}
      >
        {this.renderHeader()}
        {this.renderBody()}
      </Wrapper>
    );
  }
}
