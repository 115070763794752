import React from 'react';
import styled from 'styled-components';

import {
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Tooltip } from './Popover';

const Icon = styled(QuestionCircleOutlined)`
  pointer-events: all !important;
  cursor: help;
`;

export default function Help({
  title,
  content = null,
  style,
  className,
  ...props
}) {
  return (
    <Tooltip
      title={title}
      content={content}
      destroyTooltipOnHide
      {...props}
    >
      <Icon
        style={style}
        className={className}
      />
    </Tooltip>
  );
}
