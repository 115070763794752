import React, { cloneElement } from 'react';
import styled from 'styled-components';

import memoizeOne from 'memoize-one';

import AntSelect from 'antd/lib/select';

import FieldSelect from 'hive-admin/src/components/FieldSelect';

import TableSummary from './TableSummary';
import TableShoppingMetrics from './TableShoppingMetrics';
import TableDemographics, {
  AgeAndGender as TableDemographicsAgeAndGender,
} from './TableDemographics';
import TableReview from './TableReview';
import TableMaps from './TableMaps';

class FieldSelectFilter extends FieldSelect {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.search = '';
    this.renderChoicesMemoized = memoizeOne(
      search => this.props.renderChoicesRaw(
        this,
        this.props,
        search,
      ),
    );
  }

  static inputPropsMap = {
    ...FieldSelect.inputPropsMap,
    filterOption: true,
  }

  static createSelf(config) {
    return FieldSelectFilter.create(config);
  }

  handleSearch = (value) => {
    this.setState({ search: value });
  }

  renderInput(props) {
    return super.renderInput({ ...props, onSearch: this.handleSearch });
  }
}

const MajorGroupLabel = styled.span`
  color: ${({ theme }) => theme.less.primaryColor};
`;

const DimmedOptionLabel = styled.span`
  opacity: 0.4;
`;

const FieldExtraWrapper = styled.span`
  margin-left: 10px;
`;

const FieldExtraLabel = styled.span``;

const FieldExtraValue = styled.span`
  color: ${({ theme }) => theme.less.textColor};
`;

export function createReportFilterField(fieldCreator, fieldConfig = {}) {
  return [fieldCreator, {
    virtual: true,
    isReportFilterField: true,
    optionMatchesSearch: (/* option, search */) => true,
    renderChoices: (field, props) => field.renderChoicesMemoized(
      field.state.search,
      props.value,
    ),
    renderChoicesRaw: (field, props, search) => (
      props.filters.choices[props.filter].reduce(
        (agr, choice, index, array) => {
          if (field.props.optionMatchesSearch(search, choice)) {
            const choiceGroup = choice.group || { id: '_', label: '' };
            let renderGroup = agr.groupsMap[choiceGroup.id];
            if (!renderGroup) {
              renderGroup = agr.groupsMap[choiceGroup.id] = {
                ...choiceGroup,
                items: [],
              };
              agr.groups.push(renderGroup);
            }
            renderGroup.items.push(cloneElement(
              props.renderChoice(choice, field, props),
              {
                label: choice.label,
                children: (
                  (
                       choice.dimmed
                    && props.filters.values[props.filter] !== choice.value
                  )
                  ? (
                      <DimmedOptionLabel>
                        {choice.label}
                      </DimmedOptionLabel>
                    )
                  : choice.label
                ),
              },
            ));
          }
          if (index === array.length - 1) {
            const { groups } = agr;
            if (!groups.length) {
              return null;
            }
            if (groups.length === 1 && groups[0].id === '_') {
              return groups[0].items;
            }
            return groups.map((group) => (
              <AntSelect.OptGroup
                key={group.id}
                label={
                  group.major
                  ? <MajorGroupLabel>{group.label}</MajorGroupLabel>
                  : group.label
                }
              >
                {group.items}
              </AntSelect.OptGroup>
            ));
          }
          return agr;
        },
        {
          groupsMap: {},
          groups: [],
        },
      )
    ),
    prepareValueForInput: (value, props) => (
      props.filters
      ? props.filters.values[props.filter]
      : null
    ),
    handleChange: (value, props) => {
      props.setFilterValue(props.filter, value);
    },
    onChange: () => {},
    ...fieldConfig,
  }];
}

export const fieldReportFilterAge = createReportFilterField(
  FieldSelectFilter.createSelf,
  {
    name: 'reportFilterAge',
    prefix: 'Age:',
    label: null,
    filter: 'age',
    formItemConfig: { style: { marginBottom: '12px' } },
  },
);

export const fieldReportFilterGender = createReportFilterField(
  FieldSelectFilter.createSelf,
  {
    name: 'reportFilterGender',
    prefix: 'Gender:',
    label: null,
    filter: 'gender',
    formItemConfig: { style: { marginBottom: '12px' } },
  },
);

export const fieldReportFilterProduct = createReportFilterField(
  FieldSelectFilter.createSelf,
  {
    name: 'reportFilterProduct',
    prefix: 'Product:',
    label: null,
    filter: 'product',
    filterOption: false,
    optionLabelProp: 'label',
    showSearch: true,
    formItemConfig: { style: { marginBottom: '12px' } },
    optionMatchesSearch: (search, option) => (
      option.searchable.indexOf((search || '').toLowerCase()) > -1
    ),
    getFormItemConfig: (field, props) => {
      const {
        filters: { values: { product: productId } },
        content: { data: { resources: { product: products } } },
      } = props;
      const product = products[productId];
      if (!product) {
        return { extra: null };
      }
      return {
        ...props.formItemConfig,
        extra: (
          <FieldExtraWrapper>
            <FieldExtraLabel>
              Category:
            </FieldExtraLabel>
            &nbsp;
            <FieldExtraValue>
              {product.categoryData.name}
            </FieldExtraValue>
            &nbsp;
            /
            &nbsp;
            <FieldExtraLabel>
              Brand:
            </FieldExtraLabel>
            &nbsp;
            <FieldExtraValue>
              {product.brandData.name}
            </FieldExtraValue>
          </FieldExtraWrapper>
        ),
      };
    },
  },
);

export const fieldReportTableSummary = ['FieldReact', {
  name: 'reportTableSummary',
  label: null,
  renderContent: props => <TableSummary {...props} />,
}];

export const fieldReportTableShoppingMetrics = ['FieldReact', {
  name: 'reportTableShoppingMetrics',
  label: null,
  renderContent: props => <TableShoppingMetrics {...props} />,
}];

export const fieldReportTableDemographicsAge = ['FieldReact', {
  name: 'reportTableDemographicsAge',
  label: null,
  renderContent: props => (
    <TableDemographics {...props} demographics="age" />
  ),
}];

export const fieldReportTableDemographicsGender = ['FieldReact', {
  name: 'reportTableDemographicsGender',
  label: null,
  renderContent: props => (
    <TableDemographics {...props} demographics="gender" noheader />
  ),
}];

export const fieldReportTableDemographicsAgeAndGender = ['FieldReact', {
  name: 'reportTableDemographicsAgeAndGender',
  label: null,
  renderContent: props => (
    <TableDemographicsAgeAndGender {...props} />
  ),
}];

export const fieldReportTableReview = ['FieldReact', {
  name: 'reportTableReview',
  label: null,
  create: (config) => { delete config.content; return {}; },
  getSkip: props => !props.content || !props.content.review,
  renderContent: props => <TableReview {...props} />,
}];

export const fieldReportTableMaps = ['FieldReact', {
  name: 'reportMaps',
  label: null,
  renderContent: props => <TableMaps {...props} />,
}];

export const FIELDS = [
  [fieldReportFilterAge, {
    col: {
      sm: { span: 24, offset: 0 },
      md: { span: 24, offset: 0 },
      lg: { span: 12, offset: 0 },
      xl: { span: 12, offset: 0 },
    },
  }],
  [fieldReportFilterGender, {
    col: {
      sm: { span: 24, offset: 0 },
      md: { span: 24, offset: 0 },
      lg: { span: 12, offset: 0 },
      xl: { span: 12, offset: 0 },
    },
  }],
  [fieldReportFilterProduct, {
    col: {
      sm: { span: 24, offset: 0 },
      md: { span: 24, offset: 0 },
      lg: { span: 24, offset: 0 },
      xl: { span: 24, offset: 0 },
    },
  }],
  [fieldReportTableSummary, {}],
  [fieldReportTableShoppingMetrics, {}],
  [fieldReportTableDemographicsAge, {}],
  [fieldReportTableDemographicsGender, {}],
  [fieldReportTableReview, {}],
  [fieldReportTableMaps, {}],
];
