import React from 'react';
import styled from 'styled-components';

import { CardElement } from '@stripe/react-stripe-js';

import Admin from 'hive-admin';
// import FieldReact from 'hive-admin/src/components/FieldReact';
import Field from 'hive-admin/src/components/Field';

const CardWrapper = styled.div`
  width: 100%;
  .StripeElement {
    border: 1px solid ${({ theme }) => theme.less.borderColor};
    padding: 7px 11px;
    padding-right: 2px;
    line-height: 21px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.less.borderRadius};
    transition: all 0.3s;
    &.StripeElement--focus,
    &:not(.StripeElement--invalid):hover {
      border-color: ${({ theme }) => theme.less.primaryColor};
    }
    &.StripeElement--focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.less.primaryColor2};
      &.StripeElement--invalid {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.less.errorColor2};
      }
    }
    &.StripeElement--invalid {
      border-color: ${({ theme }) => theme.less.errorColor};
    }
  }
`;

export default class FieldStripe extends Field {
  renderInput() {
    return (
      <CardWrapper>
        <CardElement />
      </CardWrapper>
    );
  }
}

Admin.addToLibrary(
  'FieldStripe',
  config => FieldStripe.create(config),
);
