// import React from 'react';

// import Help from '../../components/Help';

import Types from '../../modules/types';

export const fieldName = ['FieldText', {
  name: 'name',
  rules: [['validation.isRequired']],
}];

export const fieldCurrency = ['FieldText', {
  name: 'currency',
  rules: [['validation.isRequired']],
}];

export const FieldCurrencyPosition = ['FieldRadio', {
  name: 'currencyPosition',
  label: 'Currency Position',
  initialValue: Types.CURRENCY_POSITION[0],
  choices: Types.CURRENCY_POSITION_LIST.map(item => ({
    value: item.id,
    label: item.label,
  })),
  rules: [['validation.isRequired']],
}];

export const fieldTranslations = ['FieldTranslations', {
  name: 'translationsArray',
  // label: (
  //   <span>
  //     Translations
  //     &nbsp;
  //     <Help
  //       content={(
  //         <>
  //           Use the table below to translate the shopper
  //           <br />
  //           user interface to the region language.
  //         </>
  //       )}
  //     />
  //   </span>
  // ),
  label: 'Translations',
}];

export const ALL = [
  fieldName,
  fieldCurrency,
  FieldCurrencyPosition,
  fieldTranslations,
];
