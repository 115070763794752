import isArray from 'lodash/isArray';

// eslint-disable-next-line import/no-extraneous-dependencies
import { stringify as stringifyQuery } from 'query-string';

import {
  cloneItem,
  getValueForFieldDefault,
  filterSort,
  filterSortLibraryForCustomer,
} from '../common';

import Types from '../../modules/types';

function isFilterForContentManager(props) {
  return props.viewer && props.viewer.role === 'CONTENT_MANAGER';
}

function isFilterForAdminOrCustomer(props) {
  return props.viewer
  && (
    props.viewer.role === 'ADMIN'
    || props.viewer.role === 'CUSTOMER'
  );
}

function isFilterForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

function isFilterForCustomer(props) {
  return props.viewer && props.viewer.role === 'CUSTOMER';
}

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  searchPaths: ['name'],
  buildQuery: (value, builder, props) => value && builder.add(
    'where',
    {
      OR: props.searchPaths.map(searchPath => ({
        [searchPath]: { REGEX: value, OPTIONS: 'i' },
      })),
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search',
  }],
}];

export const filterRegions = ['FilterField', {
  id: 'regions',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { region: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'region',
    label: null,
    placeholder: 'Regions',
    url: '/regions',
    searchPaths: ['name', 'currency'],
    allowClear: true,
    mode: 'multiple',
  }],
}];

export const filterCategory = ['FilterField', {
  id: 'category',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { category: value },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'category',
    url: '/library/categories',
    placeholder: 'Category',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterCategories = [filterCategory[0], {
  ...filterCategory[1],
  id: 'categories',
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { categories: { IN: isArray(value) ? value : [value] } },
  ),
  field: [filterCategory[1].field[0], {
    ...filterCategory[1].field[1],
    name: 'categories',
    placeholder: 'Categories',
    mode: 'multiple',
  }],
}];

export const filterOrganization = ['FilterField', {
  id: 'organization',
  label: null,
  section: 'top',
  propsFromPage: props => ({
    client: props.client,
    history: props.history,
    location: props.location,
    searchParams: props.searchParams,
    onChange: (id, value) => {
      const params = { ...props.searchParams };
      delete params.organization;
      delete params.organizations;
      delete params.company;
      delete params.companies;
      delete params.brand;
      delete params.brands;
      if (value && value.length) {
        params[id] = `${value}`;
      }
      props.history.replace(`${
        props.location.pathname
      }?${
        stringifyQuery(params)
      }`);
    },
  }),
  handleChange: (value, props) => props.history.replace(
    `${props.location.pathname}?${
      stringifyQuery({
        ...props.searchParams,
        company: undefined,
        companies: undefined,
        brand: undefined,
        brands: undefined,
      })
    }`,
  ),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { organization: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'organization',
    url: '/organizations',
    placeholder: 'Organizations',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterLibrary = ['FilterField', {
  id: 'library',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    organization: { organization: { NE: null } },
    global: { organization: { EQ: null } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: (value, props) => (
    value && value.length && props.VALUES_MAP[value]
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'library',
    placeholder: 'Library',
    prepareValueForInput: value => value,
    choices: [{
      label: 'Any Library',
      value: null,
    }, {
      label: 'My Organization',
      value: 'organization',
    }, {
      label: 'Global',
      value: 'global',
    }],
  }],
}];

export const filterCompany = ['FilterField', {
  id: 'company',
  label: null,
  section: 'top',
  propsFromPage: props => ({
    client: props.client,
    searchParams: props.searchParams,
  }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { company: value },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'company',
    url: '/library/companies',
    placeholder: 'Company',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryConditions: (props) => {
      const { organization } = props.searchParams;
      return organization
      ? [{ organization }]
      : [];
    },
  }],
}];

export const filterBrand = ['FilterField', {
  id: 'brand',
  label: null,
  section: 'top',
  propsFromPage: props => ({
    client: props.client,
    searchParams: props.searchParams,
  }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { brand: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'brand',
    url: '/library/brands',
    placeholder: 'Brand',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryConditions: (props) => {
      const { organization } = props.searchParams;
      return organization
      ? [{ organization }]
      : [];
    },
  }],
}];

export const filterPOSMaterialType = ['FilterField', {
  id: 'type',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    ...Types.POS_MATERIAL_TYPE.reduce(
      (agr, value) => {
        agr[value.toLowerCase()] = { type: value };
        return agr;
      },
      {},
    ),
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'type',
    placeholder: 'Type',
    prepareValueForInput: value => value,
    choices: [
      { label: 'Any Type', value: null },
      ...Types.POS_MATERIAL_TYPE.map(value => ({
        value: value.toLowerCase(),
        label: Types.POS_MATERIAL_TYPE_LABELS_MAP[value],
      })),
    ],
  }],
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterPopulateRegion = ['FilterHidden', {
  id: 'populateOrganization',
  section: 'bottom',
  build: builder => builder.add('populate', { region: true }),
}];

export const filterPopulateOrganization = ['FilterHidden', {
  id: 'populateOrganization',
  section: 'bottom',
  build: builder => builder.add('populate', { organization: true }),
  onlyForAdmin: true,
}];

export const filterPopulateCategory = ['FilterHidden', {
  id: 'populateCategory',
  section: 'bottom',
  build: builder => builder.add('populate', { category: true }),
}];

export const filterPopulateCategories = ['FilterHidden', {
  id: 'populateCategories',
  section: 'bottom',
  build: builder => builder.add('populate', { categories: true }),
}];

export const filterPopulateCompany = ['FilterHidden', {
  id: 'populateCompany',
  section: 'bottom',
  build: builder => builder.add('populate', { company: true }),
}];

export const filterPopulateBrand = ['FilterHidden', {
  id: 'populateBrand',
  section: 'bottom',
  build: builder => builder.add('populate', { brand: true }),
}];

export const CATEGORIES = [
  ...[
    [filterSearch, 12],
    [filterSort, 12],
  ].map((config) => cloneItem(...config)),
  filterPagination,
];

export const COMPANIES = [
  ...[
    // Content Manager
    [filterSearch, 12, isFilterForContentManager],
    [filterSort, 12, isFilterForContentManager],
    // Admin or Customer
    [filterSearch, 12, isFilterForAdminOrCustomer],
    // Admin
    [filterSort, 12, isFilterForAdmin],
    [filterOrganization, 24, isFilterForAdmin],
    // Customer
    [filterSortLibraryForCustomer, 12, isFilterForCustomer],
    [filterLibrary, 24, isFilterForCustomer],
  ].map((config) => cloneItem(...config)),
  filterPagination,
  filterPopulateOrganization,
];

export const BRANDS = [
  ...[
    // Content Manager
    [filterSearch, 8, isFilterForContentManager],
    [filterCompany, 8, isFilterForContentManager],
    [filterSort, 8, isFilterForContentManager],
    // Admin or Customer
    [filterSearch, 12, isFilterForAdminOrCustomer],
    // Admin
    [filterSort, 12, isFilterForAdmin],
    [filterOrganization, 12, isFilterForAdmin],
    // Customer
    [filterSortLibraryForCustomer, 12, isFilterForCustomer],
    [filterLibrary, 12, isFilterForCustomer],
    // Admin or Customer
    [filterCompany, 12, isFilterForAdminOrCustomer],
  ].map((config) => cloneItem(...config)),
  filterPagination,
  filterPopulateOrganization,
  filterPopulateCompany,
];

export const PRODUCTS = [
  ...[
    // Content Manager
    [filterSearch, 12, isFilterForContentManager, {
      searchPath: ['name', 'categoryName', 'companyName', 'brandName'],
    }],
    [filterSort, 12, isFilterForContentManager],
    [filterCategory, 12, isFilterForContentManager],
    [filterBrand, 12, isFilterForContentManager],
    // Admin or Customer
    [filterSearch, 12, isFilterForAdminOrCustomer],
    // Admin
    [filterSort, 12, isFilterForAdmin],
    [filterOrganization, 8, isFilterForAdmin],
    // Customer
    [filterSortLibraryForCustomer, 12, isFilterForCustomer],
    [filterLibrary, 8, isFilterForCustomer],
    // Admin or Customer
    [filterCategory, 8, isFilterForAdminOrCustomer],
    [filterBrand, 8, isFilterForAdminOrCustomer],
    [filterRegions, 24],
  ].map((config) => cloneItem(...config)),
  filterPagination,
  filterPopulateRegion,
  filterPopulateOrganization,
  filterPopulateCategory,
  filterPopulateCompany,
  filterPopulateBrand,
];

export const POS_MATERIALS = [
  ...[
    // Content Manager
    [filterSearch, 8, isFilterForContentManager],
    [filterPOSMaterialType, 8, isFilterForContentManager],
    [filterSort, 8, isFilterForContentManager],
    // Admin or Customer
    [filterSearch, 12, isFilterForAdminOrCustomer],
    // Admin
    [filterSort, 12, isFilterForAdmin],
    [filterOrganization, 12, isFilterForAdmin],
    // Customer
    [filterSortLibraryForCustomer, 12, isFilterForCustomer],
    [filterLibrary, 12, isFilterForCustomer],
    // Admin or Customer
    [filterPOSMaterialType, 12, isFilterForAdminOrCustomer],
    [filterRegions, 24],
  ].map((config) => cloneItem(...config)),
  filterPagination,
  filterPopulateRegion,
  filterPopulateOrganization,
];

export const PLANOGRAMS = [
  ...[
    // Content Manager
    [filterSearch, 8, isFilterForContentManager],
    [filterCategories, 8, isFilterForContentManager],
    [filterSort, 8, isFilterForContentManager],
    // Admin or Customer
    [filterSearch, 12, isFilterForAdminOrCustomer],
    // Admin
    [filterSort, 12, isFilterForAdmin],
    [filterOrganization, 12, isFilterForAdmin],
    // Customer
    [filterSortLibraryForCustomer, 12, isFilterForCustomer],
    [filterLibrary, 12, isFilterForCustomer],
    // Admin or Customer
    [filterCategories, 12, isFilterForAdminOrCustomer],
    [filterRegions, 24],
  ].map((config) => cloneItem(...config)),
  filterPagination,
  filterPopulateRegion,
  filterPopulateOrganization,
  filterPopulateCategories,
];
