import { Component } from 'react';

export default class TableReport extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const should = (
      this.state !== nextState
      || (
          this.props.filters !== nextProps.filters
        || this.props.content !== nextProps.content
      )
    );
    return should;
  }
}
