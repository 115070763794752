// import React from 'react';

import Loader from './Loader';

export default class LoaderProject extends Loader {
  getVariations() {
    return this.props.variations;
  }

  renderChildren(params) {
    return super.renderChildren({
      ...params,
      variations: this.getVariations(),
    });
  }
}
