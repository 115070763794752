import React from 'react';
import styled from 'styled-components';

import logoSrc from '../assets/logo.svg';
import logoSmallSrc from '../assets/logo-small.png';

const WrapperHTML = ({ padding, ...props }) => <div {...props} />;

const Wrapper = styled(WrapperHTML)`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding || '15px 25px 30px'};
  align-items: center;
  justify-content: center;
  #admin[data-is-tablet="true"] #sidebar & {
    display: none;
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
`;

export default function Logo({ src, ...props }) {
  return (
    <Wrapper {...props}>
      <Image src={src || logoSrc} />
    </Wrapper>
  );
}

export function renderLogo() {
  return <Logo />;
}

export function renderSidebarLogo({ expandedSidebar }) {
  return (
    <Logo
      padding={expandedSidebar ? '20px 30px 30px' : '30px 20px 20px'}
      src={!expandedSidebar && logoSmallSrc}
    />
  );
}
