import React from 'react';
import styled from 'styled-components';
import ms from 'ms';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';

import Item from './Item';

import Popover, {
  Content as PopoverContent,
} from '../../../Popover';

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 14px;
  padding: 5px;
  margin-right: -5px;
  margin-left: 8px;
  margin-top: -5px;
  margin-bottom: -5px;
  line-height: 1;
`;

function Content({ value, loading, onOpenChange }) {
  return (
    <PopoverContent
      title={loading ? 'Generating...' : 'Export Planogram'}
      minWidth={200}
      actions={loading ? <LoadingIcon /> : null}
    >
      <Button
        type="primary"
        disabled={loading || !value}
        href={value}
        target="_blank"
        onClick={() => onOpenChange(false)}
        download
      >
        Download Now
      </Button>
    </PopoverContent>
  );
}

export default class ItemPlanogramExport extends Item {
  static defaultProps = {
    Icon: DownloadOutlined,
  }

  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.open = false;
    this.state.value = null;
    this.state.loading = false;
    this.loader = null;
    this.cache = null;
  }

  handleOpenChange = (open) => {
    open = !!open;
    if (!open) {
      this.loader = null;
    }
    this.setState((state) => {
      if (open === state.open) {
        return null;
      }
      if (open) {
        if (
          this.cache
          && this.cache.value === this.props.value
          && this.cache.variation === this.props.variation
          && (Date.now() - this.cache.timestamp) < ms('60s')
        ) {
          this.loader = null;
          // eslint-disable-next-line no-console
          console.log('planogram export:', this.cache.data.export);
          return {
            open,
            value: this.cache.data.export,
            loading: false,
          };
        }
        this.cache = null;
        const loader = this.loader = this.props.getPlanogramExport();
        this.loader
        .then(data => new Promise(resolve => setTimeout(resolve, 200, data)))
        .then(data => {
          if (this.loader === loader) {
            this.cache = {
              data,
              value: this.props.value,
              variation: this.props.variation,
              timestamp: Date.now(),
            };
            // eslint-disable-next-line no-console
            console.log('planogram export:', this.cache.data.export);
            this.setState({ value: data.export, loading: false });
          }
        })
        .catch((error) => {
          if (this.loader === loader) {
            // eslint-disable-next-line no-console
            console.log('getPlanogramExport error:', error);
            this.setState({ value: null, loading: false });
          }
        });
      } else {
        this.loader = null;
      }
      return {
        open,
        value: null,
        loading: open,
      };
    });
  }

  handleClick(event) {
    this.alt = !!event.altKey;
    super.handleClick(event);
  }

  renderButton() {
    const { open, loading, value } = this.state;
    return (
      <Popover
        title={null}
        content={(
          <Content
            value={value}
            loading={loading}
            onOpenChange={this.handleOpenChange}
          />
        )}
        trigger="click"
        destroyTooltipOnHide
        mouseLeaveDelay={0.1}
        placement="bottom"
        visible={open}
        onVisibleChange={this.handleOpenChange}
      >
        {super.renderButton()}
      </Popover>
    );
  }
}
