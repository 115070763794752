export default [
  [
    'tagline',
    // eslint-disable-next-line max-len
    'No boring surveys here, only one simple task: shop (virtually) in our 3D grocery aisle and have fun!',
  ],
  [
    'privacyPolicyText',
    [
      'By clicking “Start” you agree',
      'to our',
    ].join('\n'),
  ],
  [
    'privacyPolicyLabel',
    'privacy policy',
  ],
  [
    'yourMissionForToday',
    'Your mission for today',
  ], [
    'letsBegin',
    'Let\'s begin!',
  ], [
    'pressEnterWhenYoureDone',
    [
      'Please fill in the info below and press',
      'start when you\'re ready.',
    ].join('\n'),
  ], [
    'age',
    'Age',
  ], [
    'gender',
    'Gender',
  ], [
    'male',
    'Male',
  ], [
    'female',
    'Female',
  ], [
    'other',
    'Other',
  ], [
    'loading',
    'Loading',
  ], [
    'start',
    'Start',
  ], [
    'missingAgeOrGender',
    'Missing Age or Gender',
  ], [
    'pleaseFillInTheMissingFields',
    'Please fill in the missing fields below.',
  ], [
    'moveInCloser',
    'Move in closer',
  ], [
    'moveInCloserDescription',
    'You need to get within reachable distance of this item.',
  ], [
    'addToCart',
    'Add To Cart',
  ], [
    'cart',
    'Cart',
  ], [
    'item',
    'Item',
  ], [
    'items',
    'Items',
  ], [
    'yourTotal',
    'Your Total',
  ], [
    'checkout',
    'Checkout',
  ], [
    'submitFailed',
    'Submit Failed',
  ], [
    'submitFailedDescription',
    'Project may have ended or have been deleted in the meantime.',
  ], [
    'success',
    'Success!',
  ], [
    'pleaseReviewTheProduct',
    'Please review the product below.',
  ], [
    'pleaseReviewThePosMaterial',
    'Please review the promotional material below.',
  ], [
    'overallRating',
    'Overall Rating',
  ], [
    'describeUsingWords',
    'Describe using up to 10 words',
  ], [
    'describeUsingWordsEg',
    'eg: memorable, appealing, etc.',
  ], [
    'missingRating',
    'Missing Rating or Description',
  ], [
    'missingRatingDescription',
    'Please fill in the missing fields below.',
  ], [
    'submit',
    'Submit',
  ], [
    'submitting',
    'Submitting...',
  ], [
    'youMayCloseThisPage',
    'You may close this page now.',
  ], [
    'useWasd',
    'use WASD to walk through the store',
  ], [
    'useWasdDescription',
    '(you can also crouch by pressing C)',
  ], [
    'clickAndDrag',
    'click and drag to look around',
  ], [
    'clickAndDragDescription',
    '(laptop touchpad works too)',
  ], [
    'swipeTheScreen',
    'swipe the screen',
  ], [
    'swipeTheScreenDescription',
    'to look around',
  ], [
    'useTheJoystick',
    'use the joystick',
  ], [
    'useTheJoystickDescription',
    'to walk throught the store',
  ],
];
