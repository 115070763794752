// import getKey from 'lodash/get';

import Admin from 'hive-admin';
import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';

import tests from '../../helpers/tests';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FIELDS_ALL,
} from './fields';

const { defaultProps: { getColumns: initialGetColumns } } = PageArchiveTable;

const {
  skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Users',
    icon: 'user',
    path: '/users',
    hidden: [
      skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
    ],
    redirect: [['redirect.unauthorized']],
    archiveConfig: {
      icon: 'user',
      title: 'Users',
      label: 'Users',
      filters: FILTERS_ALL,
      skip: [
        skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
      ],
      subtitleColumnPath: 'email',
      getArchiveFilters: (props, filtersOriginal) => (
        filtersOriginal.filter(filter => (
            !filter.isAvailable
          ? true
          : filter.isAvailable(props, filter)
        ))
      ),
      getColumns: props => initialGetColumns(
        props,
        COLUMNS_ALL
        .filter(column => (
            !column.isAvailable
          ? true
          : column.isAvailable(props, column)
        )),
      ),
    },
    singleConfig: {
      hidden: true,
      alias: '/users',
      backButtonPaths: ['/users'],
      headerTitle: 'Create User',
    },
    singleCreateConfig: {
      skip: [
        skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
      ],
    },
    singleEditConfig: {
      getBackButtonPaths: props => (
        (
          props.viewer
          && (
            props.viewer.role === 'ADMIN'
            || (
              props.viewer.role === 'CUSTOMER'
              && props.viewer.permissions.manageOrganization
            )
          )
        )
        ? props.backButtonPaths
        : false
      ),
      actions: [
        ['ActionSave'],
        ['ActionDelete', {
          skip: [
            skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
          ],
        }],
        ['ActionWithRequest', {
          name: 'activate',
          title: 'Activate',
          skip: [
            ['condition.check', { path1: 'data.active', value2: true }],
            skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
          ],
          getRequestConfig: props => ({
            url: `users/actions/activate?id=${props.data._id}`,
            method: 'POST',
          }),
          handleSuccess: (data, props) => props.reload(),
        }],
        ['ActionWithRequest', {
          name: 'deactivate',
          title: 'Deactivate',
          skip: [
            ['condition.check', { path1: 'viewer._id', path2: 'data._id' }],
            skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
            ['condition.check', { path1: 'data.active', value2: false }],
          ],
          getRequestConfig: props => ({
            url: `users/actions/deactivate?id=${props.data._id}`,
            method: 'POST',
          }),
          handleSuccess: (data, props) => props.reload(),
        }],
        ['ActionWithRequest', {
          name: 'send-activation-email',
          title: 'Send Activation Email',
          skip: [
            ['condition.check', { path1: 'data.active', value2: true }],
            skipIfViewerNotAdminOrCustomerWhichCanManageOrganization,
          ],
          getRequestConfig: props => ({
            url: `users/actions/activate?id=${props.data._id}&email=true`,
            method: 'POST',
          }),
        }],
      ],
    },
    fields: FIELDS_ALL,
  }])
).pages;
