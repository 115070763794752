import isArray from 'lodash/isArray';

import {
  cloneItem,
  getValueForFieldDefault,
  filterSort,
} from '../common';

function isFilterAvailableOnlyForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

function isFilterAvailableOnlyForAdminWhenRoleIsCustomer(props) {
  if (!isFilterAvailableOnlyForAdmin(props)) {
    return false;
  }
  const { role } = props.searchParams;
  return role === 'customer';
}

function isFilterAvailableOnlyForCustomer(props) {
  return props.viewer && props.viewer.role === 'CUSTOMER';
}

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
        { email: { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search',
  }],
}];

export const filterRole = ['FilterField', {
  id: 'role',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    admin: { role: { EQ: 'ADMIN' } },
    'content-manager': { role: { EQ: 'CONTENT_MANAGER' } },
    customer: { role: { EQ: 'CUSTOMER' } },
  },
  ARGS_MAP: {
    any: null,
    admin: { role: 'ADMIN' },
    'content-manager': { role: 'CONTENT_MANAGER' },
    customer: { role: 'CUSTOMER' },
  },
  buildQuery: (value, builder, filter) => {
    if (filter.VALUES_MAP[value]) {
      builder.add('where', { ...filter.VALUES_MAP[value] });
    }
    if (filter.ARGS_MAP[value]) {
      builder.add('custom', { ...filter.ARGS_MAP[value] });
    }
  },
  getValueForField: (value, props) => (
    value && value.length && props.VALUES_MAP[value]
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'role',
    placeholder: 'Role',
    prepareValueForInput: value => value,
    choices: [{
      label: 'Any Role',
      value: null,
    }, {
      label: 'Admin',
      value: 'admin',
    }, {
      label: 'Content Manager',
      value: 'content-manager',
    }, {
      label: 'Customer',
      value: 'customer',
    }],
  }],
}];

export const filterActive = ['FilterField', {
  id: 'available',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: undefined,
    active: { active: { EQ: true } },
    inactive: { active: { EQ: false } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: (value, props) => (
    value && value.length && props.VALUES_MAP[value]
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'active',
    placeholder: 'Any Status',
    prepareValueForInput: value => value,
    initialValue: 'any',
    choices: [{
      label: 'Any Status',
      value: undefined,
    }, {
      label: 'Active',
      value: 'active',
    }, {
      label: 'Inactive',
      value: 'inactive',
    }],
  }],
}];

export const filterOrganization = ['FilterField', {
  id: 'organization',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { organization: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'organization',
    url: '/organizations',
    placeholder: 'Organizations',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterNotSignups = ['FilterHidden', {
  id: 'nonSignupUsers',
  section: 'bottom',
  build: builder => builder.add('where', { signup: { NE: true } }),
}];

export const filterPopulateOrganization = ['FilterHidden', {
  id: 'populateStore',
  section: 'bottom',
  build: builder => builder.add('populate', { organization: true }),
}];

export const ALL = [
  ...[
    // Admin
    [filterSearch, 12, isFilterAvailableOnlyForAdmin],
    [filterSort, 12, isFilterAvailableOnlyForAdmin],
    [filterRole, 12, isFilterAvailableOnlyForAdmin],
    [filterActive, 12, isFilterAvailableOnlyForAdmin],
    [filterOrganization, 24, isFilterAvailableOnlyForAdminWhenRoleIsCustomer],
    // Customer
    [filterSearch, 8, isFilterAvailableOnlyForCustomer],
    [filterActive, 8, isFilterAvailableOnlyForCustomer],
    [filterSort, 8, isFilterAvailableOnlyForCustomer],
  ].map((config) => cloneItem(...config)),

  filterPagination,
  filterNotSignups,
  filterPopulateOrganization,
];
