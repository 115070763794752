import isArray from 'lodash/isArray';
import memoizeOne from 'memoize-one';

import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import AntdTable from 'antd/lib/table';
import AntdInput from 'antd/lib/input';
import AmtdCheckbox from 'antd/lib/checkbox';
import AntdButton from 'antd/lib/button';
import AntdPopover from 'antd/lib/popover';
import AntdPopoconfirm from 'antd/lib/popconfirm';
import {
  PlusOutlined,
  DeleteOutlined,
  BarsOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin/src/components/Admin';
import Field from 'hive-admin/src/components/Field';

export const InputWrapper = styled.div`
  ${props => props.minWidth && css`min-width: ${props.minWidth};`}
  ${props => props.maxWidth && css`max-width: ${props.maxWidth};`}
  width: 100%;
  padding: 0px 5px;
`;

export const InputAligned = styled(AntdInput)`
  text-align: ${({ align }) => align || 'left'};
  width: 100%;
`;

export const InputCheckbox = ({ value, ...props }) => (
  <AmtdCheckbox
    {...props}
    checked={!!value}
    data-input-value-uses-checked="true"
  />
);

const LabelWrapper = styled.div`
  white-space: nowrap;
`;

const ControlsColumnFullPopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ControlsColumnSimpleDeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.less.primaryColor};
  }
`;

const ControlsAfterTableSimpleAddRowButton = styled(AntdButton)`
  margin-top: 20px;
`;

export class ControlsColumnFull extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  renderAction(name, icon, onClick) {
    return (
      <AntdButton
        type="link"
        onClick={() => {
          this.ref.current.tooltip.setState({ visible: false });
          onClick();
        }}
      >
        {icon}
        &nbsp;
        {name}
      </AntdButton>
    );
  }

  render() {
    return (
      <AntdPopover
        ref={this.ref}
        title={null}
        trigger={this.props.disabled ? [] : 'click'}
        placement="right"
        content={(
          <ControlsColumnFullPopoverWrapper>
            {this.renderAction(
              'Above',
              <PlusOutlined />,
              () => this.props.onAdd(this.props.row._id, true),
            )}
            {this.renderAction(
              'Below',
              <PlusOutlined />,
              () => this.props.onAdd(this.props.row._id, false),
            )}
            {this.renderAction(
              'Remove',
              <DeleteOutlined />,
              () => this.props.onRemove(this.props.row._id),
            )}
          </ControlsColumnFullPopoverWrapper>
        )}
      >
        <BarsOutlined />
      </AntdPopover>
    );
  }
}

const ControlsColumnSimpleDeleteIconPopoconfirmHTML = ({
  className,
  ...props
}) => (
  <AntdPopoconfirm overlayClassName={className} {...props} />
);

// eslint-disable-next-line max-len
const ControlsColumnSimpleDeleteIconPopoconfirm = styled(ControlsColumnSimpleDeleteIconPopoconfirmHTML)`
  .ant-popover-message-title {
    padding: 0px;
  }
`;

export class ControlsColumnSimple extends PureComponent {
  render() {
    return (
      <ControlsColumnSimpleDeleteIconPopoconfirm
        icon={null}
        title={(
          <>
            Once the organization settings are saved,
            <br />
            this team will be permanently deleted.
          </>
        )}
        placement="top"
        onConfirm={() => this.props.onRemove(this.props.row._id)}
      >
        <ControlsColumnSimpleDeleteIcon />
      </ControlsColumnSimpleDeleteIconPopoconfirm>
    );
  }
}

export function ControlsAfterTableSimple({ onRowAdd }) {
  return (
    <ControlsAfterTableSimpleAddRowButton
      icon="plus"
      type="primary"
      onClick={() => onRowAdd(null)}
      ghost
    >
      Add New Team
    </ControlsAfterTableSimpleAddRowButton>
  );
}

export class ColumnValue extends PureComponent {
  static getFieldProps({ props = {} }) {
    return props;
  }

  static getColumnLabel({ label }) {
    return label;
  }

  render() {
    const {
      fields,
      render,
      value = {},
      onChange,
      getLabel = this.constructor.getColumnLabel,
    } = this.props;
    return render
    ? render(value, this.props)
    : fields
    ? fields.map((field) => {
        const {
          name,
          maxWidth,
          minWidth,
          ComponentWrapper = InputWrapper,
          ComponentInput = InputAligned,
          getProps = this.constructor.getFieldProps,
        } = field;
        return (
          <ComponentWrapper key={name} maxWidth={maxWidth} minWidth={minWidth}>
            <ComponentInput
              value={value[name]}
              data-input-row={value._id}
              data-input-name={name}
              onChange={onChange}
              disabled={this.props.disabled}
              {...getProps(field, value, this.props)}
            />
          </ComponentWrapper>
        );
      })
    : (
        <LabelWrapper>
          {getLabel(value, this.props)}
        </LabelWrapper>
      );
  }
}

export class Table extends PureComponent {
  handleRowAdd = (rowId, before) => {
    this.props.onRowAdd(rowId, before);
  }

  handleRowRemove = (rowId) => {
    this.props.onRowRemove(rowId);
  }

  handleRowChange = (event) => {
    const { nativeEvent, target } = event;
    const { value, checked } = target;
    const {
      inputName,
      inputRow: inputRowId,
      inputValueUsesChecked,
    } = (nativeEvent.target.dataset || {});
    if (inputName) {
      this.props.onRowUpdate(
        inputRowId,
        { [inputName]: inputValueUsesChecked ? !!checked : value },
      );
    }
  }

  renderColumnControls = (text, row) => this.props.controlsRenderColumn({
    row,
    controls: this.props.controls,
    onAdd: this.handleRowAdd,
    onRemove: this.handleRowRemove,
    disabled: this.props.disabled,
  })

  renderColumnValue(
    text,
    row = {},
    rowIndex,
    columnIndex,
    render,
    fields,
    label,
    getLabel,
  ) {
    return (
      <ColumnValue
        value={row}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        render={render}
        fields={fields}
        label={label}
        getLabel={getLabel}
        onChange={this.handleRowChange}
        disabled={this.props.disabled}
      />
    );
  }

  render() {
    const {
      columns = [],
      value,
      controls,
      handleRowAdd,
      handleRowRemove,
      handleRowUpdate,
      table = {},
      ...props
    } = this.props;
    const controlColumn = controls
    ? (
        <AntdTable.Column
          key="column-controls"
          align="center"
          render={this.renderColumnControls}
          width="50px"
        />
      )
    : null;
    return (
      <AntdTable
        dataSource={value}
        size="middle"
        rowKey="_id"
        showHeader={false}
        pagination={false}
        scroll={{ x: 'max-content' }}
        bordered
        {...props}
        {...table}
      >
        {controlColumn}
        {columns.map(({
          key,
          fields,
          label,
          getLabel,
          render,
          ...column
        }, index) => (
          <AntdTable.Column
            key={key || index}
            {...column}
            render={(...args) => this.renderColumnValue(
              ...args,
              index,
              render,
              fields,
              label,
              getLabel,
            )}
          />
        ))}
      </AntdTable>
    );
  }
}

export default class FieldTable extends Field {
  static config = {
    ...Field.config,
    minCount: 1,
    controls: false,
    controlsGetNewItem: (/* index, props */) => ({}),
    // eslint-disable-next-line no-unused-vars
    controlsRenderColumn: (controlProps, props, field) => (
        controlProps.controls === 'full'
      ? <ControlsColumnFull {...controlProps} />
      : controlProps.controls === 'simple'
      ? <ControlsColumnSimple {...controlProps} />
      : null
    ),
    // eslint-disable-next-line no-unused-vars
    controlsRenderAfterTable: (controlProps, props, field) => (
        props.controls === 'full'
      ? null
      : props.controls === 'simple'
      ? <ControlsAfterTableSimple {...controlProps} />
      : null
    ),
  }

  constructor(props) {
    super(props);
    this.getValueFromPropsMemoized = memoizeOne((value, minCount) => {
      let newValue = value;
      if (!isArray(newValue)) {
        newValue = [];
      }
      const newValuesMissing = minCount - newValue.filter(
        row => row && !row.__removed
      ).length;
      if (newValuesMissing > 0) {
        if (newValue === value) {
          newValue = [...value];
        }
        for (let i = 0; i < newValuesMissing; i++) {
          newValue.push({
            ...this.props.controlsGetNewItem(this.props),
            __ghost: true,
            __added: true,
          });
        }
      }
      return newValue;
    });
    this.getValueForInputMemoized = memoizeOne((value) => {
      const newValue = value.filter(row => row && !row.__removed);
      if (newValue.length !== value.length) {
        return newValue;
      }
      return value;
    });
  }

  getValueFromProps() {
    return this.getValueFromPropsMemoized(
      this.props.value,
      this.props.minCount,
    );
  }

  getValueForInput(value) {
    return this.getValueForInputMemoized(value);
  }

  getValueForForm(value) {
    return value.filter((item) => {
      if (!item) {
        return false;
      }
      if (item.__removed && item.__added) {
        return false;
      }
      return true;
    });
  }

  handleRowAdd = (rowId, before = true) => {
    let changed = false;
    let value = this.getValueFromProps();
    if (rowId === null) {
      changed = true;
      value.push({
        ...this.props.controlsGetNewItem(this.props),
        __added: true,
      });
    } else {
      value = value.reduce(
        (agr, row) => {
          if (row._id === rowId) {
            changed = true;
            const newRow = {
              ...this.props.controlsGetNewItem(this.props),
              __added: true,
            };
            if (before) {
              agr.push(newRow, row);
            } else {
              agr.push(row, newRow);
            }
          } else {
            agr.push(row);
          }
          return agr;
        },
        [],
      );
    }
    if (changed) {
      this.props.onChange(this.getValueForForm(value));
    }
  }

  handleRowRemove = (rowId) => {
    let changed = false;
    const value = this.getValueFromProps().reduce(
      (agr, row) => {
        if (row._id === rowId && !row.__removed) {
          changed = true;
          agr.push({
            ...row,
            __removed: true,
            __initial: row.__initial || row,
          });
        } else {
          agr.push(row);
        }
        return agr;
      },
      [],
    );
    if (changed) {
      this.props.onChange(this.getValueForForm(value));
    }
  }

  handleRowUpdate = (rowId, changes = {}) => {
    let changed = false;
    const value = this.getValueFromProps().reduce(
      (agr, row) => {
        if (row._id === rowId) {
          changed = true;
          agr.push({
            ...row,
            ...changes,
            __updated: true,
            __initial: row.__added ? undefined : (row.__initial || row),
          });
        } else {
          agr.push(row);
        }
        return agr;
      },
      [],
    );
    if (changed) {
      this.props.onChange(this.getValueForForm(value));
    }
  }

  renderControlsColumn = controlProps => this.props.controlsRenderColumn(
    controlProps,
    this.props,
    this,
  )

  renderControlsAfterTable() {
    return this.props.controlsRenderAfterTable(
      {
        onRowAdd: this.handleRowAdd,
      },
      this.props,
      this,
    );
  }

  renderInput(props) {
    return (
      <>
        <Table
          {...props}
          columns={this.props.columns}
          controls={this.props.controls}
          controlsRenderColumn={this.renderControlsColumn}
          {...(this.props.table || {})}
          value={this.getValueForInput(this.getValueFromProps())}
          onRowAdd={this.handleRowAdd}
          onRowRemove={this.handleRowRemove}
          onRowUpdate={this.handleRowUpdate}
        />
        {this.renderControlsAfterTable()}
      </>
    );
  }
}

Admin.addToLibrary(
  'FieldTable',
  config => FieldTable.create(config),
);
