import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '../../../Popover';

export const ButtonHTML = ({ disabled, ...props }) => (
  <div {...props} />
);

export const Button = styled(ButtonHTML)`
  display: flex;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.4;
  `}
`;

export default class Item extends Component {
  static defaultProps = {
    Icon: Fragment,
  }

  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: false,
    };
  }

  componentDidUpdate() {
    if (this.state.open && this.state.tooltipVisible) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ tooltipVisible: false });
    }
  }

  handleClick(...args) {
    if (this.props.onClick) {
      this.props.onClick(...args);
    }
  }

  renderButton(extras = {}) {
    const { Icon, disabled } = { ...this.props, ...extras };
    return (
      <Button
        className="planogram-editor-body-header-toolbar-button"
        onClick={(...args) => this.handleClick(...args)}
        disabled={disabled}
      >
        <Icon />
      </Button>
    );
  }

  handleTooltipVisibleChange = visible => this.setState(({ open }) => {
    if (visible) {
      if (!open) {
        return { tooltipVisible: true };
      }
    } else {
      return { tooltipVisible: false };
    }
    return null;
  })

  render() {
    const { tooltip } = this.props;
    const { tooltipVisible } = this.state;
    if (tooltip) {
      return (
        <Tooltip
          title={tooltip}
          visible={tooltipVisible}
          onVisibleChange={this.handleTooltipVisibleChange}
        >
          {this.renderButton()}
        </Tooltip>
      );
    }
    return this.renderButton();
  }
}
