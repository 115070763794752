/* global document */

import Message from 'antd/lib/message';

export default function copyToClipboard(
  value,
  message = 'Copied to clipboard!',
  uriEncode = false,
) {
  const textarea = document.createElement('textarea');
  textarea.value = uriEncode
  ? encodeURI(value)
  : value;
  document.body.append(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  Message.success(message);
}
