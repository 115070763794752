/* global btoa */

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'query-string';

import {
  ExperimentOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import AntdButton from 'antd/lib/button';

import Query from 'hive-admin/src/components/Query';
import InputSelect from 'hive-admin/src/components/Input/Select';

import Popover, {
  Content as PopoverContent,
} from '../../../Popover';

import { LoaderLibrary } from '../Loader';

import Item from './Item';

import Types from '../../../../modules/types';

const CategoriesSelect = styled(InputSelect)`
  width: 100%;
`;

const Button = styled(AntdButton)`
  width: 100%;
`;

function LoadTrigger({ loading, value, onLoaded }) {
  const loadedRef = useRef(false);
  useEffect(
    () => {
      if (!loading && !loadedRef.current) {
        loadedRef.current = true;
        onLoaded(value);
      }
    },
    [loading, value, onLoaded],
  );
  return null;
}

function Load({
  empty,
  categories,
  organization,
  section,
  client,
  onLoaded,
  extractData,
}) {
  return (
    <Query
      client={client}
      url={`library/planograms/generate?${qs.stringify({
        query: btoa(JSON.stringify({
          empty,
          categories,
          organization,
          section,
        })),
      })}`}
      extractData={extractData}
    >
      {({ data }) => {
        if (!data) {
          return null;
        }
        return (
          <LoaderLibrary
            client={client}
            target="library"
            list={Types.PLANOGRAM.section[section].list}
            value={data.planogram}
            renderLoader={() => null}
            populate
          >
            <LoadTrigger onLoaded={onLoaded} />
          </LoaderLibrary>
        );
      }}
    </Query>
  );
}

Load.defaultProps = {
  extractData: response => (
    (response && response.data)
    ? response.data
    : null
  ),
};

function ContentEmpty({
  client,
  loading,
  onLoad,
  onLoaded,
  organization,
  section,
}) {
  return (
    <PopoverContent title="Clear Section" width={150}>
      <Button
        block
        type="primary"
        disabled={loading}
        onClick={onLoad}
      >
        Clear
      </Button>
      {
        !loading
        ? null
        : (
            <Load
              empty
              client={client}
              organization={organization}
              section={section}
              onLoaded={onLoaded}
            />
          )
      }
    </PopoverContent>
  );
}

function ContentRandom({
  client,
  loading,
  onLoad,
  onLoaded,
  extractData,
  organization,
  section,
}) {
  const [categoriesSearch, setCategoriesSearch] = useState();
  const [categories, setCategories] = useState([]);
  return (
    <PopoverContent title="Generate Random Section" width={300}>
      <Query
        client={client}
        extractData={extractData}
        url={`library/categories?${qs.stringify({ query: JSON.stringify({
          limit: 20,
          skip: 0,
          where: {
            OR: [
              (
                  categories && categories.length
                ? { _id: { IN: [...categories] } }
                : null
              ),
              {
                name: categoriesSearch && categoriesSearch.length
                ? { REGEX: categoriesSearch, OPTIONS: 'i' }
                : undefined,
              },
            ]
            .filter(condition => condition),
          },
          sort: { name: 1 },
        }) })}`}
      >
        {({ data }) => (
          <CategoriesSelect
            value={categories}
            onChange={categoriesValue => setCategories(categoriesValue)}
            showSearch
            onSearch={setCategoriesSearch}
            filterOption={false}
            placeholder="Categories"
            disabled={loading}
            mode="multiple"
            allowClear
          >
            {data.map(item => (
              <CategoriesSelect.Option key={item._id} value={item._id}>
                {item.name}
              </CategoriesSelect.Option>
            ))}
          </CategoriesSelect>
        )}
      </Query>
      <Button
        block
        type="primary"
        disabled={loading}
        onClick={onLoad}
      >
        Generate Aisle
      </Button>
      {
        !loading
        ? null
        : (
            <Load
              client={client}
              categories={categories}
              organization={organization}
              section={section}
              onLoaded={onLoaded}
            />
          )
      }
    </PopoverContent>
  );
}

ContentRandom.defaultProps = {
  extractData: response => (
    (
      response
      && response.data
      && response.data.data
      && response.data.data.length
    )
    ? response.data.data
    : []
  ),
};

export default class ItemReplace extends Item {
  static defaultProps = {
    mode: 'random',
  }

  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      open: false,
      loading: false,
    });
  }

  handleLoad = () => {
    this.setState({ loading: true });
  }

  handleLoaded = (value) => {
    const { onChange, activeSection } = this.props;
    this.setState(
      {
        open: false,
        loading: false,
      },
      () => {
        const { section: { [activeSection]: { list } } } = Types.PLANOGRAM;
        onChange(Object.keys(value).reduce(
          (agr, shelfId) => {
            const { map: { [shelfId]: { index } } } = Types.PLANOGRAM;
            const targetShelfId = list.includes(shelfId)
            ? shelfId
            : list[index];
            agr[targetShelfId] = {
              ...agr[targetShelfId],
              segments: value[shelfId].segments,
              alignment: value[shelfId].alignment,
              fridge: value[shelfId].fridge,
            };
            return agr;
          },
          { ...this.props.value },
        ));
      },
    );
  }

  handleVisibleChange = visible => this.setState({ open: !!visible })

  renderButton() {
    const {
      mode,
      client,
      organization,
      activeSection,
    } = this.props;
    const ContentComponent = (
        mode === 'empty'
      ? ContentEmpty
      : mode === 'random'
      ? ContentRandom
      : null
    );
    if (!ContentComponent) {
      return null;
    }
    const IconComponent = (
        mode === 'empty'
      ? DeleteOutlined
      : mode === 'random'
      ? ExperimentOutlined
      : null
    );
    return (
      <Popover
        title={null}
        overlayClassName="planogram-editor-body-header-generate"
        content={(
          <ContentComponent
            client={client}
            loading={this.state.loading}
            onLoad={this.handleLoad}
            onLoaded={this.handleLoaded}
            organization={organization}
            section={activeSection}
          />
        )}
        trigger="click"
        destroyTooltipOnHide
        mouseLeaveDelay={0.1}
        placement="bottomLeft"
        visible={this.state.loading || this.state.open}
        onVisibleChange={this.handleVisibleChange}
      >
        {super.renderButton({ Icon: IconComponent })}
      </Popover>
    );
  }
}
