import mongoose from 'mongoose/browser';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

import React, { Component, cloneElement } from 'react';

import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import Query from 'hive-admin/src/components/Query';

import Types from '../../../../modules/types';

const { Types: { ObjectId } } = mongoose;

const Loading = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
`;

class LoaderQuery extends Query {
  componentDidUpdate(props) {
    if (props.autoload || this.props.autoreload) {
      if (
        props.url !== this.props.url
        || props.method !== this.props.method
      ) {
        this.reload();
      }
    }
  }
}

export default class Loader extends Component {
  static getValue(
    shelfsInput,
    { target, list } = {},
  ) {
    let changed = false;
    let shelfs = shelfsInput;
    if (!isObject(shelfsInput)) {
      changed = true;
      shelfs = {};
    }
    shelfs = { ...shelfs };
    Types.PLANOGRAM.list.forEach(({ id, type, section }) => {
      const accepted = (
          list
        ? list.includes(id)
        : section.target.includes(target)
      );
      if (accepted) {
        if (!shelfs[id]) {
          changed = true;
          shelfs[id] = {
            fridge: false,
            alignment: Types.SHELF_ALIGNMENT[0],
            segments: [],
          };
        }
        if (!shelfs[id].segments || !shelfs[id].segments.length) {
          changed = true;
          shelfs[id] = { ...shelfs[id] };
          shelfs[id].segments = (
            new Array(type.segment.default)
            .fill(null)
            .map(() => ({
              _id: `${ObjectId()}`,
              groups: [],
              posMaterials: {},
            }))
          );
        }
      } else if (shelfs[id]) {
        changed = true;
        delete shelfs[id];
      }
    });
    return changed ? shelfs : shelfsInput;
  }

  static parseValueTargetPosMaterials(
    valueInput,
    map,
    content = {},
    posMaterialIds,
  ) {
    for (
      let posMaterialPositionIndex = 0;
      posMaterialPositionIndex < Types.POS_MATERIAL_POSITION.length;
      posMaterialPositionIndex++
    ) {
      const posMaterialPosition = Types.POS_MATERIAL_POSITION[
        posMaterialPositionIndex
      ];
      const posMaterialId = valueInput.posMaterials[posMaterialPosition];
      if (posMaterialId) {
        if (
          !content.posMaterial
          || !content.posMaterial[posMaterialId]
        ) {
          if (!map.posMaterial[posMaterialId]) {
            map.posMaterial[posMaterialId] = {
              _id: posMaterialId,
              data: {},
              refs: 0,
            };
            posMaterialIds.push(posMaterialId);
          }
          map.posMaterial[posMaterialId].refs++;
        }
      }
    }
  }

  static parseValue(valueInput, map, content = {}, populate = false) {
    let value = valueInput;
    const productIds = [];
    const posMaterialIds = [];
    if (!value) {
      return { value, productIds, posMaterialIds, map };
    }
    const shelfIds = Object.keys(value);
    for (
      let shelfIdIndex = 0;
      shelfIdIndex < shelfIds.length;
      shelfIdIndex++
    ) {
      const shelfId = shelfIds[shelfIdIndex];
      let shelf = value[shelfId];
      if (shelf && shelf.segments && shelf.segments.length) {
        for (
          let segmentIndex = 0;
          segmentIndex < shelf.segments.length;
          segmentIndex++
        ) {
          let segment = shelf.segments[segmentIndex];
          if (segment.posMaterials) {
            this.parseValueTargetPosMaterials(
              segment,
              map,
              content,
              posMaterialIds,
            );
          }
          if (segment && segment.groups && segment.groups.length) {
            for (
              let groupIndex = 0;
              groupIndex < segment.groups.length;
              groupIndex++
            ) {
              let group = segment.groups[groupIndex];
              if (group && group.product) {
                const productId = group.product;
                if (
                  !content.product
                  || !content.product[productId]
                ) {
                  if (!map.product[productId]) {
                    map.product[productId] = {
                      _id: productId,
                      data: {},
                      refs: 0,
                    };
                    productIds.push(productId);
                  }
                  map.product[productId].refs++;
                  if (populate) {
                    if (value === valueInput) {
                      value = { ...value };
                    }
                    if (shelf === value[shelfId]) {
                      shelf = { ...shelf };
                      value[shelfId] = shelf;
                    }
                    if (segment === shelf.segments[segmentIndex]) {
                      segment = { ...segment };
                      shelf.segments = shelf.segments.slice();
                      shelf.segments[segmentIndex] = segment;
                    }
                    if (group === segment.groups[groupIndex]) {
                      group = { ...group };
                      segment.groups = segment.groups.slice();
                      segment.groups[groupIndex] = group;
                    }
                    group.product = map.product[productId].data;
                  }
                }
              }
            }
          }
        }
      }
      if (shelf.posMaterials) {
        this.parseValueTargetPosMaterials(
          shelf,
          map,
          content,
          posMaterialIds,
        );
      }
    }
    return { value, productIds, posMaterialIds, map };
  }

  static parseSourcesWithRefs(sources, map, content, populate = false) {
    const sourceKeys = Object.keys(sources);
    const propertyKeys = ['category', 'company', 'brand'];
    const result = { ids: {}, map };
    for (
      let propertyKeyIndex = 0;
      propertyKeyIndex < propertyKeys.length;
      propertyKeyIndex++
    ) {
      const propertyKey = propertyKeys[propertyKeyIndex];
      result.ids[propertyKey] = result.ids[propertyKey] || [];
      result.map[propertyKey] = result.map[propertyKey] || {};
    }
    for (
      let sourceKeyIndex = 0;
      sourceKeyIndex < sourceKeys.length;
      sourceKeyIndex++
    ) {
      const sourceKey = sourceKeys[sourceKeyIndex];
      const sourceItems = sources[sourceKey];
      const sourceItemIds = Object.keys(sourceItems);
      for (
        let sourceItemIdIndex = 0;
        sourceItemIdIndex < sourceItemIds.length;
        sourceItemIdIndex++
      ) {
        const sourceItemId = sourceItemIds[sourceItemIdIndex];
        const sourceItem = sourceItems[sourceItemId];
        const sourceMapItem = map[sourceKey][sourceItemId];
        Object.assign(sourceMapItem.data, sourceItem);
        for (
          let propertyKeyIndex = 0;
          propertyKeyIndex < propertyKeys.length;
          propertyKeyIndex++
        ) {
          const propertyKey = propertyKeys[propertyKeyIndex];
          const propertyValue = sourceItem[propertyKey];
          if (propertyValue) {
            // TODO Here we can check if it populated (object) or just id (string)
            if (!map[propertyKey][propertyValue]) {
              map[propertyKey][propertyValue] = {
                _id: propertyValue,
                data: {},
                refs: 0,
              };
              result.ids[propertyKey].push(propertyValue);
            }
            map[propertyKey][propertyValue].refs += sourceMapItem.refs;
            if (populate) {
              sourceMapItem.data[propertyKey] = map[propertyKey][
                propertyValue
              ].data;
            }
          }
        }
      }
    }
    return result;
  }

  static parseSourcesWithoutRefs(
    sources,
    map,
  ) {
    const sourceKeys = Object.keys(sources);
    for (
      let sourceKeyIndex = 0;
      sourceKeyIndex < sourceKeys.length;
      sourceKeyIndex++
    ) {
      const sourceKey = sourceKeys[sourceKeyIndex];
      const sourceItems = sources[sourceKey];
      const sourceItemIds = Object.keys(sourceItems);
      for (
        let sourceItemIdIndex = 0;
        sourceItemIdIndex < sourceItemIds.length;
        sourceItemIdIndex++
      ) {
        const sourceItemId = sourceItemIds[sourceItemIdIndex];
        const sourceItem = sourceItems[sourceItemId];
        Object.assign(map[sourceKey][sourceItemId].data, sourceItem);
      }
    }
    return { map };
  }

  shouldComponentUpdate() {
    return false;
  }

  getContent() {
    if (!this.content) {
      const { content = {} } = this.props;
      this.content = [
        ['products', 'product'],
        ['posMaterials', 'posMaterial'],
      ].reduce(
        (result, [key, newKey]) => {
          result[newKey] = (content[key] || []).reduce(
            (resource, item) => {
              resource[item._id] = {
                ...item,
                project: true,
              };
              ['category', 'company', 'brand'].forEach((property) => {
                if (item[`${property}Embed`]) {
                  item[property] = item[`${property}Embed`];
                  delete item[`${property}Embed`];
                }
              });
              return resource;
            },
            {},
          );
          return result;
        },
        {
          display: !!content.display,
          mission: content.mission,
          review: content.review,
          priceTagColor: content.priceTagColor || Types.PRICE_TAG_COLOR[0],
        },
      );
    }
    return this.content;
  }

  getSaveValue(shelfs, resources) {
    return { shelfs, resources };
  }

  handleClose = () => this.props.onClose()

  handleSaveAndClose = (shelfs, resources) => {
    const value = this.getSaveValue(shelfs, resources);
    if (value) {
      return this.props.onClose(value);
    }
    return this.props.onClose();
  }

  renderLoading() {
    return (
      this.props.renderLoader
      ? this.props.renderLoader()
      : (
          <Loading>
            <LoadingOutlined />
          </Loading>
        )
    );
  }

  extractQueryData = response => (
    (
      response
      && response.data
      && response.data.data
      && response.data.data.length
    )
    ? response.data.data.reduce(
        (agr, item) => {
          agr[item._id] = item;
          return agr;
        },
        {},
      )
    : {}
  )

  renderGetter({ url, name, ids = [], callback, params = {} }) {
    if (!ids.length) {
      return callback({ data: {} });
    }
    const { [name]: content = {} } = this.getContent();
    const contentUsed = {};
    const nonContentIds = ids.filter((id) => {
      if (content[id]) {
        contentUsed[id] = content[id];
        return false;
      }
      return true;
    });
    return (
      <LoaderQuery
        client={this.props.client}
        extractData={this.extractQueryData}
        method="POST"
        url={`library/${url}/query`}
        config={{
          data: {
            query: {
              limit: 10000,
              skip: 0,
              where: { _id: { IN: nonContentIds } },
              ...params,
            },
          },
        }}
      >
        {({ loading, data }) => (
            loading
          ? this.renderChildren({ loading: true })
          : callback({ data: { ...data, ...contentUsed } })
        )}
      </LoaderQuery>
    );
  }

  renderGetters(configs, callback, initialResults = {}) {
    configs = configs.slice().reverse();
    return configs.reduce(
      (renderNext, { name, ids, ...rest }) => (
        ({ results }) => this.renderGetter({
          name,
          ids,
          ...rest,
          callback: ({ data }) => renderNext({
            results: { ...results, [name]: data },
          }),
        })
      ),
      callback,
    )({ results: initialResults });
  }

  renderChildren({ loading, ...params }) {
    const { children } = this.props;
    if (loading) {
      return this.renderLoading();
    }
    if (isFunction(children)) {
      return children({
        ...params,
        content: this.getContent(),
        onClose: this.handleClose,
        onSaveAndClose: this.handleSaveAndClose,
      });
    }
    return cloneElement(children, {
      ...params,
      content: this.getContent(),
      onClose: this.handleClose,
      onSaveAndClose: this.handleSaveAndClose,
    });
  }

  render() {
    const valueInput = this.constructor.getValue(this.props.value, this.props);
    const map = {
      product: {},
      posMaterial: {},
      category: {},
      company: {},
      brand: {},
    };
    const {
      value,
      productIds,
      posMaterialIds,
    } = this.constructor.parseValue(
      valueInput,
      map,
      this.getContent(),
      this.props.populate,
    );
    return (
      this.renderGetters(
        [{
          name: 'product',
          url: 'products',
          ids: productIds,
        }, {
          name: 'posMaterial',
          url: 'posmaterials',
          ids: posMaterialIds,
        }],
        ({ results: resultsWithRefs }) => {
          const {
            ids: {
              category: categoryIds = {},
              company: companyIds = {},
              brand: brandIds = {},
            },
          } = this.constructor.parseSourcesWithRefs(
            resultsWithRefs,
            map,
            this.getContent(),
            this.props.populate,
          );
          return this.renderGetters(
            [{
              name: 'category',
              url: 'categories',
              ids: categoryIds,
            }, {
              name: 'company',
              url: 'companies',
              ids: companyIds,
            }, {
              name: 'brand',
              url: 'brands',
              ids: brandIds,
            }],
            ({ results: resultsWithoutRefs }) => {
              this.constructor.parseSourcesWithoutRefs(
                resultsWithoutRefs,
                map,
              );
              return this.renderChildren({
                value,
                resources: map,
              });
            },
          );
        },
      )
    );
  }
}
