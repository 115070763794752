import React, { createContext, useContext } from 'react';

const Context = createContext();

export function Provider({ value, children }) {
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

export function useProduct(productOrProductId) {
  const { getProduct } = useContext(Context);
  return getProduct(productOrProductId);
}

export function usePosMaterial(posMaterialOrPosMaterialId) {
  const { getPosMaterial } = useContext(Context);
  return getPosMaterial(posMaterialOrPosMaterialId);
}

export function useBrand(brandOrBrandId) {
  const { getBrand } = useContext(Context);
  return getBrand(brandOrBrandId);
}

export function useCategory(categoryOrCategoryId) {
  const { getCategory } = useContext(Context);
  return getCategory(categoryOrCategoryId);
}

export default Context;
