import React from 'react';

import Admin from 'hive-admin';
import FieldText from 'hive-admin/src/components/FieldText';

import useRequest from '../helpers/useRequest';

const defaultGetRegionId = props => (
    props.parentData && props.parentData.region
  ? (props.parentData.region._id || props.parentData.region)
  : props.data && props.data.region
  ? (props.data.region._id || props.data.region)
  : props.form
  ? (props.form.getFieldValue('region') || null)
  : null
);

function InputTextWithCurrencyAddonAfter(props) {
  const {
    form,
    data,
    client,
    parentData,
    getRegionId,
    ...restProps
  } = props;
  const { Component } = FieldText.config;
  const [response] = useRequest(client, `/regions/${getRegionId(props)}`);
  const currency = (
      response.data && response.data.currency
    ? response.data.currency
    : null
  );
  return (
    <Component
      addonAfter={currency}
      {...restProps}
    />
  );
}

InputTextWithCurrencyAddonAfter.defaultProps = {
  getRegionId: defaultGetRegionId,
};

export default class FieldTextWithCurrencyAddonAfter extends FieldText {
  static config = {
    ...FieldText.config,
    Component: InputTextWithCurrencyAddonAfter,
  }

  static inputPropsMap = {
    ...FieldText.inputPropsMap,
    form: true,
    data: true,
    client: true,
    parentData: true,
    getRegionId: true,
  }
}

Admin.addToLibrary(
  'FieldTextWithCurrencyAddonAfter',
  config => FieldTextWithCurrencyAddonAfter.create(config),
);
