/* global window, document */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Admin from './components/Admin';

import './helpers/library';

import './antd.less';
import './admin.less';

import './components/ActionCreate';
import './components/ActionDelete';
import './components/ActionProcessingCompileReport';
import './components/ActionProcessingSessionsAudit';
import './components/ActionSave';
import './components/ActionSaveAndReload';
import './components/ActionStripePay';
import './components/FieldConnectionSelect';
import './components/FieldSortableList';
import './components/FieldSortableListVariations';
import './components/FieldStripe';
import './components/FieldTable';
import './components/FieldTextWithCurrencyAddonAfter';
import './components/FieldTranslations';
import './components/FieldPlanogram';
import './components/FieldLengthUnit';
import './components/FieldPaymentPlan';

import './components/ActionWithPopoverAndRequest';
import './components/PageSingleProject';

import UserPages from './routes/users';
import OrganizationPages, { MyOrganizationPage } from './routes/organizations';
import LibraryPage from './routes/library';
import ProjectPages from './routes/projects';
import RegionPages from './routes/regions';
import SettingsPages from './routes/settings';
import SignupPage from './routes/signup';

import theme from './theme';

import { renderLogo, renderSidebarLogo } from './components/Logo';

import Types from './modules/types';

window.TYPES = Types;

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_STRIPE_TOKEN,
  REACT_APP_PUBLIC_URL = '',
} = process.env;

const stripe = loadStripe(REACT_APP_STRIPE_TOKEN);

const SERVER_URL = `${
  REACT_APP_SERVER_PROTOCOL
}://${
  REACT_APP_SERVER_HOST
}${
  REACT_APP_NODE_ENV === 'development'
  ? `:${REACT_APP_HTTP_PORT}`
  : ''
}`;

const base = window.ADMIN_BASE = `${REACT_APP_PUBLIC_URL}/`;
const restBase = `${SERVER_URL}${REACT_APP_API_PATH}`;
// const restBase = `${
//   'https://app.shopnosis.io' || SERVER_URL
// }${REACT_APP_API_PATH}`;

const admin = Admin.create({
  base,
  restBase,
  titlePrefix: 'Shopnosis | ',
  sidebarProps: { renderLogo: renderSidebarLogo },
  passwordSetSuccessRedirectPath: '/login',
  accountActivationSuccessRedirectPath: '/login',
  structure: [
    ['PageLogin', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    [SignupPage[0], {
      renderBeforeForm: renderLogo,
      ...SignupPage[1],
    }],
    ['Page', {
      hidden: true,
      path: '/',
      exact: true,
      redirect: [() => '/users/me'],
    }],
    ['PagePasswordReset', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    ['PagePasswordSet', {
      renderBeforeForm: renderLogo,
    }],
    ['PageAccountActivation', {
      renderBeforeForm: renderLogo,
      accountActivationSuccessRedirectPath: '/',
    }],
    MyOrganizationPage,
    ...UserPages,
    ...OrganizationPages,
    LibraryPage,
    ...ProjectPages,
    ...RegionPages,
    SettingsPages,
    ['Page404', {
      redirect: [['redirect.unauthorized']],
    }],
  ],
});

ReactDOM.render(
  (
    <Elements stripe={stripe}>
      <ThemeProvider theme={theme}>
        {admin}
      </ThemeProvider>
    </Elements>
  ),
  document.getElementById('root'),
);
