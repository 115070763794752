import isArray from 'lodash/isArray';

import Types from '../../modules/types';

import {
  cloneItem,
  getValueForFieldDefault,
  filterSort,
} from '../common';

function isFilterForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

function isFilterForCustomer(props) {
  return props.viewer && props.viewer.role === 'CUSTOMER';
}

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search',
  }],
}];

export const filterStatus = ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    ...Types.PROJECT_STATUS.reduce(
      (agr, item) => {
        agr[item] = { status: { EQ: item } };
        return agr;
      },
      {},
    ),
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    prepareValueForInput: value => value,
    choices: [
      { label: 'Any Status', value: null },
      ...Types.PROJECT_STATUS.map(key => ({
        value: key,
        label: Types.PROJECT_STATUS_LABELS_MAP[key],
      })),
    ],
  }],
}];

export const filterOrganization = ['FilterField', {
  id: 'organization',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { organization: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'organization',
    url: '/organizations',
    placeholder: 'Organizations',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
  }],
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
}];

export const filterPopulateRegion = ['FilterHidden', {
  id: 'populateOrganization',
  section: 'bottom',
  build: builder => builder.add('populate', { region: true }),
}];

export const filterPopulateOrganization = ['FilterHidden', {
  id: 'populateOrganization',
  section: 'bottom',
  build: builder => builder.add('populate', { organization: true }),
  onlyForAdmin: true,
}];

export const filterPopulateCreatedBy = ['FilterHidden', {
  id: 'populateCreatedBy',
  section: 'bottom',
  build: builder => builder.add('populate', { createdBy: true }),
}];

export const ALL = [
  ...[
    // For Admin
    [filterSearch, 12, isFilterForAdmin],
    [filterSort, 12, isFilterForAdmin, { buildQueryDefault: 'date' }],
    [filterStatus, 12, isFilterForAdmin],
    [filterOrganization, 12, isFilterForAdmin],
    // For Customer
    [filterSearch, 8, isFilterForCustomer],
    [filterStatus, 8, isFilterForCustomer],
    [filterSort, 8, isFilterForCustomer, { buildQueryDefault: 'date' }],
  ].map(config => cloneItem(...config)),
  filterPagination,
  filterPopulateRegion,
  filterPopulateOrganization,
  filterPopulateCreatedBy,
];
