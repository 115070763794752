import isString from 'lodash/isString';
import isFinite from 'lodash/isFinite';
import isFunction from 'lodash/isFunction';
import padStart from 'lodash/padStart';

import React, { useEffect } from 'react';
import styled from 'styled-components';

import AntdSwitch from 'antd/lib/switch';
import AntdButton from 'antd/lib/button';
import AntdTable from 'antd/lib/table';
import AntdPagination from 'antd/lib/pagination';
import AntdModal from 'antd/lib/modal';

import Admin from 'hive-admin';
import Form from 'hive-admin/src/components/Form';
import Query from 'hive-admin/src/components/Query';
import Field from 'hive-admin/src/components/Field';
import ActionWithFormModal from 'hive-admin/src/components/ActionWithFormModal';

const FILTERS_COL_HALF = {
  md: 12,
  sm: 24,
};

const FILTERS = [
  ...(
    [
      [
        'Session',
        'session',
        '_id',
        value => isString(value) && value.length === 24,
        value => value,
        [
          ['ID', 'Eq'],
        ],
      ],
      () => ['FieldSelect', {
        name: 'sessions_status',
        label: null,
        prefix: 'Status:',
        initialValue: 'ANY',
        conditionGet: value => (
          value === 'ANY'
          ? null
          : { enabled: value === 'ENABLED' }
        ),
        choices: ['Any', 'Enabled', 'Disabled'].map(label => ({
          label,
          value: label.toUpperCase(),
        })),
        col: FILTERS_COL_HALF,
      }],
      () => ['FieldSelect', {
        name: 'sessions_sort',
        label: null,
        prefix: 'Sort:',
        initialValue: 'search.sumQuantity_1',
        sortGet: value => {
          const [key, valueString] = value.split('_');
          return { [key]: parseInt(valueString, 10) };
        },
        choices: [
          ['Total Quantity', 'search.sumQuantity'],
          ['Total Spending', 'search.sumSpending'],
          ['Shopping Time', 'search.durShopping'],
        ].reduce(
          (agr, [label, name]) => {
            agr.push({
              label: `${label} ASC`,
              value: `${name}_${1}`,
            }, {
              label: `${label} DESC`,
              value: `${name}_${-1}`,
            });
            return agr;
          },
          [],
        ),
        col: FILTERS_COL_HALF,
      }],
      [
        'Total Quantity',
        'sumQuantity',
        'search.sumQuantity',
        isFinite,
        value => value,
        [
          ['(min)', 'Gte'],
          ['(max)', 'Lte'],
        ],
        { addonAfter: 'items', type: 'number', col: FILTERS_COL_HALF },
      ],
      [
        'Total Spending',
        'sumSpending',
        'search.sumSpending',
        isFinite,
        value => value,
        [
          ['(min)', 'Gte'],
          ['(max)', 'Lte'],
        ],
        { addonAfter: '$', type: 'number', col: FILTERS_COL_HALF },
      ],
      [
        'Shopping Time',
        'durShopping',
        'search.durShopping',
        isFinite,
        value => value * 1000,
        [
          ['(min)', 'Gte'],
          ['(max)', 'Lte'],
        ],
        { addonAfter: 's', type: 'number', col: FILTERS_COL_HALF },
      ],
    ].reduce(
      (
        agr,
        input,
      ) => {
        if (isFunction(input)) {
          agr.push(input());
        } else {
          const [
            labelPrefix,
            namePrefix,
            conditionKey,
            conditionValidate,
            conditionNormalize,
            suffixes,
            config = {},
            creator = 'FieldText',
          ] = input;
          suffixes.forEach(([labelSuffix, nameSuffix]) => agr.push([creator, {
            name: `sessions_${namePrefix}${nameSuffix}`,
            label: null,
            prefix: `${labelPrefix} ${labelSuffix}:`,
            align: 'right',
            conditionGet: value => (
                conditionValidate(value)
              ? {
                  [conditionKey]: {
                    [nameSuffix.toUpperCase()]: conditionNormalize(value),
                  },
                }
              : null
            ),
            ...config,
          }]));
        }
        return agr;
      },
      [],
    )
  ),
];

function OnChange({ value, loading, callback, children }) {
  useEffect(
    () => { if (!loading) { callback(value); } },
    [value, loading, callback],
  );
  return children;
}

const Table = styled(AntdTable)`
  transition: opacity 300ms;
`;

const ModalHTML = ({ className, ...props }) => (
  <AntdModal wrapClassName={className} {...props} />
);
const Modal = styled(ModalHTML)`
  .ant-modal {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    max-width: 900px;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 40px);
      width: 100%;
      .ant-modal-body {
        overflow-y: scroll;
        display: flex;
        width: 100%;
        flex: 1;
        > .ant-form {
          width: 100%;
          .ant-form-item {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
`;

const ModalFooterPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalFooterPaginationCount = styled.div`

`;

export class FieldProcessingSessionsAuditTable extends Field {
  extractQueryData = (response) => {
    if (!response || !response.data || !response.data.data) {
      if (!this.oldData) {
        this.oldData = { data: [], count: 0 };
      }
      return this.oldData;
    }
    this.oldData = response.data;
    return this.oldData;
  }

  handleStatusChange = async (value, { target }) => {
    const sessionId = target.getAttribute('data-session-id');
    this.props.sessionsUpdateStatus({ _id: sessionId }, !!value);
  }

  getTableColumns() {
    return [{
      key: 'enabled',
      title: null,
      width: '40px',
      render: (text, record) => (
        <AntdSwitch
          size="small"
          checked={record.enabled}
          onChange={this.handleStatusChange}
          data-session-id={record._id}
        />
      ),
    }, {
      key: '_id',
      title: 'Session ID',
      render: (text, record) => record._id.toUpperCase(),
    }, {
      key: 'search.sumQuantity',
      title: 'Total Quantity',
      align: 'right',
      render: (text, record) => record.search.sumQuantity,
    }, {
      key: 'search.sumSpending',
      title: 'Total Spending',
      align: 'right',
      render: (text, record) => `${
        (record.search.sumSpending || 0).toFixed(2)
      } $`,
    }, {
      key: 'search.durShopping',
      title: 'Shopping Time',
      align: 'right',
      render: (text, record) => `${
        Math.floor((record.search.durShopping || 0) / 1000 / 60)
      }:${
        padStart(
          (((record.search.durShopping || 0) / 1000) % 60).toFixed(0),
          2,
          '0',
        )
      }`,
    }];
  }

  // renderExpandedTableRow = (record) => {
  //   return record.products.reduce(
  //     (agr, product) => {

  //     }
  //   )
  // }

  renderTable(queryProps) {
    return (
      <OnChange
        value={queryProps.data.count || 0}
        loading={!!(queryProps.error || queryProps.loading)}
        callback={this.props.onChange}
      >
        <Table
          rowKey="_id"
          scroll={{ x: 'max-content', y: false }}
          dataSource={queryProps.data.data}
          columns={this.getTableColumns()}
          expandedRowRender={this.renderExpandedTableRow}
          pagination={false}
          size="small"
          style={
              (queryProps.error || queryProps.loading)
            ? { pointerEvents: 'none', opacity: 0.4 }
            : undefined
          }
          centered
          bordered
        />
      </OnChange>
    );
  }

  renderInput() {
    return (
      <Query
        url={this.props.sessionsGetQueryUrl()}
        extractData={this.extractQueryData}
        client={this.props.client}
      >
        {queryProps => this.renderTable(queryProps)}
      </Query>
    );
  }
}

Admin.addToLibrary(
  'FieldProcessingSessionsAuditTable',
  config => FieldProcessingSessionsAuditTable.create(config),
);

export default class ActionProcessingSessionsAudit extends ActionWithFormModal {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state.t = 0;
    this.state.sessionsPage = 0;
    this.state.sessionsPageSize = 20;
  }

  componentDidUpdate() {
    const { form } = this.props;
    const { sessionsPage, sessionsPageSize } = this.state;
    const total = form.getFieldValue('sessionsCount') || 0;
    const sessionsPageMax = Math.floor(total / sessionsPageSize);
    if (sessionsPage > sessionsPageMax) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sessionsPage: sessionsPageMax });
    }
  }

  getQuery = () => {
    const { form } = this.props;
    const { sessionsPage, sessionsPageSize } = this.state;
    const sorts = [];
    const conditions = [];
    if (form) {
      FILTERS.forEach(([, { name, conditionGet, sortGet }]) => {
        if (conditionGet) {
          const condition = conditionGet(form.getFieldValue(name));
          if (condition) {
            conditions.push(condition);
          }
        } else if (sortGet) {
          const sort = sortGet(form.getFieldValue(name));
          if (sort) {
            sorts.push(sort);
          }
        }
      });
    }
    return {
      skip: sessionsPage * sessionsPageSize,
      limit: sessionsPageSize,
      where: conditions.length ? { AND: conditions } : undefined,
      sort: sorts.length ? Object.assign({}, ...sorts) : undefined,
      select: { maps: false, words: false },
    };
  }

  getUrl = () => {
    const { data: { _id: projectId } } = this.props;
    const { t } = this.state;
    return `/projects/${projectId}/sessions?t=${t}&query=${
      JSON.stringify(this.getQuery())
    }`;
  }

  refresh = () => this.setState({
    t: this.state.t + 1,
  })

  updateStatus = async (where, enabled = true) => {
    const { client, data: { _id: projectId } } = this.props;
    await client.request({
      method: 'PATCH',
      url: `/projects/${projectId}/sessions?query=${
        JSON.stringify({ where })
      }`,
      data: { enabled: !!enabled },
    });
    this.refresh();
  }

  updateFilteredStatusses = async (enabled = true) => {
    const { where } = this.getQuery();
    await this.updateStatus(where, enabled);
  }

  renderForm(props = {}) {
    return <Form key="form" {...this.props} {...props} />;
  }

  renderModal() {
    const { form } = this.props;
    const { sessionsPageSize, sessionsPage } = this.state;
    const total = form.getFieldValue('sessionsCount') || 0;
    return (
      <Modal
        title={this.props.getModalTitle(this.props)}
        footer={(
          <ModalFooterPaginationWrapper>
            <ModalFooterPaginationCount>
              {`${total} Sessions`}
              &nbsp;
              &nbsp;
              /
              &nbsp;
              &nbsp;
              <AntdButton
                ghost
                type="primary"
                onClick={() => this.updateFilteredStatusses(true)}
                size="small"
              >
                Enable
              </AntdButton>
              &nbsp;
              <AntdButton
                ghost
                type="danger"
                onClick={() => this.updateFilteredStatusses(false)}
                size="small"
              >
                Disable
              </AntdButton>
            </ModalFooterPaginationCount>
            <AntdPagination
              current={sessionsPage + 1}
              pageSize={sessionsPageSize}
              total={total}
              onChange={page => this.setState({ sessionsPage: page - 1 })}
            />
          </ModalFooterPaginationWrapper>
        )}
        visible={this.state.visible}
        onCancel={this.handleCancel}
        width="calc(100vw - 40px)"
        {...this.props.getModalProps(this.props)}
      >
        {this.renderBeforeForm()}
        {this.renderForm({
          sessionsPage,
          sessionsPageSize,
          sessionsGetQueryUrl: this.getUrl,
          sessionsRefresh: this.refresh,
          sessionsUpdateStatus: this.updateStatus,
        })}
        {this.renderAfterForm()}
      </Modal>
    );
  }
}

Admin.addToLibrary(
  'ActionProcessingSessionsAudit',
  (config = {}) => ActionProcessingSessionsAudit.create({
    modalProps: {
      centered: true,
      closable: true,
    },
    fields: [
      ...FILTERS,
      ['FieldProcessingSessionsAuditTable', {
        name: 'sessionsCount',
        label: null,
        virtual: true,
      }],
    ],
    ...config,
  }),
);
