import mongoose from 'mongoose/browser';

const { Types: { ObjectId } } = mongoose;

export const fieldName = ['FieldText', {
  name: 'name',
  rules: [['validation.isRequired']],
}];

export const fieldTeams = ['FieldTable', {
  name: 'teams',
  table: {
    showHeader: false,
    scroll: { x: '370px' },
  },
  skip: [props => props.activity !== 'edit'],
  controls: 'simple',
  controlsGetNewItem: () => ({
    _id: `${ObjectId()}`,
  }),
  columns: [{
    key: 'name',
    title: 'Team Name',
    fields: [{
      name: 'name',
      props: {
        size: 'default',
        placeholder: 'Team Name',
      },
    }],
  }],
}];

export const ALL = [
  fieldName,
  fieldTeams,
];
