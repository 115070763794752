import Admin from 'hive-admin';
import OriginalActionCreate from 'hive-admin/src/components/ActionCreate';

class ActionCreate extends OriginalActionCreate {
  static config = {
    ...OriginalActionCreate.config,
    ghost: true,
    icon: 'plus',
  }
}

Admin.addToLibrary(
  'ActionCreate',
  config => ActionCreate.create(config),
);
