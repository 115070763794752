import memoize from 'memoize-one';

import React, { Component } from 'react';
import styled from 'styled-components';

import { SearchOutlined } from '@ant-design/icons';

import AntdInput from 'antd/lib/input';
import AntdEmpty from 'antd/lib/empty';

import {
  HEADER_HEIGHT,
} from '../../config';
import Product from './Product';

const ContentHTML = ({ innerRef, ...props }) => (
  <div ref={innerRef} {...props} />
);

export const Content = styled(ContentHTML)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 10px;
  overflow: visible;
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  /* height: ${HEADER_HEIGHT}px; */
  border-bottom: 1px solid transparent;
`;

export const SearchInput = styled(AntdInput)`
  width: 100%;
`;

export default class Tab extends Component {
  static defaultProps = {
    emptyDescription: 'No Content Found',
  }

  renderHeaderContent() {
    return (
      <SearchInput
        value={this.props.config.search}
        placeholder="Search"
        onChange={ev => this.props.onConfigChange({ search: ev.target.value })}
        suffix={<SearchOutlined />}
        className="planogram-editor-sidebar-body-header-search"
      />
    );
  }

  renderHeader() {
    return (
      <Header className="planogram-editor-sidebar-body-header">
        {this.renderHeaderContent()}
      </Header>
    );
  }

  renderProduct(product, index) {
    return (
      <Product
        key={product._id}
        product={product}
        currency={this.props.currency}
        index={index}
        updates={this.props.updates}
        organization={this.props.organization}
        getBrand={this.props.getBrand}
        getCategory={this.props.getCategory}
      />
    );
  }

  renderProducts() {
    return null;
  }

  renderContent() {
    return (
      <Content className="planogram-editor-sidebar-body-content">
        {this.renderProducts()}
      </Content>
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderContent()}
      </>
    );
  }
}

export class TabLocal extends Tab {
  static defaultProps = {
    products: [],
    ...Tab.defaultProps,
  }

  getFilteredProducts = memoize((search, products) => {
    search = (search || '').toLowerCase();
    return (
      !search.length
      ? products
      : products.filter(({ name }) => name.toLowerCase().indexOf(search) > -1)
    ) || [];
  })

  renderProducts() {
    const products = this.getFilteredProducts(
      this.props.config.search,
      this.props.products,
    );
    if (!products.length) {
      return (
        <AntdEmpty
          image={AntdEmpty.PRESENTED_IMAGE_SIMPLE}
          description={this.props.emptyDescription}
        />
      );
    }
    return products.map((product, index) => this.renderProduct(
      product,
      index,
    ));
  }
}
