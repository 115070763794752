import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
} from './fields';

const {
  viewerIsNotAdmin,
  isNotViewerCustomerWhichCanManageOrganization,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Organizations',
    icon: 'home',
    path: '/organizations',
    redirect: [['redirect.unauthorized']],
    skip: [viewerIsNotAdmin],
    archiveConfig: {
      icon: 'home',
      title: 'Organizations',
      label: 'Organizations',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/organizations',
      backButtonPaths: ['/organizations'],
      headerTitle: 'Create Organization',
      loadUrl: '/organizations/:id/?teams=true',
    },
    singleEditConfig: {
      backButtonPaths: ['/organizations'],
      actions: [
        ['ActionSaveAndReload'],
      ],
    },
    fields: FIELDS_ALL,
  }])
).pages;

export const MyOrganizationPage = ['PageSingle', {
  icon: 'home',
  label: 'My Organization',
  title: 'My Organization',
  path: '/organization',
  exact: true,
  backButtonPaths: false,
  skip: [
    isNotViewerCustomerWhichCanManageOrganization,
  ],
  redirect: [
    ['redirect.unauthorized'],
  ],
  hidden: false,
  activity: 'edit',
  fields: FIELDS_ALL,
  useTrash: false,
  loadUrl: '/organizations/me/?teams=true',
  saveUrl: '/organizations/me/?teams=true',
  saveMethod: 'patch',
  loadExtractData: response => (response ? response.data : null),
  editConfig: {
    actions: [['ActionSaveAndReload']],
  },
  notFoundMessage: 'Organization not found!',
}];
