/* eslint-disable import/prefer-default-export */

import {
  cloneElement,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import Context, { Provider as ContextProvider } from './Context';

export {
  Context,
  ContextProvider as Provider,
};

export function getListItems(
  shelfId,
  segmentId,
  items,
  srcList,
  srcIndex,
  dstList,
  dstIndex,
  data,
  getItem = item => item,
  passthrough = false,
) {
  if (!items.length) {
    if (!passthrough && (dstList === segmentId && dstIndex === 0)) {
      return [
        getItem(data, {
          index: 0,
          shelfId,
          segmentId,
          dragging: true,
        }),
      ];
    }
  } else {
    return items.reduce(
      (agr, item, index) => {
        if (!passthrough && (dstList === segmentId && dstIndex === index)) {
          agr.push(getItem(data, {
            index,
            shelfId,
            segmentId,
            dragging: true,
          }));
        }
        if (passthrough || (srcList !== segmentId || srcIndex !== index)) {
          agr.push(getItem(item, {
            index,
            shelfId,
            segmentId,
            dragging: false,
          }));
        }
        if (
          !passthrough
          && (
            dstList === segmentId
            && (items.length - 1) === index
            && dstIndex >= items.length
          )
        ) {
          agr.push(getItem(data, {
            index,
            shelfId,
            segmentId,
            dragging: true,
          }));
        }
        return agr;
      },
      [],
    );
  }
  return items;
}

export function List({
  // shelf,
  shelfId,
  segmentId,
  items: itemsInput,
  renderItem,
  getCanAcceptData,
  onDragEnd,
  children,
  currency,
}) {
  const {
    addList,
    removeList,
    dragStart,
    dragMove,
    // dragMoveEvent,
    dragEnd,
    // dragEndEvent,
    data,
    dragging,
    srcList,
    srcIndex,
    dstList,
    dstListAttempt,
    dstIndex,
  } = useContext(Context);
  const ref = useRef();
  const syncRef = useRef({
    data,
    dragging,
    srcList,
    srcIndex,
    dstList,
    dstIndex,
    itemsInput,
  });
  const indexDiff = syncRef.current.dstIndex - syncRef.current.srcIndex;
  if (
    dragging
    || !syncRef.current.dragging
    || (
      syncRef.current.srcList === syncRef.current.dstList
      && [0, 1].includes(indexDiff)
    )
    || (
      syncRef.current.itemsInput !== itemsInput
      || (
        syncRef.current.srcList !== segmentId
        && syncRef.current.dstList !== segmentId
      )
    )
  ) {
    Object.assign(syncRef.current, {
      data,
      dragging,
      srcList,
      srcIndex,
      dstList,
      dstIndex,
      itemsInput,
    });
  }
  const items = useMemo(
    () => getListItems(
      shelfId,
      segmentId,
      syncRef.current.itemsInput,
      syncRef.current.srcList,
      syncRef.current.srcIndex,
      syncRef.current.dstList,
      syncRef.current.dstIndex,
      syncRef.current.data,
      (item, { dragging: isDragging, ...rest }) => renderItem(
        item,
        {
          dragging: isDragging,
          onDragStart: () => dragStart(
            item,
            segmentId,
            rest.index,
          ),
          onDrag: event => dragMove(event.pageX, event.pageY),
          onDragEnd: event => dragEnd(event.pageX, event.pageY),
          // onDrag: dragMoveEvent,
          // onDragEnd: dragEndEvent,
          ...rest,
        },
      ),
    ),
    // eslint-disable-next-line
    [
      dragging,
      srcList,
      srcIndex,
      dstList,
      dstIndex,
      shelfId,
      segmentId,
      data,
      itemsInput,
      renderItem,
      dragStart,
      dragMove,
      dragEnd,
      currency,
    ],
  );
  useEffect(
    () => {
      addList(
        segmentId,
        ref,
        { segmentId, getCanAcceptData, onDragEnd },
      );
      return () => {
        removeList(segmentId);
      };
    },
    [
      segmentId,
      addList,
      removeList,
      getCanAcceptData,
      onDragEnd,
    ],
  );
  return cloneElement(children, {
    ref,
    dragging,
    droppable: !(dstListAttempt === segmentId && dstListAttempt !== dstList),
    children: items,
    count: items.length,
  });
}

List.defaultProps = {
  items: [],
};
