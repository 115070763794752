import Admin from 'hive-admin';
// eslint-disable-next-line max-len
import InitialFieldConnectionSelect from 'hive-admin/src/components/FieldConnectionSelect';

const DEFAULT_ID = new Array(24).fill('0').join('');

// eslint-disable-next-line max-len
export default class FieldConnectionSelect extends InitialFieldConnectionSelect {
  static config = {
    ...FieldConnectionSelect.config,
    extractValueQuery: (value, props) => ({
      limit: props.valueLimit || 100,
      where: {
        AND: [
          ...props.getExtraQueryConditions(props),
          {
            [props.valueKey]: {
              IN: !value || !value.length ? [DEFAULT_ID] : value,
            },
          },
        ],
      },
      ...props.getExtraQueryProps(props),
    }),
  }
}

Admin.addToLibrary(
  'FieldConnectionSelect',
  config => FieldConnectionSelect.create(config),
);
