import React, { cloneElement } from 'react';
import styled from 'styled-components';

import Admin from 'hive-admin';
import ActionWithRequest from 'hive-admin/src/components/ActionWithRequest';

import AntdPopover from 'antd/lib/popover';

const PopoverHTML = ({ className, ...props }) => (
  <AntdPopover
    overlayClassName={className}
    {...props}
  />
);

const Popover = styled(PopoverHTML)`

`;

export default class ActionWithPopoverAndRequest extends ActionWithRequest {
  static config = {
    ...ActionWithRequest.config,
    popoverContent: null,
    renderPopoverContent: props => props.popoverContent,
  }

  renderAction(props) {
    const content = cloneElement(
      super.renderAction(props),
      { onClick: undefined },
    );
    return (
      <Popover
        title={null}
        content={this.props.renderPopoverContent(this.props, this)}
        trigger="click"
        placement="top"
        destroyTooltipOnHide
      >
        {content}
      </Popover>
    );
  }
}

Admin.addToLibrary(
  'ActionWithPopoverAndRequest',
  config => ActionWithPopoverAndRequest.create(config),
);
