import isString from 'lodash/isString';

import React, { useEffect, useRef } from 'react';

import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';

import Admin from 'hive-admin';
import Query from 'hive-admin/src/components/Query';

import ActionWithRequest from 'hive-admin/src/components/ActionWithRequest';

import { stringify as stringifyQuery } from 'querystring';

function Reloader({
  loading,
  wasReload,
  newCompiling,
  newReload,
  renderAction,
  every = 1000,
}) {
  const newCompilingRef = useRef({
    count: -1,
    value: newCompiling,
  });
  if (
       newCompiling !== false
    && newCompilingRef.current.value !== newCompiling
  ) {
    newCompilingRef.current.count++;
    newCompilingRef.current.newCompiling = newCompiling;
  }
  useEffect(
    () => {
      let interval = null;
      if (newCompiling) {
        interval = setInterval(
          () => newReload(),
          every,
        );
      }
      return () => {
        clearInterval(interval);
      };
    },
    [newCompiling, newReload, every],
  );
  if (loading || newCompiling) {
    return renderAction({
      disabled: true,
      icon: 'loading',
      title: newCompiling ? 'Compiling Report...' : 'Loading...',
      onClick: () => {},
    });
  }
  if (newCompilingRef.current.count > 0) {
    return renderAction({
      disabled: false,
      icon: 'reload',
      title: 'Load New Report',
      onClick: () => wasReload(),
    });
  }
  return renderAction({
    disabled: false,
    icon: 'cloud-sync',
    title: 'Compile New Report',
    messageWorking: 'Starting...',
    messageSuccess: 'Compilation Started!',
    handleSuccess: () => newReload(),
    handleError: () => newReload(),
  });
}

export default class ActionProcessingCompileReport extends ActionWithRequest {
  static extractData(response) {
    return (
      (
           response
        && response.data
      )
      ? response.data
      : { compiling: null }
    );
  }

  renderAction(props) {
    const popconfirm = !props.popconfirm
    ? null
    : Object.assign(
        {
          title: 'Are you sure?',
          onConfirm: () => props.onClick(props),
        },
        props.popconfirm === true
        ? {}
        : isString(props.popconfirm)
        ? { title: props.popconfirm }
        : props.popconfirm
      );
    const action = (
      props.iconOnly
      ? (
          <Icon
            type={props.icon}
            onClick={() => props.onClick(props)}
          />
        )
      : (
        <Button
          data-action={props.name}
          ghost={!!props.ghost}
          type={props.type}
          icon={props.icon}
          size={props.size}
          shape={props.shape}
          disabled={props.disabled}
          onClick={props.popconfirm ? (() => {}) : () => props.onClick(props)}
          style={props.style}
        >
          {props.title}
        </Button>
      )
    );
    if (popconfirm) {
      return (
        <Popconfirm {...popconfirm}>
          {action}
        </Popconfirm>
      );
    }
    return action;
  }
}

Admin.addToLibrary(
  'ActionProcessingCompileReport',
  (config = {}) => ActionProcessingCompileReport.create({
    name: 'actionProcessingCompileReport',
    ghost: true,
    getRequestConfig: (props) => ({
      method: 'GET',
      url: `/projects/${props.data._id}/stats/compile?trigger=true`,
    }),
    processAction: props => props.issueRequest(
      props,
      props.getRequestConfig(props),
    ).then(response => response.data),
    renderAction: (props, action) => (
      <Query
        client={props.client}
        url={`/projects/${props.data._id}?${stringifyQuery({
          query: JSON.stringify({
            full: true,
            select: { compiling: true },
          }),
        })}`}
        extractData={ActionProcessingCompileReport.extractData}
      >
        {({
          loading,
          reload: newReload,
          data: { compiling: newCompiling },
        }) => (
          <Reloader
            loading={loading}
            wasCompiling={!!props.data.compiling}
            wasReload={props.reload}
            newCompiling={newCompiling}
            newReload={newReload}
            renderAction={newProps => action.renderAction({
              ...props,
              ...newProps,
            })}
          />
        )}
      </Query>
    ),
    ...config,
  }),
);
