import Types from '../modules/types';

const tests = Types.ROLE.reduce(
  (agr, role) => {
    ['===', '!=='].forEach(op => ['user', 'viewer'].forEach((type) => {
      agr[`${
        type
      }${
        op === '===' ? 'Is' : 'IsNot'
      }${
        role
        .split('_')
        .map(word => `${
          word[0].toUpperCase()
        }${
          word.slice(1).toLowerCase()
        }`)
        .join('')
      }`] = (
          type === 'user'
        ? (props) => {
            let value = null;
            if (props.form) {
              value = props.form.getFieldValue('role');
            }
            if (!value && props.data) {
              value = props.data.role;
            }
            return op === '==='
            ? value === role
            : value !== role;
          }
        : [
            'condition.checkViewer',
            'role',
            op,
            role,
          ]
      );
    }));
    return agr;
  },
  {
    activityNotCreate: props => props && props.activity !== 'create',
  },
);

[
  ['viewerCustomerWhichCanManageOrganization', 'manageOrganization'],
  ['viewerCustomerWhichCanManageLibrary', 'manageLibrary'],
  ['viewerCustomerWhichCanManageProjects', 'manageProjects'],
  ['viewerCustomerWhichCanCreateProjects', 'createProjects'],
  ['viewerCustomerWhichCanReadProjects', 'readProjects'],
].forEach(([name, permission]) => {
  tests[`is${name[0].toUpperCase()}${name.slice(1)}`] = props => (
    props.viewer
    && props.viewer.role === 'CUSTOMER'
    && props.viewer.permissions
    && props.viewer.permissions[permission]
  );
  tests[`isNot${name[0].toUpperCase()}${name.slice(1)}`] = props => (
    !props.viewer
    || props.viewer.role !== 'CUSTOMER'
    || !props.viewer.permissions
    || !props.viewer.permissions[permission]
  );
});

tests.isViewerProjectCreator = props => (
  props.viewer
  && props.data
  && props.data.createdBy === props.viewer._id
);

tests.skipIfUserIsCustomerWhichCanManageOrganization = [
  'condition.testFieldValue',
  'permissions.manageOrganization',
  '===',
  true,
];

tests.skipIfViewerNotAdminOrCustomerWhichCanManageOrganization = [
  'condition.not',
  ['condition.or', [
    tests.viewerIsAdmin,
    tests.isViewerCustomerWhichCanManageOrganization,
    ],
  ],
];

tests.viewerCanManageLibrary = ['condition.or', [
  tests.viewerIsAdmin,
  tests.viewerIsContentManager,
  tests.isViewerCustomerWhichCanManageOrganization,
  tests.isViewerCustomerWhichCanManageLibrary,
]];

tests.viewerCannotManageLibrary = [
  'condition.not',
  tests.viewerCanManageLibrary,
];

tests.viewerUnitIsCm = ({ viewer }) => (
  viewer
  && viewer.units
  && viewer.units === 'CM'
);

tests.viewerUnitIsInch = ({ viewer }) => (
  viewer
  && viewer.units
  && viewer.units === 'INCH'
);

tests.viewerCanEditUserPermissions = ['condition.or', [
  tests.viewerIsAdmin,
  tests.isViewerCustomerWhichCanManageOrganization,
]];

tests.viewerCannotEditUserPermissions = [
  'condition.not',
  tests.viewerCanEditUserPermissions,
];

tests.isActivityEditAndlibraryPlanogramIsPurposed = props => (
  props.activity === 'edit'
  && props.data
  && props.data.purpose
);

export default tests;
