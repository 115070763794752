import React, { cloneElement, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'query-string';

import {
  FolderOpenOutlined,
  HomeOutlined,
} from '@ant-design/icons';

import AntdButton from 'antd/lib/button';
import AntdTag from 'antd/lib/tag';

import Query from 'hive-admin/src/components/Query';
import InputText from 'hive-admin/src/components/Input/Input';
import InputSelect from 'hive-admin/src/components/Input/Select';
// eslint-disable-next-line max-len
import InputList, { renderItem as renderListItem } from 'hive-admin/src/components/Input/List';

import Popover, {
  Content as PopoverContent,
} from '../../../Popover';

import { LoaderLibrary } from '../Loader';

import Item from './Item';

import Types from '../../../../modules/types';

const SearchInput = styled(InputText)`
  width: 100%;
`;

const LibrarySelect = styled(InputSelect)`
  width: 100%;
`;

const CategorySelect = styled(InputSelect)`
  width: 100%;
`;

// const CountriesSelect = styled(InputSelect)`
//   width: 100%;
// `;

const PlanogramList = styled(InputList)`
  width: 100%;
  .ant-list-items {
    height: 220px;
    overflow-x: hidden;
    overflow-y: scroll;
    > .ant-list-item {

    }
  }
`;

const PlanogramItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanogramItemLabel = styled.div`
  width: 100%;
`;

const PlanogramItemCategories = styled.div`
  padding-bottom: 5px;
  > .ant-tag {
    cursor: pointer;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 5px;
  }
`;

const PlanogramIconLibraryOrganization = styled(HomeOutlined)`
  margin-right: 5px;
  opacity: 0.5;
`;

const Button = styled(AntdButton)`
  width: 100%;
`;

function LoadTrigger({ loading, value, onLoaded }) {
  const loadedRef = useRef(false);
  useEffect(
    () => {
      if (!loading && !loadedRef.current) {
        loadedRef.current = true;
        onLoaded(value);
      }
    },
    [loading, value, onLoaded],
  );
  return null;
}

function Load({ value, onLoaded, client, extractData }) {
  return (
    <Query
      client={client}
      url={`library/planograms/${value}`}
      extractData={extractData}
    >
      {({ data }) => {
        if (!data) {
          return null;
        }
        return (
          <LoaderLibrary
            client={client}
            target="library"
            value={data.planogram}
            renderLoader={() => null}
            populate
          >
            <LoadTrigger onLoaded={onLoaded} />
          </LoaderLibrary>
        );
      }}
    </Query>
  );
}

Load.defaultProps = {
  extractData: response => (
    (response && response.data)
    ? response.data
    : null
  ),
};

function Content({
  client,
  loading,
  onLoad,
  onLoaded,
  extractData,
  region,
  organization,
}) {
  const [value, setValue] = useState();
  const [search, setSearch] = useState();
  const [library, setLibrary] = useState();
  const [categorySearch, setCategorySearch] = useState();
  const [category, setCategory] = useState();
  // const [countries, setCountries] = useState();
  return (
    <PopoverContent width={300} title="Load Planogram From Library">
      <SearchInput
        value={search}
        onChange={(ev) => {
          setValue(undefined);
          setSearch(ev.target.value);
        }}
        placeholder="Search"
      />
      {
          organization
        ? (
            <LibrarySelect
              value={library}
              onChange={(libraryValue) => {
                setLibrary(libraryValue);
                setValue(undefined);
              }}
              allowClear
              filterOption={false}
              placeholder="Library"
            >
              <LibrarySelect.Option value="global">
                Global
              </LibrarySelect.Option>
              <LibrarySelect.Option value="organization">
                Organization
              </LibrarySelect.Option>
            </LibrarySelect>
          )
        : null
      }
      <Query
        client={client}
        extractData={extractData}
        url={`library/categories?${qs.stringify({ query: JSON.stringify({
          limit: 20,
          skip: 0,
          where: {
            name: categorySearch && categorySearch.length
            ? { REGEX: categorySearch, OPTIONS: 'i' }
            : undefined,
          },
          sort: { name: 1 },
        }) })}`}
      >
        {({ data }) => (
          <CategorySelect
            value={category}
            onChange={(categoryValue) => {
              setCategory(categoryValue);
              setValue(undefined);
            }}
            showSearch
            onSearch={setCategorySearch}
            filterOption={false}
            placeholder="Category"
            disabled={loading}
            allowClear
          >
            {data.map(item => (
              <CategorySelect.Option key={item._id} value={item._id}>
                {item.name}
              </CategorySelect.Option>
            ))}
          </CategorySelect>
        )}
      </Query>
      {/* <CountriesSelect
        value={countries}
        onChange={countriesValue => setCountries(countriesValue)}
        placeholder="Countries"
        optionFilterProp="children"
        mode="multiple"
      >
        {Types.COUNTRIES.map(({ name: label, iso3a2 }) => (
          <CountriesSelect.Option
            key={iso3a2}
            value={iso3a2}
          >
            {label}
          </CountriesSelect.Option>
        ))}
      </CountriesSelect> */}
      <Query
        client={client}
        extractData={extractData}
        url={`library/planograms?${qs.stringify({ query: JSON.stringify({
          limit: 20,
          skip: 0,
          // where: {
          //   name: search && search.length
          //   ? { REGEX: search, OPTIONS: 'i' }
          //   : undefined,
          //   purpose: { EQ: null },
          //   organization: {
          //     IN: (
          //         !organization
          //       ? [null]
          //       : library === 'global'
          //       ? [null]
          //       : library === 'organization'
          //       ? [organization]
          //       : [null, organization]
          //     ),
          //   },
          //   categories: category || undefined,
          //   countries: (
          //     countries && countries.length
          //     ? { IN: countries }
          //     : undefined
          //   ),
          // },
          where: {
            AND: [
              {
                purpose: { EQ: null },
                name: search && search.length
                ? { REGEX: search, OPTIONS: 'i' }
                : undefined,
                organization: {
                  IN: (
                      !organization
                    ? [null]
                    : library === 'global'
                    ? [null]
                    : library === 'organization'
                    ? [organization]
                    : [null, organization]
                  ),
                },
                categories: category || undefined,
              },
              // (
              //     countries && countries.length
              //   ? {
              //       OR: [
              //         { countries: { IN: countries } },
              //         { countries: { EQ: [] } },
              //         { countries: { EQ: null } },
              //       ],
              //     }
              //   : null
              // ),
              (
                  region
                ? { region }
                : null
              ),
            ].filter(condition => !!condition),
          },
          sort: { name: 1 },
          populate: { categories: true },
        }) })}`}
      >
        {({ data }) => (
          <PlanogramList
            value={value}
            onChange={setValue}
            dataSource={data}
            locale={{ emptyText: 'No Planograms Found' }}
            renderItem={(item, currentValue, onChange) => cloneElement(
              renderListItem(item, currentValue, onChange),
              {},
              (
                <PlanogramItem>
                  <PlanogramItemLabel>
                    {
                        !organization || !item.organization
                      ? null
                      : <PlanogramIconLibraryOrganization />
                    }
                    <span>{item.name}</span>
                  </PlanogramItemLabel>
                  <PlanogramItemCategories>
                    {item.categories.map(itemCategory => (
                      <AntdTag key={itemCategory._id}>
                        {itemCategory.name}
                      </AntdTag>
                    ))}
                  </PlanogramItemCategories>
                </PlanogramItem>
              ),
            )}
          />
        )}
      </Query>
      <Button
        block
        type="primary"
        disabled={loading || !value}
        onClick={onLoad}
      >
        Load Planogram
      </Button>
      {
        !loading
        ? null
        : <Load value={value} onLoaded={onLoaded} client={client} />
      }
    </PopoverContent>
  );
}

Content.defaultProps = {
  extractData: response => (
    (
      response
      && response.data
      && response.data.data
      && response.data.data.length
    )
    ? response.data.data
    : []
  ),
};

export default class ItemLoad extends Item {
  static defaultProps = {
    Icon: FolderOpenOutlined,
  }

  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      open: false,
      loading: false,
    });
  }

  handleLoad = () => {
    this.setState({ loading: true });
  }

  handleLoaded = (value) => {
    const { onChange, activeSection } = this.props;
    this.setState(
      {
        open: false,
        loading: false,
      },
      () => {
        const { section: { [activeSection]: { list } } } = Types.PLANOGRAM;
        onChange(Object.keys(value).reduce(
          (agr, shelfId) => {
            const { map: { [shelfId]: { index } } } = Types.PLANOGRAM;
            const targetShelfId = list[index];
            agr[targetShelfId] = {
              ...agr[targetShelfId],
              segments: value[shelfId].segments,
              alignment: value[shelfId].alignment,
              fridge: value[shelfId].fridge,
            };
            return agr;
          },
          { ...this.props.value },
        ));
      },
    );
  }

  handleVisibleChange = visible => this.setState({ open: !!visible })

  renderButton() {
    return (
      <Popover
        title={null}
        content={(
          <Content
            client={this.props.client}
            loading={this.state.loading}
            onLoad={this.handleLoad}
            onLoaded={this.handleLoaded}
            region={this.props.region}
            organization={this.props.organization}
          />
        )}
        trigger="click"
        destroyTooltipOnHide
        mouseLeaveDelay={0.1}
        placement="bottomLeft"
        visible={this.state.loading || this.state.open}
        onVisibleChange={this.handleVisibleChange}
      >
        {super.renderButton()}
      </Popover>
    );
  }
}
